export const productListSettings = {
  capilarCategoryPattern: {
    desktop:[
      {type: 'image', className: "big raw-capilar-1"},
      {type: 'product', className: ""},
      {type: 'product', className: ""},
      {type: 'product', className: ""},
      {type: 'product', className: ""},
      {type: 'text', className: "", title:"¿estás pensando en alguien que necesita un extra de cuidado?", info:"Estos packs de cuidado facial y capilar son perfectos para mostrar todo tu cariño a esa persona que necesita un extra de mimos"},
      {type: 'image', className: "big raw-capilar-2"},
      {type: 'image', className: "big raw-capilar-3"},
      {type: 'image', className: "big raw-capilar-4", content: true},
    ], 
    mobile:[
      {type: 'product', className: ""},
      {type: 'product', className: ""},
      {type: 'product', className: ""},
      {type: 'product', className: ""},
      {type: 'image', className: "big raw-capilar-1"},
      {type: 'image', className: "small text-content", content: true},
      {type: 'image', className: "small raw-capilar-2"},
      {type: 'image', className: "big raw-capilar-3"},
    ]
  },
  fraganceCategoryPattern: {
    desktop:[
      {type: 'image', className: "big raw-essence-1"},
      {type: 'image', className: "wide raw-essence-2"},
      {type: 'product', className: ""},
      {type: 'product', className: ""},
      {type: 'product', className: ""},
      {type: 'product', className: ""},
      {type: 'image', className: "big raw-essence-3"},
      {type: 'product', className: ""},
      {type: 'product', className: ""},
      {type: 'image', className: "big raw-essence-4"},
      {type: 'image', className: "wide raw-essence-5", content: false},
      {type: 'product', className: ""},
      {type: 'product', className: ""},
      {type: 'product', className: ""},
      {type: 'product', className: ""},
      {type: 'product', className: ""},
      {type: 'image', className: "small raw-essence-6"},
    ], 
    mobile:[
      {type: 'product', className: ""},
      {type: 'image', className: "small raw-essence-1"},
      {type: 'product', className: ""},
      {type: 'product', className: ""},
      {type: 'image', className: "small raw-essence-2"},
      {type: 'image', className: "small raw-essence-3"},
      {type: 'product', className: ""},
      {type: 'product', className: ""},
      {type: 'image', className: "small raw-essence-4"},
      {type: 'product', className: ""},
      {type: 'image', className: "wide raw-essence-5 relative-positioned", content: false},
      {type: 'product', className: ""},
      {type: 'product', className: ""},
      {type: 'product', className: ""},
      {type: 'product', className: ""},
      {type: 'product', className: ""},
      {type: 'image', className: "small raw-essence-6"},
    ]
  },
  homecomingCategoryPattern: {
    desktop:[
      { type: 'product', className: "" },
      { type: 'product', className: "" },
      { type: 'product', className: "ez-product-item featured-product", content: true,},
      {type: 'product', className: ""},
      {type: 'product', className: ""},
      {type: 'product', className: ""},
      {type: 'product', className: ""},
      {type: 'product', className: ""},
      {type: 'product', className: ""},
      { type: 'product', className: "ez-product-item featured-product"},
      {type: 'product', className: ""},
      {type: 'product', className: ""},
      {type: 'product', className: ""},
      {type: 'product', className: ""},
      { type: 'product', className: "" },
      { type: 'product', className: "" },
      { type: 'product', className: "ez-product-item featured-product", content: true, },
      { type: 'product', className: "" },
      { type: 'product', className: "" },
      { type: 'product', className: "" },
      { type: 'product', className: "" },
      { type: 'product', className: "" },
      { type: 'product', className: "" }
    ], 
    mobile:[
      {type: 'product', className: ""},
      {type: 'product', className: ""},
      {type: 'product', className: ""},
      {type: 'product', className: ""},
      {type: 'product', className: ""},
      {type: 'product', className: ""},
      {type: 'product', className: ""},
      {type: 'product', className: ""},
      { type: 'product', className: "ez-product-item featured-product", content: true, },
      {type: 'product', className: ""},
      {type: 'product', className: ""},
      {type: 'product', className: ""},
      {type: 'product', className: ""},
      { type: 'product', className: "ez-product-item featured-product", content: true, },
      { type: 'product', className: "" },
      { type: 'product', className: "" },
      { type: 'product', className: "" },
      { type: 'product', className: "" },
      { type: 'product', className: "" },
      { type: 'product', className: "" },
      { type: 'product', className: "" },
      { type: 'product', className: "" },
      { type: 'product', className: "ez-product-item featured-product", content: true, },
    ]
  },
  sunProtectionCategoryPattern: {
    desktop:[
      {type: 'image', className: "ez-product-item arborea big raw-sun-1"},
      {type: 'product', className: ""},
      {type: 'product', className: ""},
      {type: 'product', className: ""},
      {type: 'product', className: ""},
      {type: 'product', className: ""},
      {type: 'product', className: ""},
      {type: 'product', className: ""},
      {type: 'product', className: ""},
      {type: 'image', className: "ez-product-item arborea wide-row raw-sun-3"},
    ], 
    mobile:[
      {type: 'product', className: ""},
      {type: 'product', className: ""},
      {type: 'product', className: ""},
      {type: 'image', className: "ez-product-item arborea small raw-sun-1"},
      {type: 'product', className: ""},
      {type: 'product', className: ""},
      {type: 'product', className: ""},
      {type: 'product', className: ""},
      {type: 'product', className: ""},
      {type: 'image', className: "ez-product-item arborea full raw-sun-4"},
    ]
  },
  giftIdeasCategoryPattern: {
    desktop:[
      {type: 'text', className: "", title:"¿estás lista para brillar estas navidades?", info:"Estos 3 looks creados por nuestras make up artist , son perfectos para cualquier ocasión estas fiestas. Elígelo en función de lo que quieras destacar, una mirada penetrante, unos labios jugosos o una piel perfecta"},
      {type: 'product', className: ""},
      {type: 'product', className: ""},
      {type: 'image', className: "ez-product-item arborea relative-positioned big", content: true,},
      {type: 'product', className: ""},
      {type: 'product', className: ""},
      {type: 'text', className: "", title:"¿buscas un detalle sorprendente?", info:"Estos lotes de Happy son perfectos si la persona es cariñosa, positiva, alegre, valiente y sensible. ¡Encuentra el que más se adapte a su personalidad"},
      {type: 'product', className: "arborea big"},
      {type: 'product', className: ""},
      {type: 'product', className: ""},
      {type: 'product', className: ""},
      {type: 'product', className: ""},
      {type: 'text', className: "", title:"¿estás pensando en alguien que necesita un extra de cuidado?", info:"Estos packs de cuidado facial y capilar son perfectos para mostrar todo tu cariño a esa persona que necesita un extra de mimos"},
      {type: 'product', className: ""},
      {type: 'product', className: ""},
      {type: 'image', className: "big raw-gift-2"},
      {type: 'product', className: ""},
      {type: 'product', className: ""},
      {type: 'text', className: "", title:"¿buscas una rutina que te haga desconectar cada día?", info:"La linea Happiest es perfecta para hacer realidad este sueño convirtiéndolo en un ritual de cuidado físico y mental. Conoce los aromas que conseguirán elevar tu día con una energía y relajación especial. Hay perfumes que te hacen sentir único, encuentra la fragancia que más se adapta a tu personalidad o te hace rememorar los momentos más increibles de tu vida, un verano inolvidable o unas navidades de ensueño."},
      {type: 'product', className: ""},
      {type: 'product', className: ""},
      {type: 'product', className: ""},
      {type: 'product', className: ""},
      {type: 'product', className: ""},
      {type: 'product', className: ""},
      {type: 'product', className: ""},
      {type: 'image', className: "small raw-gift-4"},
      {type: 'image', className: "big footer raw-gift-5-dk", href:"/es/caja-de-navidad-xmas.html"},
    ], 
    mobile:[
      {type: 'product', className: ""},
      {type: 'product', className: ""},
      {type: 'product', className: ""},
      {type: 'product', className: ""},
      {type: 'image', className: "ez-product-item arborea relative-positioned big", content: true,},
      {type: 'product', className: ""},
      {type: 'product', className: ""},
      {type: 'product', className: "arborea big"},
      {type: 'product', className: ""},
      {type: 'product', className: ""},
      {type: 'product', className: ""},
      {type: 'product', className: ""},
      {type: 'product', className: "arborea big"},
      {type: 'product', className: ""},
      {type: 'product', className: ""},
      {type: 'product', className: ""},
      {type: 'product', className: ""},
      {type: 'product', className: ""},
      {type: 'product', className: ""},
      {type: 'product', className: ""},
      {type: 'image', className: "small raw-gift-4"},
      {type: 'image', className: "big footer raw-gift-5-mb", href:"/es/caja-de-navidad-xmas.html"},
    ]
  },
  videosUrls:{
    giftIdeasVideos:{
      urlMediaDesktop: '/static/images/gift/gift-6-dk.mp4',
      urlMediaMobile: '/static/images/gift/gift-6-mb.mp4'
    }
  }
}

export default productListSettings;