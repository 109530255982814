import EzComponent       from '@frontend/components/EzComponent';
import _has              from 'lodash/has';

import {validateForm}    from "@frontend/services/FormValidator";


export class EzFormComponent extends EzComponent{
  state = {
    formData: {},
    error:    {}
  }

  updateValue = (data) => {
    const formData = this.state.formData;
    const { name } = data;
    for (let key in data) {
        if (key === 'name') {
            continue;
        }
        formData[name][key] = data[key];
    }
    this.setState({formData});
  }

  updateType = (data) => {
    const formData = this.state.formData;
    const { field } = data;
    for (let key in data) {
        if (key === 'name') {
            continue;
        }
        formData[field][key] = data[key];
    }
    this.setState({formData});
  }

  getValue = (inputName) => {
    const inputValue =
      ( _has(this.state,`formData.${inputName}`) )
        ? this.state.formData[inputName].value
        : "";

    return inputValue;
  }

  validateFields(){
    const { formData }      = this.state;
    const { isValid,error } = validateForm(formData);

    if(!isValid){
      this.setState({error});
    }else{
      this.setState({error:{}});
    }
    return isValid;
  }
}

export default EzFormComponent;
