import { SigninBox as BaseSigninBox } from '@themes/store/frontend/components/signin/SigninBox';
import { connect }                    from 'react-redux';
import { openSideBar,closeSideBar }   from '@frontend/actions/side-bar';
import Link                           from 'next/link';

import {
  signIn
}                                  from '@frontend/actions/user';
class SigninBox extends BaseSigninBox{
  state = {
    formData: {
      email:            { field:'email',type:'text',value:'',validation:'notEmpty,email',required:true },
      password:         { field:'password',type:'password',value:'',validation:'notEmpty',required:true }
    },
    error: '',
    storeConfiguration:{},
    disabled: false,
    showPassword: false
  }
  toggleShowPassword = ()=>{
    this.setState((state)=>({showPassword:!state.showPassword}));
  }
  openSideBar = ()=>{
    if (this.props.openSideBar){
      this.props.openSideBar('my-account');
    }
  }
  openForgottenPassword =()=> {
    if (this.props.openSideBar){
      this.props.openSideBar('forgotten-password');
    }
  }
  closeSideBar=()=>{
    if(this.props.closeSideBar){
      this.props.closeSideBar();
    }
  }
  handleClick = async (e) =>{
    if(this.state.disabled === true){
      return;
    }
    const { formData }                     = this.state;
    const { minicart,storeConfiguration,isMobile }  = this.props;

    e.preventDefault()
    e.stopPropagation()

    this.setState({disabled:false})

    if (this.validateFields()){
      let options= {
        email: formData.email.value,
        password: formData.password.value,
        storeConfiguration,
        minicart
      };
      this.props.signIn(this.context,options,()=>{
        this.setState({ disabled: false })
        if (isMobile){
          this.router.redirect('myAccountMe');
        }else{
          this.openSideBar();
        }
      })
    }
  }
  render(){
    const { formData,error,showPassword } = this.state;
    const { isMobile } = this.props;
    return (
      <>
      <div className="section-title">
        <span>{this.i18n.t('user.signIn.pageTitle')}</span>
      </div>
      <span className="section-description">{this.i18n.t('user.signIn.sectionDescription')}</span>
      <form className="ez-form sign-in-form">
        {
          Object.keys(formData).map((inputKey,index)=>{
            const {field,value,type,required} = formData[inputKey];
            const inputError    = (error[inputKey]) ? error[inputKey] : '';
            return(
              <div key={index} className="field">
                <input
                  className={`input ${inputKey} ${(value.length) ? 'active':''}`}
                  name={field}
                  type={(type=='password' && showPassword) ? 'text' : type}
                  value={value}
                  onChange={this.onInputChange}
                />
                <label className= {`label ${(required) ? "required":""}`} >
                  <span>{this.i18n.t(`user.signIn.${field}`)}</span>
                </label>
                {
                  type=='password' &&
                  <span className="toggle-show-password" onClick={this.toggleShowPassword}>{this.i18n.t(`user.signIn.${showPassword ? 'hide' : 'show'}Password`)}</span>
                }
                {
                  (inputError.length!==0) &&
                  <div className="ez-error-wrapper">
                    <span>{this.i18n.t(inputError)}</span>
                  </div>
                }
              </div>
            )
          })
        }

        <button className="signin-button" disabled={this.state.disabled} onClick={this.handleClick}>
          {this.i18n.t('user.signIn.buttonLabel')}
        </button>
        {
          isMobile ?
          <Link {...this.router.get('forgottenPassword')}>
            <a className="forgotten-password-link">
              <span>{this.i18n.t('user.signIn.forgottenPassword')}</span>
            </a>
          </Link>
          :
          <span className="forgotten-password-link" onClick={this.openForgottenPassword}>
              <span>{this.i18n.t('user.signIn.forgottenPassword')}</span>
          </span>

        }
      </form>
      </>
    )
  }
}

function mapStateToProps (state){
  return {
    storeConfiguration: state.storeConfig,
    minicart:           state.minicart,
    isMobile:           state.isMobile
  }
}
export default connect(
    mapStateToProps,
    { signIn,openSideBar,closeSideBar }
)(SigninBox);
