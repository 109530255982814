import EzComponent              from '@frontend/components/EzComponent';
import EditCustomerForm     from '@frontend/components/my-account/me/EditCustomerForm.jsx';

import '@frontend/components/side-bar/sections/edit-customer/index.less';

class SideBarEditCustomer extends EzComponent{
	render() {
		return (	
			<div className="edit-customer-content">
				<div className="section-header">
					<span className="section-title">{this.i18n.t('user.myAccount.contactInfo.edit')}</span>
				</div>
				<EditCustomerForm />
			</div>
		);
	}
  
}
export default SideBarEditCustomer;
  