import EzComponent            from '@frontend/components/EzComponent';
import { connect }            from 'react-redux'
import _get                   from 'lodash/get';
import { addCartCoupon }      from '@frontend/actions'
class AddCouponButton extends EzComponent {

  //// TODO: handle coupon errors
  handleAddCoupon = () =>{
    const {
      cartData,
      user,
      storeConfiguration,
      couponCode,
      isApp
    } = this.props;

    let variables = {
      couponCode,
      storeId:(storeConfiguration.storeCode) ? parseInt(storeConfiguration.storeId) : 0,
      storeCode:(storeConfiguration.storeCode) ? storeConfiguration.storeCode : 'default',
      quoteId: (cartData.quote_id) ? cartData.quote_id: '',
      customerToken: (user.token) ? user.token : '',
      customerId:   (user.id) ? user.id : null,
      customerGroup: (user.group_id) ? user.group_id : 0,
      enableCrossSellProducts: storeConfiguration. enableMinicartCrossSell,
      isApp
    };
    if (couponCode && couponCode.length){
      this.props.addCartCoupon({client:this.context.client,i18n:this.i18n},variables);
    }
  }
  render(){
    const { className='' } = this.props;

    return (
      <button className={`apply-coupon ez-button ${className}`} onClick= {this.handleAddCoupon}>{this.i18n.t('coupons.applyCoupon')}</button>
    );
  }
}

function mapStateToProps(state){
  const { 
    isApp,
    minicart:{cart},
    user,
    storeConfig
  } = state;
  return {
    cartData: cart,
    user,
    isSignedIn: _get(user, 'isSignedIn'),
    storeConfiguration: storeConfig,
    isApp
  }
}
export default connect(mapStateToProps,{addCartCoupon}) (AddCouponButton);
