import { Navigation as BaseNavigation}  from '@themes/magento/frontend/components/header/Navigation';
import MegaMenu                         from '@frontend/components/megamenus/MegaMenu';
import AccountMenu                      from '@frontend/components/header/account-menu/AccountMenu';
import { connect }                      from 'react-redux';

class Navigation extends BaseNavigation{
  render() {
    const { isMobile } = this.props;

      return (
        <>
         <MegaMenu />
         {
           (isMobile) &&
           <AccountMenu />
         }
        </>
      );
  }
}

function mapStateToProps({isMobile}){
  return {
    isMobile
  }
}

export default connect(mapStateToProps)(Navigation);
