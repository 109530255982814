import { HtmlParser as StoreParser }  from '@themes/store/frontend/components/HtmlParser';
import dynamic from 'next/dynamic'

const FooterNewsletter               = dynamic(()=> import('@frontend/components/newsletter/Newsletter'));
const CmsLink                        = dynamic(()=> import('@frontend/components/basic-components/cms-link/index'));
const CmsPageLink                    = dynamic(()=> import('@frontend/components/basic-components/cms-page-link/index'));
const CmsProductLink                 = dynamic(()=> import('@frontend/components/basic-components/cms-product-link/index'));
const CmsCategoryLink                = dynamic(()=> import('@frontend/components/basic-components/cms-category-link/index'));
const NextLink                       = dynamic(()=> import('@frontend/components/basic-components/next-link/index'));



export class HtmlParser extends StoreParser{
  static getComponentObjects(){
    const storeComponents = super.getComponentObjects();

    return {
      ...storeComponents,
      "footer-newsletter" : FooterNewsletter,
      "cms-link":           CmsLink,
      "cms-page-link":      CmsPageLink,
      "cms-product-link":   CmsProductLink,
      "cms-category-link":  CmsCategoryLink,
      "next-link":          NextLink
    };
  }

}
export default HtmlParser;
