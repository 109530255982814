import EzComponent          		from '@frontend/components/EzComponent';
import Link                 		from 'next/link';
import { closeAccountModal }   		from '@frontend/actions/account-modal';

import ReducedModal 				from '@frontend/components/basic-components/reduced-modal';

import { connect }          		from 'react-redux'


import '@frontend/components/my-account/account-modal/index.less';

class AccountMenu extends EzComponent{
	closeModal = ()=>{
		this.props.closeAccountModal();
	}
	render(){
		return (
			<ReducedModal className="account-modal" closeModal={this.closeModal}>
				<span className="account-menu-description">{this.i18n.t('accountMenu.description')}</span>
				<div className="links-wrapper col-group">
					<Link {...this.router.get('signOut')}><span className="menu-link signout-button col-mb-4" onClick={this.closeModal}>{this.i18n.t('accountMenu.signout')}</span></Link>
					<Link {...this.router.get('myAccountMe')}><span className="menu-link edit-profile col-mb-offset-1 col-mb-6" onClick={this.closeModal}>{this.i18n.t('accountMenu.editProfile')}</span></Link>
				</div>
			</ReducedModal>
		);
	}
}

export default connect(null,{closeAccountModal})(AccountMenu);
