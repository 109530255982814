import EzComponent from '@frontend/components/EzComponent';

import '@frontend/components/basic-components/reduced-modal/index.less';

class ReducedModal extends EzComponent {
    closeModal = ()=>{
        if (this.props.closeModal){
            this.props.closeModal();
        }
    }
    render(){
        const { className='',children } = this.props;
        return (
            <div className={`reduced-modal ${className}`}>
                <div className="modal-content">
                    <div className="close-action" onClick={this.closeModal}><span></span></div>
                     {children}
                </div>
                
            </div>
        )
    }
}

export default ReducedModal;
