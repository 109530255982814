import EzComponent from '@frontend/components/EzComponent';
import { connect } from 'react-redux';
import Link        from 'next/link';
import {
    addItemToCart,
    getWishlistProductList
} from '@frontend/actions';

import '@frontend/components/minicart/cross-sell-products/index.less';

import '@frontend/components/minicart/wishlist/index.less';

class WishlistPromo extends EzComponent {

    static mapStateToProps({ user, storeConfig, isMobile, wishlist, minicart }) {
        return {
            storeConfiguration: storeConfig,
            user: user,
            isMobile: isMobile,
            wishlist: wishlist,
            cartData: minicart.cart
        }
    }

    getWishlistData = () => {
        const { user, storeConfiguration } = this.props;

        if (user.id) {
            let variables = {
                customerId: user.id,
                customerGroup: (user.group_id) ? user.group_id : 0,
                storeId: storeConfiguration.storeId ? storeConfiguration.storeId : 0
            };
            this.props.getWishlistProductList(this.context, variables);
        }
    }

    componentDidMount(){
        this.getWishlistData();
    }

    renderMobileContent()
    {
        let image = "/static/images/wish-cart-mb.jpg"
        return (
            <div className="cart-wishlist-promo" style={{ backgroundImage: `url(${image})` }}>
                <span className="slow-title">
                    {this.i18n.t('miniCart.slow')}
                </span>
                <span className="slow-subtitle">
                    {this.i18n.t('miniCart.toCartWish')}
                </span>
                <span className="sub-sub-subtitle">
                    {this.i18n.t('miniCart.wishlistMessOne')}
                </span>
                <span className="down-sub-subtitle">
                    {this.i18n.t('miniCart.wishlistMessTwo')}<br />{this.i18n.t('miniCart.wishlistMessThree')}
                </span>

                <span className="main-title">
                    {this.i18n.t('miniCart.wishlistTitle')}
                </span>
                <span className="sub-title">
                    {this.i18n.t('miniCart.wishlistSubTitle')}
                </span>
               
                <Link {...this.router.get('myAccountWishlist')}>
                    <span className="wishlist-link">
                        {this.i18n.t('miniCart.wishlistButtonOne')}
                    </span>
                </Link>
                <span onClick={this.addProductsToCart.bind(this)} className="buy-now-button">
                    {this.i18n.t('miniCart.wishlistButtonTwo')}
                </span>                
            </div> 
        )
    }

    addProductsToCart = async () => {
        const { wishlist } = this.props;
        if (wishlist && wishlist.length > 0) {
            await Promise.all(wishlist.map(async (item, index) => {
                let { product_options, is_configurable } = item;
                let qty = 1;
                let { cartData, user, storeConfiguration } = this.props;
                let product_option = {};
                if (is_configurable) {
                    let configurable_item_options = [];
                    if (product_options && product_options.length) {
                        product_options = JSON.parse(product_options);
                        product_options.map((option) => {
                            let optionValue = option.values[0].value_id;
                            let optionId = option.option_id;
                            const configurable_option = {
                                option_id: parseInt(optionId),
                                option_value: parseInt(optionValue)
                            }
                            configurable_item_options.push(configurable_option)
                        });
                        product_option['extension_attributes'] = {
                            configurable_item_options
                        }
                    }
                    if (is_configurable && !product_options) {
                        return false;
                    }
                }
                let variables = {
                    sku: item.sku,
                    qty: qty,
                    storeId: (storeConfiguration.storeCode) ? parseInt(storeConfiguration.storeId) : 0,
                    storeCode: (storeConfiguration.storeCode) ? storeConfiguration.storeCode : 'default',
                    quoteId: (cartData.quote_id) ? cartData.quote_id : '',
                    customerToken: (user.token) ? user.token : '',
                    customerId: (user.id) ? user.id : 0,
                    product_option
                }
                await this.props.addItemToCart({ client: this.context.client }, variables);
            }));
        }
        this.router.redirect('checkoutCart');
    }

    renderDesktopContent(){
        let image = "/static/images/wish-cart-desk.jpg"
        return(
            <div className="cart-wishlist-promo" style={{ backgroundImage: `url(${image})` }}>
                <div className="top-left">
                    <span className="main-title">
                        {this.i18n.t('miniCart.wishlistTitle')}
                    </span>
                    <span className="sub-title">
                        {this.i18n.t('miniCart.wishlistSubTitle')}
                    </span>
                </div>
                <div className="bottom-left">
                    <Link {...this.router.get('myAccountWishlist')}>
                        <span className="wishlist-link">
                            {this.i18n.t('miniCart.wishlistButtonOne')}
                        </span>
                    </Link>
                    <span onClick={this.addProductsToCart.bind(this)} className="buy-now-button">
                        {this.i18n.t('miniCart.wishlistButtonTwo')}
                    </span>
                </div>
                <div className="right">
                    <span className="slow-title"> 
                        {this.i18n.t('miniCart.slow')}
                    </span>
                    <span className="slow-subtitle">
                        {this.i18n.t('miniCart.toCartWish')}
                    </span>
                    <span className="sub-sub-subtitle">
                        {this.i18n.t('miniCart.wishlistMessOne')}
                    </span>
                    <span className="down-sub-subtitle">
                        {this.i18n.t('miniCart.wishlistMessTwo')}<br/>{this.i18n.t('miniCart.wishlistMessThree')}
                    </span>
                </div>
            </div> 
        )
    }

    renderWishlistPromo(){
        const {isMobile} = this.props;
        if (isMobile) {
            return this.renderMobileContent()
        } else {
            return this.renderDesktopContent()
        }
    }

    render(){
        const {user} = this.props;
        if (!user||!user.wishlist_items||!user.wishlist_items.length) {
            return null
        }
        return this.renderWishlistPromo()
    }

}


export default connect(WishlistPromo.mapStateToProps, { addItemToCart, getWishlistProductList})(WishlistPromo);