import EzComponent              from '@frontend/components/EzComponent';
import EditAddressForm      from '@frontend/components/my-account/addresses/EditAddressForm';

import '@frontend/components/side-bar/sections/add-address/index.less';

class SideBarEditAddress extends EzComponent{
	render() {
		const { id } = this.props;
		return (	
			<div className="edit-address-content">
				<div className="section-header">
					<span className="section-title">{this.i18n.t('user.myAccount.addresses.edit')}</span>
				</div>
				<EditAddressForm id={id}/>
			</div>
		);
	}
  
}
export default SideBarEditAddress;
  