import EzComponent          from '@frontend/components/EzComponent';
import { connect }          from 'react-redux'

import HtmlParser           from '@frontend/components/HtmlParser'
import { getCMSBlock }      from '@frontend/actions';

export class CMSBlock extends HtmlParser{
  state = {
    cmsBlock: null
  }

  getCMSBlockData = async (id) => {
    const { getCMSBlock } = this.props;
    const variables       = {id};
    const cmsBlock        = await getCMSBlock(this.context,variables);

    this.setState({cmsBlock});
  }
  async componentDidMount(){
    const { id } = this.props;

    await this.getCMSBlockData(id);
  }

  render(){
    const { cmsBlock }     = this.state;
    const { className="" } = this.props;
    const htmlBlock        = (cmsBlock && cmsBlock.content) ? this.parseComponents(cmsBlock.content) : "";

    if(htmlBlock){
      return (
        <div className={`ez-cms-block ${className}`}>
          <div className="cms-content">
          {
            htmlBlock
          }
          </div>
        </div>

      )
    }
    else{
      return null;
    }

  }
}

export default connect(null,{getCMSBlock})(CMSBlock);
