import EzFormComponent          from '@frontend/components/EzFormComponent';

import Cookies              from 'nookies'
import cookie               from 'cookie'
import _has                 from 'lodash/has'
import _unset               from 'lodash/unset'

import { connect }          from 'react-redux'
import dynamic              from 'next/dynamic';

const MyAccountLayout      = dynamic(()=> import('@frontend/layout/MyAccountLayout'));
const TextInput            = dynamic(()=> import('@frontend/components/basic-components/form/TextInput'));
const AutoSelectInput      = dynamic(()=> import('@frontend/components/basic-components/form/auto-select-input/AutoSelectInput'));
const DateInput            = dynamic(()=> import('@frontend/components/basic-components/form/date-input/DateInput'));

import {updateUser}         from '@frontend/actions'
import Link                 from 'next/link'

import { setUser }          from '@frontend/actions';

export class AddAddressForm extends EzFormComponent{
  state = {
    user: null,
    isSignedIn: false,
    formData: {
      firstname:        { field:'firstname',       type:'text',     value:'', validation:'lettersWithSpaces,notEmpty',        required:true },
      lastname:         { field:'lastname',        type:'text',     value:'', validation:'lettersWithSpaces,notEmpty',        required:true },
      telephone:        { field:'telephone',       type:'text',     value:'', validation:'numbers,notEmpty',                  required:true },
      street:           { field:'street',          type:'text',     value:'', validation:'notEmpty',                          required:true },
      city:             { field:'city',            type:'text',     value:'', validation:'lettersWithSpaces,notEmpty',        required:true },
      region:           { field:'region',          type:'select',   value:'', validation:'notEmpty',                          required:true,focused:false },
      country:          { field:'country',         type:'select',   value:'', validation:'notEmpty',                          required:true,focused:false },
      postCode:         { field:'postCode',        type:'text',     value:'', validation:'notEmpty',                          required:true }
    },
    selectValues:{
      gender: [{value:1,label:this.i18n.t('genders.male')},{value:2,label:this.i18n.t('genders.female')}]
    },
    error:[],
    defaultShipping: false,
    defaultBilling: false
  }
  updateCustomer = () =>{
    const {
            formData:{firstname,lastname,telephone,region,country,postCode,street,city},
            defaultShipping,
            defaultBilling
   } = this.state;
   const {
     user:{ token },
     user,
     storeCountries,
    } = this.props;

    let userData = JSON.parse(JSON.stringify(user));
    let regionData = {};
    if (this.validateFields()){
      if (storeCountries.countriesData.filter(item=>item.countryCode==country.value).length){
        let countryData = storeCountries.countriesData.filter(item=>item.countryCode==country.value)[0];
        if(countryData.regions.filter(item=>item.regionId==parseInt(region.value)).length){
          regionData = countryData.regions.filter(item=>item.regionId==parseInt(region.value))[0];
        }
      }
      userData.addresses.map(address=>{
        address.custom_attributes = [];
      });
      let address = {
        default_shipping: defaultShipping,
        default_billing: defaultBilling,
        firstname: firstname.value,
        lastname: lastname.value,
        telephone: telephone.value,
        region: _has(regionData, "regionId") ? {
          region: regionData.region,
          region_id: regionData.regionId,
          region_code: regionData.regionCode
        }
        :
        {
          region: region.value
        },
        custom_attributes: [],
        postcode: postCode.value,
        street: [street.value],
        city: city.value,
        country_id: country.value
      }
      userData.addresses.push(address);
      _unset(userData,'token');
      _unset(userData,'error');
      _unset(userData,'wishlist_items');
      _unset(userData,'isSignedIn');

      let variables = {
        token,
        customer:userData
      };
      this.props.updateUser(this.context,variables);
      this.router.redirect('myAccountMe');
    }
  }
  onFocusSelect= (name)=>{
    let { formData } = this.state;
    formData[name]['focused'] = true;
    this.setState({ formData });
  }
  onBlurSelect= (name)=>{
    let { formData } = this.state;
    formData[name]['focused'] = false;
    this.setState({ formData });
  }

  handleSelect = (inputData)=>{
    const { selectValues,selectValues:{actualRegions} } = this.state;
    let   {formData} = this.state;
    const { storeCountries } = this.props;
    let selectRegions = [];
    this.updateValue(inputData);
    if(inputData.name=='country' && actualRegions != inputData.value ){
      let countryInfo = storeCountries.countriesData.filter(item=>item.countryCode==inputData.value);
      if (countryInfo.length){
        countryInfo = countryInfo[0];
        countryInfo.regions.map((region)=>{
          if(region.regionId){
            selectRegions.push({value:region.regionId.toString(),label:region.region});
          }
        })
        formData['region']['value'] = '';
      }
      if(selectRegions.length === 0){
        formData["region"]['type'] = "text";
        formData["region"]['required'] = false;
      }else{
        formData["region"]['type'] = "select";
        formData["region"]['required'] = true;
      }
      this.setState({formData, selectValues:{...selectValues,region:selectRegions,actualRegions:inputData.value}})
    }

  }
  getCountriesData = ()=>{
    let { formData,selectValues } = this.state;
    const {storeCountries,storeConfiguration:{ storeId,websiteId }} = this.props;
    let selectCountries = [];
    let selectRegions = [];
    storeCountries.allowed.map((country)=>{
      let countryInfo = storeCountries.countriesData.filter(item=>item.countryCode==country);
      if (countryInfo.length){
        countryInfo = countryInfo[0];
        let label = (this.i18n.t(`countries.${countryInfo.countryName}`) ?
                    this.i18n.t(`countries.${countryInfo.countryName}`) :
                    countryInfo.countryName);

        selectCountries.push({value:countryInfo.countryCode,label:label});
        if(country==storeCountries.default){
          selectRegions.push({value:'default',label:this.i18n.t('regions.default')});
          countryInfo.regions.map((region)=>{
            selectRegions.push({value:region.regionId.toString(),label:region.region});
          })
        }
      }
    });
    if(formData['country']['value'] === ""){
      formData['country']['value'] = storeCountries.default;
    }
    this.setState({
      selectValues:{
        ...selectValues,
        region:selectRegions,
        country:selectCountries,
        actualRegions:formData['country']['value']
      },
      storeCountries,
      formData
    });
  }
  componentDidMount(){
    this.getCountriesData();
  }
  render(){
    const { formData,error,selectValues } = this.state;

    return(
      <div className="add-address-form ez-form">
          <form>
          {
            Object.keys(formData).map((inputKey,index)=>{
              const { field,
                      value,
                      type,
                      focused,
                      required }  = formData[inputKey];
              const inputError    = (error[inputKey]) ? error[inputKey] : '';

              return(
                <div key={index} className={`field ${(focused) ? 'focused':''}`}>
                  {
                    (type=='date') ?
                      <div className={`input ${field} ${(value.length) ? 'active':''}`}>
                        <DateInput
                          value={value}
                          name={field}
                          onChange={this.updateValue}
                        />
                      </div>
                    :
                    (type=='select' && selectValues[field] && selectValues[field].length) ?
                    <AutoSelectInput
                      className = {`input ${field} ${(focused||value) ? 'active':''}`}
                      options = {selectValues[field]}
                      name={field}
                      onFocus= {this.onFocusSelect}
                      onBlur= {this.onBlurSelect}
                      onChange={this.handleSelect}
                      value = {value}
                    />
                    :
                    <TextInput
                      className={`input ${field} ${(value) ? 'active':''}`}
                      type={type}
                      name={field}
                      onChange={this.updateValue}
                      value={value}
                    />
                  }
                  <label className= {`label ${(required) ? "required":""}`} >
                    <span>{this.i18n.t(`user.myAccount.addresses.${field}Label`)}</span>
                  </label>
                  {
                    (inputError.length!==0) &&
                    <div className="ez-error-wrapper">
                      <span>{this.i18n.t(inputError)}</span>
                    </div>
                  }
                </div>
              )
            })
          }
          </form>
          <div className="add-address-actions">
            <button className="save-button" onClick={this.updateCustomer}>{this.i18n.t('user.myAccount.contactInfo.save')}</button>
            <Link {...this.router.get('myAccountMe')}>
              <label className="cancel-button ez-secondary-button">{this.i18n.t('user.myAccount.contactInfo.cancel')}</label>
            </Link>
          </div>
      </div>
    );
  }
}
function mapStateToProps(state){
  return {
    isSignedIn:state.user.isSignedIn,
    user:state.user,
    storeConfiguration:state.storeConfig,
    storeCountries:state.storeCountries
  };
}

export default connect(mapStateToProps,{updateUser})(AddAddressForm);
