import EzComponent              from '@frontend/components/EzComponent';
import AddAddressForm       from '@frontend/components/my-account/addresses/AddAddressForm';

import '@frontend/components/side-bar/sections/add-address/index.less';

class SideBarAddAddress extends EzComponent{
	render() {
		return (	
			<div className="add-address-content">
				<div className="section-header">
					<span className="section-title">{this.i18n.t('user.myAccount.addresses.add')}</span>
				</div>
				<AddAddressForm />
        	</div>
		);
	}
  
}
export default SideBarAddAddress;
  