import { Header as BaseHeader }                 from '@themes/base/frontend/components/header/Header';
import Link                                     from 'next/link'
import Head                                     from 'next/head'
import { connect }                              from 'react-redux';
import { compose }                              from 'redux';
import {withScroll}                             from '@frontend/lib/withScroll';

import { closeCart,openCart }                   from '@frontend/actions';

import Icon                                     from '@frontend/components/header/Icon'
import AccountBanner                            from '@frontend/components/accountbanner/AccountBanner'

import SearchBar                                from '@frontend/components/searchbar/SearchBar'
import MiniCart                                 from '@frontend/components/minicart/MiniCart'
import Navigation                               from "@frontend/components/header/Navigation";
import CMSBlock                                 from '@frontend/components/cms-block/CMSBlock';
import { HEADER_BANNER_ENABLED,HEADER_HEIGHT }  from '@frontend/constants';


import '@frontend/components/header/header.less';

export class Header extends BaseHeader {
  state = {
    showMobileMenu: false
  }

  minicartChanges = (showCart) =>{
    if(showCart){
      this.props.openCart();
    }else{
      this.props.closeCart()
    }
  }

  toggleShowMobileMenu = () =>{
    this.setState((state)=>({showMobileMenu:!state.showMobileMenu}));
  }

  headerClasses = () => {
    const { showCart,scrollData={},isMobile } = this.props;
    const { showMobileMenu }                  = this.state;
    const { scrollTop }                       = scrollData;
    const scrolled                            = scrollTop >= HEADER_HEIGHT;

    const scrolledClass = (scrolled)                       ? 'scrolled'           : 'normal-header';
    const minicartClass = showCart                         ? 'minicart-opened'    : '';
    const bannerClass   = HEADER_BANNER_ENABLED            ? 'with-banner'        : '';
    const mobileClass   = (isMobile && showMobileMenu)     ? 'mobile-menu-opened' : '';

    return `${scrolledClass} ${minicartClass} ${bannerClass} ${mobileClass}`;
  }

  render() {
    const { showMobileMenu }                          = this.state;
    const { search,showCart,scrollData={},isMobile }  = this.props;
    const { scrollTop }                               = scrollData;
    const scrolled                                    = scrollTop >= HEADER_HEIGHT;

    return (
      <>
      <div id="ez-header" className={`ez-header col-group ${this.headerClasses()}`}>
        <Head/>
        <div className= "ez-top-pannel">
          {
            HEADER_BANNER_ENABLED &&
              <CMSBlock id="header_banner"/>
          }
        </div>
        <div className="ez-menu-mobile-icon col-mb-2" onClick={this.toggleShowMobileMenu}>
          <span className="toggle-nav"></span>
        </div>
        <div className="ez-header-menu col-12 col-mb-9 col-group">
          <div className={`menu-icon ${(scrolled) ? "col-2" : "col-4 col-offset-4"} col-mb-7`}>
            <Icon />
          </div>
          {
            (!isMobile && scrolled) &&
              <div className={`ez-navigation ${(scrolled) ? "col-7" : "col-12"} col-sm-0`}>
                <Navigation />
              </div>
          }
          <div className={`ez-header-search-cart ${(scrolled) ? "col-3" : "col-4"} col-mb-5`}>
            <MiniCart colData="" onMinicartChange={this.minicartChanges} showCart={showCart}/>

            <SearchBar colData="" search={search} />
            {
              // <WishlistLink colData="col-2 col-mb-0" />
            }

          </div>
          {
            (!isMobile && !scrolled) &&
              <div className="ez-navigation col-12 col-sm-0">
                <Navigation />
              </div>
          }
        </div>
      </div>

      <div className="ez-navigation-menu mobile-menu">
        <div className="close-menu">
          <span onClick={this.toggleShowMobileMenu}>{this.i18n.t('navigationMenu.close')}</span>
        </div>
        <div className="ez-navigation">
          <span className="ez-navigation-menu-title">{this.i18n.t('navigationMenu.title')}</span>
          <Navigation />
        </div>
      </div>
      <div className="mobile-menu-overlay" onClick={this.toggleShowMobileMenu}></div>
    </>

    );
  }
}

function mapStateToProps({minicart,storeConfig,isMobile}){
  return {
    showCart: minicart.showCart,
    isMobile
  }
}

export default compose(withScroll,connect(mapStateToProps, {closeCart,openCart}))(Header);
