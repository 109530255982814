import React,{ Component }  from 'react';
import { connect }          from 'react-redux'
import _indexOf             from 'lodash/indexOf';
import _has                 from 'lodash/has';

import LogInterceptor       from "@frontend/services/LogInterceptor";
import Header               from "@frontend/components/header/Header";
import Footer               from '@frontend/components/footer/Footer';
import AccountModal         from '@frontend/components/my-account/account-modal';
import SwatchesModal        from '@frontend/components/product-swatches/swatches-modal';
import AddToCartModal       from '@frontend/components/buttons/add-to-cart-button/add-to-cart-modal';
import FlashMessages        from '@frontend/components/flash-messages/FlashMessages';
import SideBar              from '@frontend/components/side-bar';
import SafeBuy              from '@frontend/components/safe-buy';
import DiscountPopup        from '@frontend/components/discount-popup';
// import CookiesBar           from '@frontend/components/cookies/bar/index.jsx';
import RetailContainer      from '@frontend/components/third-party-scripts/misc/retail/RetailContainer';


import "@frontend/less/layout/base.less";



export class Base extends Component{
  state = {
    messages:   [],
    scrollData: {}
  }
  addClassToBody = () =>{
    const { showCart }  = this.props;
    const minicartClass = 'minicart-opened';

    if(typeof document != 'undefined'){
      showCart ? document.body.classList.add(minicartClass) : document.body.classList.remove(minicartClass);
    }
  }
  getBaseClasses = (children) => {

    if (children.length > 1) {
      for (var i = 0; i < children.length; i++) {
        if (children[i]&&children[i].type == "div") {
          children = children[i];
          break;
        }
      }
    }
    if(!_has(children,'props.className')){
      return '';
    }

    const childrenClasses = children.props.className;
    const childrenArray   = childrenClasses.split(' ').map(childClass => `${childClass}-wrapper`);
    const baseClasses     = childrenArray.join(' ');
    return baseClasses;
  }

  render() {
    const {
      search='',
      className='',
      children,
      disablePadding=false,
      showCart,
      messages,
      header,
      showAccountModal,
      showSwatchesModal,
      showAddToCartModal,
      storeConfiguration
    }                     = this.props;
    let baseClasses       = this.getBaseClasses(children);


    this.addClassToBody();
    return (
      <>
      <div className={`page-wrapper ${baseClasses}`}>
        <Header search={search} />
        <FlashMessages messages={messages}/>
        <div className="ez-base">
          {children}
          <DiscountPopup/>
        </div>
        <SafeBuy />
        <Footer />
        {
          showAccountModal &&
          <AccountModal />
        }
        {
          showSwatchesModal &&
          <SwatchesModal />
        }
        {
          showAddToCartModal &&
          <AddToCartModal />
        }
      </div>
      <SideBar />
      <RetailContainer blockId="5ecd147097a525075c900869" />
    </>
    );
  }
}

function mapStateToProps({
  minicart,
  flashMessages, 
  header,
  storeConfig,
  showAccountModal,
  swatchesModal:{showModal:showSwatchesModal},
  addToCartModal:{showModal:showAddToCartModal}
}){
  return {
    showCart: minicart.showCart,
    messages: flashMessages.messages,
    count:    flashMessages.messages.length,
    storeConfiguration: storeConfig,
    header,
    showAccountModal,
    showSwatchesModal,
    showAddToCartModal
  }
}

export default connect(mapStateToProps)(Base);
