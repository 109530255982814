import EzComponent        from '@frontend/components/EzComponent';
import Link               from 'next/link'
import { connect }        from 'react-redux';
import { openSideBar }    from '@frontend/actions/side-bar';

import '@frontend/components/my-account/me/contact-info.less';

class ContactInfo extends EzComponent {
  openSideBar = ()=>{
    if (this.props.openSideBar){
      this.props.openSideBar('edit-customer',['my-account']);
    }
  }
  getUserYearsMonths=(birthdayDate,actualDate)=>{
    let years = '';
    let months = '';
    if (actualDate.getMonth()-birthdayDate.getMonth()>0){
      years = actualDate.getFullYear()-birthdayDate.getFullYear();
      months = actualDate.getMonth()-birthdayDate.getMonth();
    }else if(actualDate.getMonth()-birthdayDate.getMonth() == 0){
      if (actualDate.getDay()< birthdayDate.getDate()){
        years = (actualDate.getFullYear()-birthdayDate.getFullYear())-1;
        months = 12;
      }else{
        years = (actualDate.getFullYear()-birthdayDate.getFullYear());
        months = 0;
      }
    }else if(actualDate.getMonth() - birthdayDate.getMonth() < 0){
      years = (actualDate.getFullYear() - birthdayDate.getFullYear())-1;
      months = (11 - birthdayDate.getMonth()) + actualDate.getMonth();
    }
    return {years,months};
  }
  render(){
    let {
      user,
      user:{
        firstname,
        lastname,
        dob,
        email,
        custom_attributes
      },isMobile } = this.props;
    if(user){
      const name = `${firstname} ${lastname}`;
      const data = {
        name,
        email
      };
      if (dob && dob.length){
        let birthdayDate = new Date(dob);
        data['birthday'] = `${("0" + birthdayDate.getDate()).slice(-2)}/${("0"+ (birthdayDate.getMonth()+1)).slice(-2)}/${birthdayDate.getFullYear()}`;
      }
      if (!custom_attributes){
        custom_attributes = [];
      }
      let customer_phone = (custom_attributes.filter(item=>item.attribute_code=='customer_phone').length) ?
                              custom_attributes.filter(item=>item.attribute_code=='customer_phone')[0]['value']:'';

      if (customer_phone.length){
        data['telephone'] = customer_phone;
      }
      return (
        <div className="contact-info">
          <span className="sub-section-title">{this.i18n.t('user.myAccount.contactInfo.sectionTitle')}</span>
          <div className="contact-info-container col-group">
            {
              Object.keys(data).map((key,index2)=>{
                return(
                  <div key={index2} className="customer-info-field field">
                    <label className="label">
                      {this.i18n.t(`user.myAccount.contactInfo.${key}Label`)}:
                    </label>
                    <span className="value">{data[key]}</span>
                  </div>
                )
              })
                  
            }
            <div className="contact-info-actions">
           {
             isMobile ?
             
             <Link {...this.router.get('myAccountEditCustomer')}><a className="section-action-button">{this.i18n.t('user.myAccount.editCustomer')}</a></Link>
             :
              <span className="section-action-button" onClick={this.openSideBar}>{this.i18n.t('user.myAccount.editCustomer')}</span>
           }
            </div>

          </div>
        </div>
      );
    }else{
      return null;
    }

  }

}

function mapStateToProps({isMobile}){
  return { isMobile };
}
export default connect(mapStateToProps,{ openSideBar })(ContactInfo);
