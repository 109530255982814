import React,{ Component }  from 'react';
import SwatchItem           from '@frontend/components/product-swatches/SwatchItem';

import '@frontend/components/product-swatches/swatch.less';

class Swatches extends Component{
  render(){
    const { className,options=[],productId,product,goToTop=false } = this.props;

    return (
      <div className={`ez-swatches ${className}`}>
        { options &&
          options.map((option,index)=>{
            return (
              <SwatchItem key={`${productId}/${option.attribute_id}`}
                          option={option}
                          product={product}
                          onOptionSelected={this.props.onOptionSelected}
                          mediaGallery = {this.props.mediaGallery}
                          productId = { productId }
                          goToTop = {goToTop}
              />
            )
          })
        }
      </div>
    );
  }
}

export default Swatches;
