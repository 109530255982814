import EzComponent    from '@frontend/components/EzComponent';

import Address        from '@frontend/components/my-account/addresses/Address'

import '@frontend/components/my-account/addresses/other-addresses.less';

class OtherAddresses extends EzComponent {
  searchOtherAddresses = (addresses) => {
    let others = [];
    addresses.map(address=>{
      
      if (address.default_shipping || address.default_billing){
        return null;
      }else{
        others.push(address);
      }
    });
    return others;
  }

  render(){
    const { addresses=[] } = this.props;
    const others = this.searchOtherAddresses(addresses);
    return (
      <div className="other-addresses">
      { (others.length!==0) ?
        <div className="addresses-list">
        {
          others.map((address,index)=>{
            return(
              <div key={index} className="addresses-list-item">
                <Address address={address} />
              </div>
            )
          })
        }
        </div>
        :
        <span className="not-other-addresses">{this.i18n.t('user.myAccount.addresses.notOtherAddresses')}</span>
      }
      </div>
    );


  }

}

export default OtherAddresses;
