import {Coupon as StoreCoupon}  from '@themes/store/frontend/components/coupons/Coupon';

import AddCouponButton          from '@frontend/components/coupons/button/Add';
import DeleteCouponButton       from '@frontend/components/coupons/button/Delete';

import '@frontend/components/coupons/coupon.less';

class Coupon extends StoreCoupon {

  render(){
    const { inputText }  = this.state;
    const { showCancel } = this.props;

    return (
      <div className="coupon-actions">
        <label className="label coupon-input">
          <span>{this.i18n.t(`coupons.inputLabel`)}</span>
        </label>
        <input
          name='coupon'
          type='text'
          placeholder={this.i18n.t('coupons.couponCode')}
          value={inputText}
          onChange={this.onInputChange}
          autoComplete="off"
        />
        {
          (!showCancel) &&
          <AddCouponButton couponCode={inputText}  />
        }
        {
          showCancel &&
          <DeleteCouponButton  deleted={this.deleted} />
        }
      </div>
    );
  }
}

export default Coupon;
