import EzComponent              from '@frontend/components/EzComponent';
import {ProductListItem as
        ProductListItemMagento} from '@themes/magento/frontend/components/product-list/product-item/ProductListItem';
import Link                     from 'next/link';
import { connect }              from 'react-redux'
import _find                    from 'lodash/find';
import _get                     from 'lodash/get';
import _has                     from 'lodash/has';
import Swatches                 from '@frontend/components/product-swatches/Swatches';
import AddToCartButton          from '@frontend/components/buttons/add-to-cart-button/AddToCartButton'
import WishlistButton,{ADD_ITEM_ACTION,
DELETE_ITEM_ACTION}             from '@frontend/components/buttons/wishlist/WishlistButton'
import ProductPrice             from '@frontend/components/product/price/ProductPrice'
import { LazyLoadImage }        from 'react-lazy-load-image-component';
import StockNotificationModal   from '@frontend/components/stock-notification/modal';

import '@frontend/components/product-list/product-item/product-list-item.less';

const PLACEHOLDER_IMAGE_STRING = "placeholder";
const PLACEHOLDER_IMAGE_URL    = "/static/images/cat-placeholder.jpg";
const TOP_LABEL                = "top";

class ProductListItem extends ProductListItemMagento{
  state = {
    selected_options:   [],
    selectedProductId:  '',
    qty:1,
    enableStockForm: false
  }
  addQty = (addend) => () => {
    this.setState(state =>{
      const newQty = (state.qty + Number(addend)) > 0 ? (state.qty + Number(addend)) : 1 ;

      return { qty: newQty };
    });
  }
  dispatchOnCategoryClick(){
    const {product,category} = this.props;
    this.context.scriptManager.dispatch("SelectItem", {
      item_list_id:   "category_list", 
      item_list_name: "Category List", 
      product
    });
    this.context.scriptManager.dispatch("CategoryClick", {product, category});
  }

  getPromoImage(media_gallery,promo_image_path)
  {
    let promo_image = false;
    for (var i = 0; i < media_gallery.length; i++) {
      let current = media_gallery[i];
      if (typeof(current) != "undefined") {
        if (current.includes(promo_image_path)) {
          promo_image = current;
        }
      }
    }
    if (promo_image == false) {
      promo_image = media_gallery[media_gallery.length -1]
    }
    return promo_image;
  }

  oddOrEven(x) {
    return ( x & 1 ) ? "odd" : "even";
  }

  

  render(){
    let {
      product,
      wishlistButtonEnable,
      showRating,
      className='',
      containerClass,
      position,
      magentoSlider,
      isMobile,
      pattern
     }                              = this.props;
    const { selected_options,qty }  = this.state;
    const ratingValue               = (product.rating_summary*5)/100;
    const product_path          = this.getProductPath(product);

    if ( typeof product.product_options === 'string' ){
      product.product_options = JSON.parse(product.product_options);
    }

    if (product){
      let {
        product_id,
        path,
        media_gallery,
        name,
        product_options,
        prices,
        is_configurable
      }               = product;
      const {
        regular_price,
        final_price,
        tier_price
      }               = this.getPrices(prices);
      media_gallery   = this.getProductListMediaGallery(media_gallery);
      let chosenImage = false;
      let additionalCssClass = product.label && product.label  == TOP_LABEL ? "featured-product" : "";
      const isBundle = _has(product, 'product_options.extension_attributes.bundle_options');
      additionalCssClass = " " + additionalCssClass + " " + className;


      if (additionalCssClass == "featured-product") {
        if (this.oddOrEven(position)=="odd") {
          additionalCssClass = additionalCssClass + " odd";
        } else {
          additionalCssClass = additionalCssClass + " even";
        }
      }

      if (containerClass == "slider-products") {
        if (product.additional_image != null) {
          chosenImage = this.getPromoImage(media_gallery, product.additional_image)
        }
      }

      if (chosenImage === false) {
        if (!product.additional_image) {
          chosenImage = media_gallery[0];
        }
      }

      return (
        <li className={`ez-product-item  ${additionalCssClass}`}>
          <div className="product-container">
            <div className="product-gallery-container">
            {
                _get(product,'label') &&
                <span className="galery-product-label label">{product.label}</span>
              }
              {
                _get(product,'discount_label') &&
                <span className="galery-product-label discount_label">{product.discount_label}</span>
              }
              {
               _get(product,'sticker') &&
                <span className="galery-product-label sticker">{product.sticker}</span>
              }
              <Link {...this.router.get('product',{id:product_id,slug:product_path})}>
                <div className="ga-click-div" onClick={this.dispatchOnCategoryClick.bind(this)}>
                  {
                    (magentoSlider && isMobile||pattern == "B"&&!isMobile) ?
                      <div className="magento-slider-image-bg" style={{ backgroundImage: "url(" + this.router.webpUrl(chosenImage) + ")" }}></div>
                    :
                      <LazyLoadImage
                          className="product-item-image"
                          src={this.router.webpUrl(chosenImage)}
                          alt={name}
                      />
                  }
                  {
                    product.sticker == "heritage" &&
                    <div className="promo-banner-product-category-view">
                        <span className="pb-main-title">HERITAGE</span>
                        <span className="pb-sub-title">reserva y llévatelo con un regalo muy especial</span>
                    </div>
                  }
                </div>
              </Link>

              <div className="product-actions col-12 hidden">
                <div className="product-qty col-12">
                  <input className="qty-input" value={qty} min="1" max={product.stock} disabled readOnly/>
                  <div className="product-qty-actions">
                    <div className="add-qty"     onClick={this.addQty(1)}>+</div>
                    <div className="remove-qty"  onClick={this.addQty(-1)}>-</div>
                  </div>
                </div>
              </div>
            </div>

            <div className="product-details">
              {
                wishlistButtonEnable &&
                <WishlistButton
                  productId={product_id}
                  product={product}
                  onClickWishlist={this.props.onClickWishlist}
                />
              }
              <div className="product-swatch">
                {
                  (is_configurable) &&
                  <Swatches product={product} productId= {product_id} options={product.product_options} onOptionSelected={this.addSelectedOption}/>
                }
              </div>
              <div className="product-text-data">
                <div className="product-name" onClick={this.dispatchOnCategoryClick.bind(this)}>
                  <Link {...this.router.get('product',{id:product_id,slug:product_path})}><a><span>{name}</span></a></Link>
                </div>
                <div className="product-short-description" onClick={this.dispatchOnCategoryClick.bind(this)}>
                  <Link {...this.router.get('product',{id:product_id,slug:product_path})}><a><span>{product.product_subtitle}</span></a></Link>
                </div>
              </div>

                <ProductPrice
                  regular_price={regular_price}
                  final_price={final_price}
                  tier_price={tier_price}
                />
              
              
              {
                (product.stock > 0 && !isBundle) && 
                <AddToCartButton
                  item={product}
                  selected_options={selected_options}
                  qty={qty}
                  final_price={final_price}
                />
              }
              {
                (product.stock <= 0 && !isBundle) &&
                <StockNotificationModal product={product} />
              }
              {
                (!!isBundle) &&
                <Link {...this.router.get('product', { id: product_id, slug: product_path })}>
                  <a><span className="more-info-product-list">Más información</span></a>
                </Link>
              }
             
            </div>
          </div>
        </li>
      );
    }else{
      return null;
    }

  }
}

function mapStateToProps(state){
  return {
    storeConfiguration:state.storeConfig,
    isMobile: state.isMobile,
    user: state.user
   }
}

export default connect(mapStateToProps)(ProductListItem);
