import EzComponent      from '@frontend/components/EzComponent';
import { connect }      from 'react-redux'
import dynamic          from 'next/dynamic';

const DisclamerDropdown         = dynamic(()=> import('@frontend/components/minicart/disclamer-dropdown'));

import '@frontend/components/minicart/free-shipping-status-bar/index.less';

export class FreeShippingStatusBar extends EzComponent{
	getFreeShippingData = () => {
		const { freeShippingMin,disclamerText,countRewardText,
			successRewardText,freeShippingGroup, freeShippingMinGroup } = this.props.storeConfiguration;
		const { cartData: { total: cartTotal },userGroup } = this.props;
		let shippingMin = userGroup == freeShippingGroup ? freeShippingMinGroup : freeShippingMin;
		let freeShippingLeft = freeShippingMin;
		let freeShippingPercentage = 0;

		freeShippingLeft = parseFloat(shippingMin - cartTotal).toFixed(2);
		if (freeShippingLeft < 0){
			freeShippingLeft = 0;
		}
		freeShippingPercentage = (cartTotal * 100)/shippingMin;
		freeShippingPercentage = Math.ceil(freeShippingPercentage);
		if (freeShippingPercentage > 100){
			freeShippingPercentage = 100;
		}
		return {freeShippingLeft,freeShippingPercentage,disclamerText,countRewardText,successRewardText};
	}	
  render(){
    let {storeConfiguration:{ currencySymbol }}		= this.props;
	let {freeShippingLeft,freeShippingPercentage,disclamerText,countRewardText,successRewardText } = this.getFreeShippingData();
    return(
		<div className="free-shipping-status-bar">
			{
				!!disclamerText &&
					<DisclamerDropdown title={this.i18n.t('freeShippingStatusBar.caution')} children={disclamerText}/>
			}
					<div className="free-shipping-left">
							<span>
								{
									(!!freeShippingLeft) ?
										!!countRewardText && countRewardText.replace(/%f[a-z]*%/g,freeShippingLeft + currencySymbol) 
									:
										successRewardText
								}
							</span>
					</div>
					<div className="status-bar">
						<div className="complete" style={{width:`${freeShippingPercentage}%`}}></div>
					</div>
	    </div>
    )
  }
}

function mapStateToProps({isMobile,storeConfig,minicart,user:{group_id}}){
  return { 
	  isMobile,
	  storeConfiguration:storeConfig, 
	  cartData: minicart.cart,
	  userGroup:group_id
	}
}

export default connect(mapStateToProps)(FreeShippingStatusBar);
