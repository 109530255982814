import EzComponent            from '@frontend/components/EzComponent';

import { connect }            from 'react-redux'
import CartProductItem        from '@frontend/components/minicart/CartProductItem';
import CheckoutButton         from '@frontend/components/buttons/checkout-button/CheckoutButton';
import Coupon                 from '@frontend/components/coupons/Coupon';

import '@frontend/components/minicart/minicart.less';

export class MiniCart extends EzComponent{

  toggleShowCart = (showCart) => () =>{
    this.props.onMinicartChange(showCart);
  }
  render(){
    const { cartData,
      storeConfiguration,
      showCart }                          = this.props;
    const products 			                  = (cartData && cartData.items)? cartData.items: [];
    const showCancelCoupon 		            = (cartData && cartData.subtotal != cartData.subtotal_discount);
    const numProducts                     = products.length;

    return (
      <div className={`ez-minicart-wraper ${this.props.colData}`}>
        <div className="show-cart" onClick={this.toggleShowCart(true)}>
          <span className="cart-text">
            <span className="cart-title">{this.i18n.t('miniCart.cart')}</span>
            <span className="items-counter">{numProducts}</span>
          </span>
        </div>
        {
          showCart &&
          <>
            <div className="cart-overlay"  onClick={this.toggleShowCart(false)}></div>
            <div className="cart-content">
              <div className="close-icon" onClick={this.toggleShowCart(false)}></div>
              {(products.length) ?
                <>
                  <div className="minicart-title"><span>{this.i18n.t('miniCart.cart')}</span></div>
                  <ul className="product-items">
                  {
                    products.map((product,index)=>{
                      product['currency'] = storeConfiguration.currencySymbol;
                      return(
                        <li key={index} className="product-item">
                          <CartProductItem
                            key={index}
                            data={product}/>
                        </li>
                      )
                    })

                  }
                  </ul>
                  <div className="minicart-footer">
                    <div className="ez-minicart-totals">
                      <div className={`subtotal col-group ${showCancelCoupon ? 'old-subtotal' :''}`}>
                        <span className="label-subtotal col-mb-8">{`${this.i18n.t('miniCart.subTotal')}:`}</span>
                        <span className= "qty-subtotal col-mb-4">{`${cartData.subtotal} ${storeConfiguration.currencySymbol}`}</span>
                      </div>
                      {
                        showCancelCoupon &&
                          <div className="subtotal subtotal-discount col-group">
                            <span className="label-subtotal col-8">{`${this.i18n.t('miniCart.subTotalDiscount')}:`}</span>
                            <span className= "qty-subtotal col-4">{`${cartData.total} ${storeConfiguration.currencySymbol}`}</span>
                          </div>
                      }
                    </div>
                    <div className="ez-minicart-actions">
            		      <Coupon showCancel={showCancelCoupon} couponCode={cartData.coupon_code}/>
                      <button className="checkout-continue-button ez-secondary-button" onClick= {this.toggleShowCart(false)}>{this.i18n.t('miniCart.continueBuying')}</button>
                      <CheckoutButton />
                    </div>
                  </div>
                </>
                :
                <span className="ez-empty-minicart">{this.i18n.t('miniCart.noItems')}</span>
              }
            </div>
          </>

        }
      </div>
    );
  }
}
function mapStateToProps(state){
  return {
    cartData:           state.minicart.cart,
    storeConfiguration: state.storeConfig
  }
}
export default connect(mapStateToProps)(MiniCart);
