import React,{Component}      from 'react';
import {withScroll}     from '@frontend/lib/withScroll';

class LoadMoreProducts extends Component{

  render(){
    const { scrollData,loadMore,loading,offset=0,infiniteScrollOffset=null }  = this.props;
    const heightOffset = infiniteScrollOffset ? infiniteScrollOffset : 0;
    const { scrollHeight,scrollTop,clientHeight }   = scrollData;
    const position                                  = scrollTop + clientHeight + heightOffset;
    const totalHeight                                = scrollHeight - offset;
    if (!loading && position && totalHeight > 0 &&  (position >= totalHeight)){
      loadMore();
    }
    return(
      <></>
    )
  }
}

export default withScroll(LoadMoreProducts)
