import React, { Component }     from 'react';
import EzComponent              from '@frontend/components/EzComponent';
import { setPopupVisibility }   from '@frontend/actions/popup';
import HtmlParser               from '@frontend/components/HtmlParser';
import _get                     from 'lodash/get';
import { connect }              from 'react-redux';
import settings                 from '@frontend/settings/components/popup';


import '@frontend/components/discount-popup/index.less';

class DiscountPopup extends EzComponent {

    /**
     * Closes the popup and sets a cookie to hide the popup in the next hour
     */
    handleCloseButton() {
        this.props.setPopupVisibility(0, _get(settings, "keys.main"));
    }

    /**
     * Checks if the popup should render
     * @returns boolean
     */
    isVisible = () => {
        return _get(this.props, "popup.block.is_active") && _get(this.props, "popup.visibility");
    }

    render() {
        if (!this.isVisible()) return null;

        return (
            <div className="discount-popup-etnia">
                <span onClick={() => this.handleCloseButton()} className="close-discount-popup"></span>
                {
                    HtmlParser.staticParseComponents(_get(this.props, "popup.block.content"))
                }
            </div>
        )
    }
}
function mapStateToProps(store) {
    return { popup: _get(store, `popup.byKey.${_get(settings, "keys.main")}`) }
}

export default connect(mapStateToProps,{setPopupVisibility})(DiscountPopup);