export const openSwatchesModal = (option, product, onOptionSelected, setParentSwatchState, selectedValue) => {
    return {
      type: "OPEN_SWATCHES_MODAL",
      product,
      option,
      onOptionSelected,
      setParentSwatchState,
      selectedValue
    }
  }
  
  export const closeSwatchesModal = () => {
    return {
      type: "CLOSE_SWATCHES_MODAL"
    }
  }


