import React, { Component }  from 'react';
import { connect }           from 'react-redux';
import Message               from '@frontend/components/flash-messages/Message';

import '@frontend/components/flash-messages/flash-messages.less';

export class FlashMessages extends Component {
  state ={
    changes: true,
  }

  render(){
    const {header,flashMessages:{messages}} = this.props;
    return (
      <>
      {
      messages &&
        <div className="flash-messages-container" data-count={messages.length} style={{paddingTop:header.height}}>
        {
          messages.map(child=>{
            const {message,id} = child
            const {type, text, details } = message;
            return (
              <Message key={id} id={id} type={type} text = {text} details={details}/>
            )
          })
        }
        </div>
      }
      </>
    )

  }

}

function mapStateToProps ({header,flashMessages}){
  return { header,flashMessages };
}
export default connect(mapStateToProps)(FlashMessages);
