import {HtmlParser as BaseParser }  from '@themes/base/frontend/components/HtmlParser';
import dynamic from 'next/dynamic'

const ProductSliderByIds           = dynamic(()=> import('@frontend/components/product-slider/ProductSliderByIds'));

export class HtmlParser extends BaseParser{

  static getComponentObjects(){
    const baseComponents = super.getComponentObjects();

    return {
      ...baseComponents,
      "product-slider": ProductSliderByIds
    };
  }
}
export default HtmlParser;
