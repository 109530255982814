import React,{ Component }  from 'react';
import { connect }          from 'react-redux'

import '@frontend/components/product/price/product-price.less';

export class ProductPrice extends Component{
  haveSpecialPrice = ()=>{
    const { regular_price,final_price,tier_price } = this.props;
    if (final_price && regular_price && parseFloat(final_price) < parseFloat(regular_price) ) {
      return true;
    }
    if (tier_price && regular_price && tier_price && parseFloat(tier_price) < parseFloat(regular_price) ){
      return true;
    }
    return false;
  }

  roundUp = (num, precision) =>{
    precision = Math.pow(10, precision);
    let result = Math.ceil(num * precision) / precision
    return result.toFixed(2);
  }

  render(){
    const { regular_price,final_price,tier_price,className='' } = this.props;
    const currency                                 = this.props.storeConfiguration.currencySymbol;
    const renderRegular                            = this.haveSpecialPrice();
    const price                                    = (tier_price && tier_price<final_price) ? tier_price : final_price;
    return (

      <div className={`product-price-box ${renderRegular ? 'with-discount':''} ${className}`}>
        {
          renderRegular &&
          <span key="regular-price" className="product-regular-price">{`${this.roundUp(regular_price,2)} ${currency}`}</span>
        }
        <span key="final-price" className="product-final-price">{`${this.roundUp(price,2)} ${currency}`}</span>
      </div>
    );
  }
}
function mapStateToProps(state){
  return {storeConfiguration:state.storeConfig}
}
export default connect(mapStateToProps)(ProductPrice);
