import EzComponent              from '@frontend/components/EzComponent';
import { connect }              from 'react-redux';

import { 
	closeSideBar,
	openSideBar	}    			from '@frontend/actions/side-bar';

import '@frontend/components/side-bar/actions/index.less';

class SideBarActions extends EzComponent{
	closeSideBar = ()=>{
		this.props.closeSideBar();
	}
	openSideBar = ()=>{
		let { sideBar:{backTo} } = this.props;
		const back = backTo.pop();
		this.props.openSideBar(back,backTo);
	}
	render() {
		const { sideBar:{backTo} } = this.props;
		return (
            <div className="side-bar-actions">
			    <div className="close-action" onClick={this.closeSideBar}><span></span></div>
				{
					backTo && (backTo.length!=0) &&
					<div className="back-action" onClick={this.openSideBar}><span></span></div>
				}	
			</div>
		);
	}
  
}
function mapStateToProps({sideBar}){
	return {
		sideBar
	}
}
export default connect(mapStateToProps,{closeSideBar,openSideBar})(SideBarActions);
  