import EzComponent          from '@frontend/components/EzComponent';
import Link                 from 'next/link';
import { connect }          from 'react-redux';

import '@frontend/components/footer/footer.less';

const FIND_YOUR_STORE_PAGE         = {1: {pageId: 16, slug: "storelocator"}, 5: {pageId: 101, slug: "storelocator"}};
const GENERAL_CONDITIONS_PAGE      = {1: {pageId: 8, slug: "condiciones-generales"}, 5: {pageId: 93, slug: "general-conditions"}};
const LEGAL_BASE_PAGE              = {1: {pageId: 5, slug: "bases-legales"}, 5: {pageId: 89, slug: "legal-terms-and-conditions"}};
const LOVELY_CLUB_CONDITIONS_PAGE  = {1: {pageId: 15, slug: "bases-legales-club-lovely"}, 5: {pageId: 100, slug: "lovely-club-legal-terms-and-conditions"}};
const TRIBE_PAGE                   = {1: {pageId: 17, slug: "te-queremos-en-nuestra-tribu"}, 5: {pageId: 102, slug: "we-want-you-in-our-tribe"}};
const LEGAL_WARNING_PAGE           = {1: {pageId: 7, slug: "aviso-legal"}, 5: {pageId: 92, slug: "legal-notice"}};
const FAQ_PAGE                     = {1: {pageId: 13, slug: "faq"}, 5: {pageId: 98, slug: "faq"}};
const WE_ARE_FAMILY_PAGE           = {1: {pageId: 9, slug: "we-are-family"}, 5: {pageId: 94, slug: "we-are-family"}};
const TERMS_PAGE                   = {1: {pageId: 84, slug: "terminos-y-condiciones-del-sitio"}, 5: {pageId: 114, slug: "terms-and-conditions-of-the-site"}};

class MenuFooter extends EzComponent {
  render(){
    const { isMobile, storeConfig: { storeId } } = this.props;
    return (
      <div className="ez-menu-footer col-group">
        <div className="ez-menu-footer-top">
          <Link {...this.router.get('cmsPage',{id:FIND_YOUR_STORE_PAGE[storeId].pageId,slug:FIND_YOUR_STORE_PAGE[storeId].slug})}>
            <a className="ez-mft-link">
              {this.i18n.t('menuFooter.shops')}
            </a>
          </Link>
          <Link {...this.router.get('cmsPage', { id: TERMS_PAGE[storeId].pageId, slug: TERMS_PAGE[storeId].slug })}>
            <a className="ez-mft-link">
              {this.i18n.t('menuFooter.terms')}
            </a>
          </Link>
          <Link {...this.router.get('cmsPage',{id:WE_ARE_FAMILY_PAGE[storeId].pageId,slug:WE_ARE_FAMILY_PAGE[storeId].slug})}>
            <a className="ez-mft-link">
              {this.i18n.t('menuFooter.about')}
            </a>
          </Link>
        </div>
        <div className="ez-menu-footer-bottom col-group">
          <ul className="ez-menu-footer-bottom-list">
            <li className="list-item"><Link {...this.router.get('cmsPage',{id:GENERAL_CONDITIONS_PAGE[storeId].pageId,slug:GENERAL_CONDITIONS_PAGE[storeId].slug})}><a className="ez-mfb-link">{this.i18n.t('menuFooter.conditions')}</a></Link></li>
            <li className="list-item"><Link {...this.router.get('cmsPage',{id:LOVELY_CLUB_CONDITIONS_PAGE[storeId].pageId,slug:LOVELY_CLUB_CONDITIONS_PAGE[storeId].slug})}><a className="ez-mfb-link">{this.i18n.t('menuFooter.lovely')}</a></Link></li>
            <li className="list-item"><Link {...this.router.get('cmsPage',{id:LEGAL_BASE_PAGE[storeId].pageId,slug:LEGAL_BASE_PAGE[storeId].slug})}><a className="ez-mfb-link">{this.i18n.t('menuFooter.legalBase')}</a></Link></li>
            <li className="list-item"><Link {...this.router.get('cmsPage',{id:TRIBE_PAGE[storeId].pageId,slug:TRIBE_PAGE[storeId].slug})}><a className="ez-mfb-link">{this.i18n.t('menuFooter.jobs')}</a></Link></li>
            <li className="list-item"><Link {...this.router.get('cmsPage',{id:LEGAL_WARNING_PAGE[storeId].pageId,slug:LEGAL_WARNING_PAGE[storeId].slug})}><a className="ez-mfb-link">{this.i18n.t('menuFooter.legalWarning')}</a></Link></li>
            <li className="list-item"><Link {...this.router.get('cmsPage',{id:FAQ_PAGE[storeId].pageId,slug:FAQ_PAGE[storeId].slug})}><a className="ez-mfb-link">{this.i18n.t('menuFooter.faq')}</a></Link></li>
          </ul>
        </div>
        {
          isMobile &&
          <span className="ez-nav-span">
            ES / EN
          </span>
        }
      </div>
    );
  }
}

function mapStateToProps({storeConfig, isMobile}){
  return {
    storeConfig,
    isMobile
  }
}
export default connect(mapStateToProps, {})(MenuFooter);
