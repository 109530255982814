import { EditCustomerForm as
          EditCustomerFormMagento}          from '@themes/magento/frontend/components/my-account/me/EditCustomerForm';

import _has                               from 'lodash/has'
import _unset                             from 'lodash/unset'
import moment                             from 'moment';
import { connect }                        from 'react-redux'
import Link                               from 'next/link';

import dynamic              from 'next/dynamic';

const TextInput            = dynamic(()=> import('@frontend/components/basic-components/form/TextInput'));
const AutoSelectInput      = dynamic(()=> import('@frontend/components/basic-components/form/auto-select-input/AutoSelectInput'));
const DateInput            = dynamic(()=> import('@frontend/components/basic-components/form/date-input/DateInput'));
import { openSideBar }                    from '@frontend/actions/side-bar';
import { updateUser }                     from '@frontend/actions';

export class EditCustomerForm extends EditCustomerFormMagento{
  state = {
    user: null,
    isSignedIn: false,
    formData: {
      firstname:        { field:'firstName',       type:'text',     value:'', validation:'lettersWithSpaces,notEmpty',        required:true,  className:'col-6 col-mb-6 two-fields first'},
      lastname:         { field:'lastName',        type:'text',     value:'', validation:'lettersWithSpaces,notEmpty',        required:true,  className:'col-6 col-mb-6 two-fields last'},
      dob:              { field:'birthday',        type:'date',     value:'', validation:'validDate(DD/MM/YYYY)',             required:false, className:'col-12 col-mb-12'},
      customer_phone:   { field:'telephone',       type:'text',     value:'', validation:'numbers',                           required:false, className:'col-12 col-mb-12'}
    },
    error:[]
  }
  updateCustomer = async (e)=>{
    const {
      formData: {
        firstName,
        lastName,
        birthday,
        telephone,
        email,
        password
      },
      formData
      } 	 = this.state;
    const { user:{ token },user,isMobile } = this.props;
    e.preventDefault();
    e.stopPropagation();
    let userData = user;
    
    if(this.validateFields()){
      const { storeConfiguration:{storeId,websiteId,address_types},storeCountries }  = this.props;
      userData['addresses'].map(address=>{
        address.custom_attributes=[];
      });
      Object.keys(formData).map(key=>{
        if((formData[key]['value'].length || parseInt(formData[key]['value'])) && formData[key]['value']!=userData[key]){
          if (userData.hasOwnProperty(key)){
            if(key=='dob'){
              userData[key] = moment(formData[key]['value'],'DD/MM/YYYY').format('YYYY/MM/DD');
            }else{
              userData[key] = formData[key]['value'];
            }
          }else if(userData['custom_attributes']&&userData['custom_attributes'].filter(item=>item.attribute_code==key)){
            let newCustomAttributes = [];
            userData['custom_attributes'].map((attr=>{
              if(attr.attribute_code==key &&
                formData[attr['attribute_code']] &&
                formData[attr['attribute_code']]['value']!=attr.value){
                  newCustomAttributes.push({attribute_code:key,value:formData[attr['attribute_code']]['value']});
              }else{
                newCustomAttributes.push(attr);
              }
            }));
            userData['custom_attributes']= newCustomAttributes;
          }
        }
      });
      _unset(userData,'token');
      _unset(userData,'error');
      _unset(userData,'orders');
      _unset(userData,'wishlist_items');
      _unset(userData,'isSignedIn');
      let variables = {
        token,
        customer:userData
      };
      await this.props.updateUser(this.context,variables);
      if(isMobile){
        this.router.redirect('myAccountMe');
      }else{
        this.props.openSideBar('my-account');
      }
    }
  }
  onFocusSelect= (name)=>{
    let { formData } = this.state;
    formData[name]['focused'] = true;
    this.setState({ formData });
  }
  onBlurSelect= (name)=>{
    let { formData } = this.state;
    formData[name]['focused'] = false;
    this.setState({ formData });
  }
  handleCheckBox = ({target:{name}}) =>{

    let { formExtra } = this.state;
    formExtra[name].value = !formExtra[name].value;
    this.setState({formExtra});
  }
  toggleCheckBox = (key)=>()=>{
    let { formExtra } = this.state;
    formExtra[key].value = !formExtra[key].value;
    this.setState({formExtra});
  }
  toggleShowPassword = ()=>{
    this.setState((state)=>({showPassword:!state.showPassword}));
  }

  render(){
    const { formData,formExtra,error,selectValues,showPassword } = this.state;
    const { isMobile } = this.props;
    return (
      <div className="edit-customer-form ez-form">
        <form className="col-group">
          {
            Object.keys(formData).map((inputKey,index)=>{
              let { field,
                      value,
                      type,
                      focused=false,
                      className='',
                      required }  = formData[inputKey];
              const inputError    = (error[inputKey]) ? error[inputKey] : '';

              type = (type=='select' && selectValues[field] && !selectValues[field].length) ? 'text': type;

              return(
                <div key={index} className={`field ${type}  ${className} ${(focused) ? 'focused':''}`}>
                  {
                    (type=='date') ?
                      <div className={`input ${field}`}>
                        <DateInput
                          value={value}
                          name={inputKey}
                          onChange={this.updateValue}
                          onFocus= {this.onFocusSelect}
                          onBlur= {this.onBlurSelect}
                        />
                      </div>
                    :
                    (type=='select' && selectValues[field] && selectValues[field].length) ?
                    <AutoSelectInput
                      className = {`input ${field} ${(focused||value) ? 'active':''}`}
                      options = {selectValues[field]}
                      name={inputKey}
                      onChange={this.handleSelect}
                      onFocus= {this.onFocusSelect}
                      onBlur= {this.onBlurSelect}
                      value = {value}
                    />
                    :
                    <TextInput
                      className={`input ${field} ${(value.length) ? 'active':''}`}
                      type={(type=='password' && showPassword) ? 'text' : type}
                      name={inputKey}
                      onChange={this.updateValue}
                      value={value}
                    />
                  }
                  <label className= {`label ${(required) ? "required":""}`} >
                    <span>{this.i18n.t(`user.createAccount.${field}`)}</span>
                  </label>
                  {
                    type=='password' &&
                    <span className="toggle-show-password" onClick={this.toggleShowPassword}>{this.i18n.t(`user.signIn.${showPassword ? 'hide' : 'show'}Password`)}</span>
                  }
                  {
                    (inputError.length!==0) &&
                    <div className="ez-error-wrapper">
                      <span>{this.i18n.t(inputError)}</span>
                    </div>
                  }
                </div>
              )
            })

          }
          </form>
          <div className="required-field-text"><span>{this.i18n.t('user.createAccount.requiredFields')}</span></div>
          <div className="edit-customer-actions">
            <button className="save-button" onClick={this.updateCustomer}>{this.i18n.t('user.myAccount.contactInfo.save')}</button>
            {
              isMobile &&
              <Link {...this.router.get('myAccountMe')}><a className="cancel-button ez-secondary-button">{this.i18n.t('user.myAccount.contactInfo.cancel')}</a></Link>
            }
        </div>
      </div>
    )
  }
}
function mapStateToProps({user,storeConfig,storeCountries,isMobile}){
  return {
    isSignedIn:user.isSignedIn,
    user,
    storeConfiguration:storeConfig,
    storeCountries,
    isMobile
  };
}

export default connect(mapStateToProps,{updateUser,openSideBar})(EditCustomerForm);
