import React, { Component } from 'react';
import HtmlParser           from '@frontend/components/HtmlParser';
import { connect }          from 'react-redux';

import '@frontend/components/footer/footer.less';

class Footer extends HtmlParser {
  render(){
    const { footer } = this.props;
    const htmlFooter = (footer && footer.content) ? this.parseComponents(footer.content) : "";

    return (footer) ?
        <div className="ez-footer">
          {
            htmlFooter
          }
        </div>
    :
      <div>Webapp Footer</div>
  }
}

function mapStateToProps({storeConfig,footer}){
  return {
    storeConfiguration: storeConfig,
    footer
  };
}


export default connect(mapStateToProps)(Footer);
