import EzComponent            from '@frontend/components/EzComponent';

import AddCouponButton        from '@frontend/components/coupons/button/Add';
import DeleteCouponButton     from '@frontend/components/coupons/button/Delete';

import '@frontend/components/coupons/coupon.less';

export class Coupon extends EzComponent {
  state={
    inputText: '',
  }
  onInputChange = ({target})=>{
    this.setState({inputText:target.value});
  }

  deleted = () =>{
    this.setState({inputText: ''});
  }

  componentDidMount(){
    const { couponCode } = this.props;

    if(couponCode){
      this.setState({inputText:couponCode});
    }
  }

  render(){
    const { inputText }  = this.state;
    const { showCancel } = this.props;

    return (
      <div className="coupon-actions">
        <label className="label coupon-input">
          <span>{this.i18n.t(`coupons.inputLabel`)}</span>
        </label>
        <input
          name='coupon'
          type='text'
          placeholder={this.i18n.t('coupons.couponCode')}
          value={inputText}
          onChange={this.onInputChange}
        />
        {
          (!showCancel) &&
          <AddCouponButton couponCode={inputText}  />
        }
        {
          showCancel &&
          <DeleteCouponButton  deleted={this.deleted} />
        }
      </div>
    );
  }
}

export default Coupon;
