import EzComponent        from '@frontend/components/EzComponent';

import DefaultAddresses   from '@frontend/components/my-account/addresses/DefaultAddresses';

import '@frontend/components/my-account/me/addresses-info.less';

class AddressesInfo extends EzComponent {
  render(){
    const { user } = this.props;
    const addresses = ( user ) ? user.addresses : [];
    if (!addresses){
      return null;
    }
    return (
      <div className="addresses-info">
        <div className="addresses-info-container">
          <DefaultAddresses addresses={addresses} goToAddressBook={true}/>
        </div>
      </div>
    );
  }

}

export default AddressesInfo;
