import React             from 'react';
import EzComponent       from '@frontend/components/EzComponent';
import dynamic           from 'next/dynamic';

const HtmlToReact         = dynamic(()=> import('@frontend/components/html-to-react'));
const TextSlider          = dynamic(()=> import('@frontend/components/basic-components/text-slider'));
const LazyImage           = dynamic(()=> import('@frontend/components/lazy/image'));
const LazyComponent       = dynamic(()=> import('@frontend/components/lazy/component'));
const ImagesSlider        = dynamic(() => import('@frontend/components/basic-components/images-slider'));
const ScheduleVisibility  = dynamic(() => import('@frontend/components/schedule-visibility'));


export class HtmlParser extends EzComponent{
  // processNodeDefinitions = new HtmlToReact.ProcessNodeDefinitions(React);
  // parser                 = new HtmlToReact.Parser();

  static getAttributesObjects(){
    return{
      "classname":    "className",
      "datefrom":     "dateFrom",
      "dateto":       "dateTo",
      "hoursfrom":    "hoursFrom",
      "hoursto":      "hoursTo"
    };
  }

  static getComponentObjects(){
    return {
      "text-slider":          TextSlider,
      "lazy-img":             LazyImage,
      "lazy-component":       LazyComponent,
      "images-slider":        ImagesSlider,
      "schedule-visibility":  ScheduleVisibility,
    };
  }

  static isValidNode(){
    return true;
  }

  static staticParseComponents (html){
    if(!html || !html.length){
      return null;
    }

    if(html.length && !html.match(/^\s*<.*>\s*$/gm)){
      html = `<div>${html}</div>`;
    }

    return (
      <HtmlToReact
        htmlToParse={html}
        isValidNode={this.isValidNode}
        components={this.getComponentObjects()}
        attributes={this.getAttributesObjects()}
      />);
  }

  parseComponents(html){
    return this.constructor.staticParseComponents(html)
  }

  isValidNode() {
    return this.constructor.isValidNode();
  }

  getComponentObjects(){
    return this.constructor.getComponentObjects();
  }

  render(){
    let { html } = this.props;
    return this.parseComponents(html) || null
  }
}

export default HtmlParser;
