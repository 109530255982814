import { useState,useEffect,useMemo } from 'react';
import _debounce                      from 'lodash/debounce';

export default function UseScroll(Component) {
  const [_scrollHeight,setScrollHeight] = useState(0);
  const [_scrollTop,setScrollTop]       = useState(0);
  const [_clientHeight,setClientHeight] = useState(0);
  const scrollData = useMemo(() =>[ _scrollHeight,_scrollTop,_clientHeight],[[ _scrollHeight,_scrollTop,_clientHeight]]);

  const listener = ({target}) => {
    if(target.nodeName === '#document'){
      const {
        documentElement:{
            scrollHeight: _scrollHeight,
            scrollTop:    _scrollTop,
            clientHeight: _clientHeight
          }
      } = target;
      setScrollHeight(_scrollHeight);
      setScrollTop(_scrollTop);
      setClientHeight(_clientHeight);
    }

  };

  const delay = 0;

  useEffect(() => {
    window.addEventListener('scroll', _debounce(listener, delay));
    return () => {
      window.removeEventListener('scroll', listener);
    };
  });

  const [scrollHeight,scrollTop,clientHeight] = scrollData;

  return {scrollHeight,scrollTop,clientHeight};
}
