import stockNotification from '@frontend/services/mutations/registerStockNotification.graphql'

export const registerStockNotification = (apollo, variables) => {
    return async (dispatch) => {
        let stockNotificationResponse = await apollo.mutate(
            {
                mutation: stockNotification,
                variables
            }
        ).then(data => {
            return data;
        });
        return stockNotificationResponse;
    }
}
