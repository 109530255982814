import EzFormComponent             from '@frontend/components/EzFormComponent'
import { ApolloContext }           from 'react-apollo';
import { connect }                 from 'react-redux';
import Link                        from 'next/link';
import ReCAPTCHA                    from "react-google-recaptcha";

import {
  forgottenPassword,
  addFlashMessage
}                                  from '@frontend/actions/index';
import TextInput                   from '@frontend/components/basic-components/form/TextInput';
import {closeSideBar,openSideBar}  from '@frontend/actions/side-bar';

import {
  GOOGLE_RECAPTCHA_API_KEY,
  SUCCESS,
  ERROR
}                                  from '@frontend/constants.js';

import '@frontend/components/forgotten-password/form/index.less';

export class forgottenPasswordForm extends EzFormComponent {

  state = {
    formData: {
      email:{ type:'text', required:true, value:'', validation:'notEmpty,email' },
      captcha:{                           value:'', validation:'notEmptyCaptcha',show:false}

    },
    error: {},
    disableButton: false,
    buttonText: ''
  }

  saveCaptcha=(value)=>{
    const {formData} = this.state;

    formData.captcha.value = value;
    this.setState({ formData });
  }

  handleClick = async (e) =>{
    const { formData }                          = this.state;
    const { forgottenPassword, addFlashMessage, storeConfiguration } = this.props;


    e.preventDefault();
    e.stopPropagation();

    if (this.validateFields()){
      const options= {
        email: this.getValue('email'),
        websiteId: storeConfiguration.websiteId
      };
      this.setState({
        disableButton:true,
        buttonText: this.i18n.t('user.forgottenPassword.button.sending')
      });

      forgottenPassword(this.context,options).then(success =>{
        const response = {
          message:{
            type: success ? SUCCESS : ERROR,
            text: success ? this.i18n.t('user.forgottenPassword.success') : this.i18n.t('user.forgottenPassword.error'),
          }
        };

        const buttonText = this.i18n.t(`user.forgottenPassword.button.${success ? 'sent' : 'send'}`);

        this.setState({disableButton:success,buttonText});
        this.closeSideBar();
        return addFlashMessage(response);
      });
    }
  }
  closeSideBar=()=>{
    this.props.closeSideBar();
  }
  openSideBar = ()=>{
    if (this.props.openSideBar){
      this.props.openSideBar('signin');
    }
  }
  onInputChange = ({target})=>{
    let newData = this.state.formData;

    newData[target.name].value= target.value;
    this.setState({formData:newData});
  }

  render(){
    const { formData,error,disableButton,buttonText }  = this.state;
    const { className,storeConfiguration,isMobile }  = this.props;
    const { googleRecaptchaKey='' }         = storeConfiguration;
    const errorCaptcha                      = (error.captcha) ? this.i18n.t(error.captcha) : '';

    return (
      <form className={`ez-form forgotten-password-form ${className}`}>
        {
          Object.keys(formData).map((key,index)=>{
            const { value,type,required,show=true } = formData[key];
            const inputError                        = (error[key]) ? this.i18n.t(error[key]) : '';

            return(
              show &&
              <div key={index} className="field">
                <TextInput
                  type={type}
                  className={`input ${key} ${(value.length) ? 'active':''}`}
                  name={key}
                  index={index}
                  opIndex={index}
                  onChange={this.updateValue}
                  value={value}
                />
                <label className= {`label ${(required) ? "required":""}`} >
                  <span>{this.i18n.t(`user.signIn.${key}`)}</span>
                </label>
                {
                  (inputError.length!==0) &&
                  <div className="ez-error-wrapper sign-in">
                    <span>{inputError}</span>
                  </div>
                }
              </div>
            )
          })
        }
        <div className="contact-recaptcha">
          <ReCAPTCHA className="subscribe_recaptcha" sitekey={googleRecaptchaKey} onChange={this.saveCaptcha} />
          {
            errorCaptcha &&
              <div className="form-error">
                <p className="ez-error-wrapper">{errorCaptcha}</p>
              </div>
          }
        </div>
        <div className="form-actions">
          <button className="ez-button submit-button" disabled={disableButton} onClick={this.handleClick}>
            {buttonText ? buttonText : this.i18n.t('user.forgottenPassword.button.send')}
          </button>
          {
            isMobile ?
            <Link {...this.router.get('signin')}>
              <a className="signin-link">
                <span>{this.i18n.t('user.forgottenPassword.returnToLogin')}</span>
              </a>
            </Link>
            :
            <span className="signin-link" onClick={this.openSideBar}>
                <span>{this.i18n.t('user.forgottenPassword.returnToLogin')}</span>
            </span>
          }
        </div>


      </form>
    )
  }
}

function mapStateToProps({storeConfig,minicart,isMobile}){
  return {
    storeConfiguration: storeConfig,
    minicart,
    isMobile
  }
}

export default connect(mapStateToProps,{ forgottenPassword,addFlashMessage,closeSideBar,openSideBar } )(forgottenPasswordForm);
