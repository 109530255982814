import EzComponent  from '@frontend/components/EzComponent'
import { connect }        from 'react-redux';

import MegaMenuItem from '@frontend/components/megamenus/MegaMenuItem';

import '@frontend/components/megamenus/megamenus.less';

export class MegaMenu extends EzComponent{
  render(){
    const { megamenu } = this.props;
    const level        = 0;

    return (
      <div className="ez-megamenu">
        <ul className="ez-megamenu-items">
        {
          megamenu.map((item,index)=>{
            return (
              <li className={`megamenu-item level-${level}`} key={index}><MegaMenuItem data={item} level={level+1} /></li>
            );
          })
        }
        </ul>
      </div>
    );
  }
}
function mapStateToProps({megamenu}){
  return {
    megamenu
  };
}
export default connect(mapStateToProps)(MegaMenu);
