export const openAccountModal = () => {
    return {
      type: "OPEN_ACCOUNT_MODAL"
    }
  }
  
  export const closeAccountModal = () => {
    return {
      type: "CLOSE_ACCOUNT_MODAL"
    }
  }
  