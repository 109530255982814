import {AddAddressForm as
        StoreAddAddressForm }   from '@themes/store/frontend/components/my-account/addresses/AddAddressForm';

import _has                     from 'lodash/has'
import _unset                   from 'lodash/unset'

import { connect }              from 'react-redux'
import dynamic              from 'next/dynamic';

const TextInput            = dynamic(()=> import('@frontend/components/basic-components/form/TextInput'));
const AutoSelectInput      = dynamic(()=> import('@frontend/components/basic-components/form/auto-select-input/AutoSelectInput'));
const DateInput            = dynamic(()=> import('@frontend/components/basic-components/form/date-input/DateInput'));

import {updateUser}             from '@frontend/actions'
import Link                     from 'next/link'
import { openSideBar }              from '@frontend/actions/side-bar';

import { setUser }              from '@frontend/actions';

export class AddAddressForm extends StoreAddAddressForm{
  state = {
    user: null,
    isSignedIn: false,
    formData: {
      firstname:        { field:'firstname',       type:'text',     value:'', validation:'lettersWithSpaces,notEmpty',        required:true ,               className:" col-6 col-mb-6 two-fields" },
      lastname:         { field:'lastname',        type:'text',     value:'', validation:'lettersWithSpaces,notEmpty',        required:true ,               className:"col-6 col-mb-6 two-fields" },
      telephone:        { field:'telephone',       type:'text',     value:'', validation:'numbers,notEmpty',                  required:true ,               className:" col-12 col-mb-12" },
      street:           { field:'street',          type:'text',     value:'', validation:'notEmpty',                          required:true ,               className:" col-12 col-mb-12" },
      city:             { field:'city',            type:'text',     value:'', validation:'lettersWithSpaces,notEmpty',        required:true ,               className:" col-6 col-mb-6 two-fields" },
      postCode:         { field:'postCode',        type:'text',     value:'', validation:'notEmpty',                          required:true ,               className:"col-6 col-mb-6 two-fields" },
      region:           { field:'region',          type:'select',   value:'', validation:'notEmpty',                          required:true,focused:false , className:" col-6 col-mb-6 two-fields" },
      country:          { field:'country',         type:'select',   value:'', validation:'notEmpty',                          required:true,focused:false , className:"col-6 col-mb-6 two-fields" }
    },
    selectValues:{
      gender: [{value:1,label:this.i18n.t('genders.male')},{value:2,label:this.i18n.t('genders.female')}],
      defaultAddress: [{value:0,label:this.i18n.t('user.myAccount.addresses.defaultSelect.notDefault')},{value:1,label:this.i18n.t('user.myAccount.addresses.defaultSelect.defaultShipping')},{value:2,label:this.i18n.t('user.myAccount.addresses.defaultSelect.defaultBilling')},{value:3,label:this.i18n.t('user.myAccount.addresses.defaultSelect.defaultShippingBilling')}]
    },
    defaultSelect: {value:0,label:this.i18n.t('user.myAccount.addresses.defaultSelect.label'),focused:false},
    error:[],
    defaultShipping: true,
    defaultBilling: true
  }
  toggleDefaultShipping = ()=>{
    this.setState ((state)=>({defaultShipping: !state.defaultShipping }));
  }
  toggleDefaultBilling = ()=>{
    this.setState ((state)=>({defaultBilling: !state.defaultBilling }));
  }
  handleDefaultAddressSelect = ({value}) =>{
    let {defaultSelect,defaultBilling,defaultShipping} = this.state;
    defaultSelect.value = value;
    switch (value) {
      case 0:
        defaultBilling = false;
        defaultShipping = false;
        break;
      case 1:
        defaultBilling = false;
        defaultShipping = true;
        break;
      case 2:
        defaultBilling = true;
        defaultShipping = false;
        break;
      case 3:
        defaultBilling = true;
        defaultShipping = true;
        break;
      default:
        break;
    }
    this.setState({defaultSelect,defaultBilling,defaultShipping})
  }
  onFocusDefaultAddressSelect= ()=>{
    let {defaultSelect} = this.state;
    defaultSelect['focused'] = true;
    this.setState({ defaultSelect });
  }
  onBlurDefaultAddressSelect= ()=>{
    let {defaultSelect} = this.state;
    defaultSelect['focused'] = false;
    this.setState({ defaultSelect });
  }
  updateCustomer = () =>{
    const {
            formData:{firstname,lastname,telephone,region,country,postCode,street,city},
            defaultShipping,
            defaultBilling
   } = this.state;
   const {
     user:{ token },
     user,
     storeCountries,
     isMobile
    } = this.props;

    let userData = JSON.parse(JSON.stringify(user));
    let regionData = {};
    if (this.validateFields()){
      if (storeCountries.countriesData.filter(item=>item.countryCode==country.value).length){
        let countryData = storeCountries.countriesData.filter(item=>item.countryCode==country.value)[0];
        if(countryData.regions.filter(item=>item.regionId==parseInt(region.value)).length){
          regionData = countryData.regions.filter(item=>item.regionId==parseInt(region.value))[0];
        }
      }
      userData.addresses.map(address=>{
        address.custom_attributes = [];
      });
      let address = {
        default_shipping: defaultShipping,
        default_billing: defaultBilling,
        firstname: firstname.value,
        lastname: lastname.value,
        telephone: telephone.value,
        region: _has(regionData, "regionId") ? {
          region: regionData.region,
          region_id: regionData.regionId,
          region_code: regionData.regionCode
        }
        :
        {
          region: region.value
        },
        custom_attributes: [],
        postcode: postCode.value,
        street: [street.value],
        city: city.value,
        country_id: country.value
      }
      userData.addresses.push(address);
      _unset(userData,'token');
      _unset(userData,'error');
      _unset(userData,'orders');
      _unset(userData,'wishlist_items');
      _unset(userData,'isSignedIn');

      let variables = {
        token,
        customer:userData
      };
      this.props.updateUser(this.context,variables);
      if (isMobile){
        this.router.redirect('myAccountMe');
      }else{
        this.props.openSideBar('my-account');
      }
    }
  }
  render(){
    const { formData,error,selectValues,defaultSelect} = this.state;
    const { isMobile } = this.props;
    return(
      <div className="add-address-form ez-form col-group">
          <form>
          {
            Object.keys(formData).map((inputKey,index)=>{
              const { field,
                      value,
                      type,
                      focused,
                      className='',
                      required }  = formData[inputKey];
              const inputError    = (error[inputKey]) ? error[inputKey] : '';

              return(
                <div key={index} className={`field ${className} ${(focused) ? 'focused':''}`}>
                  {
                    (type=='date') ?
                      <div className={`input ${field} ${(value.length) ? 'active':''}`}>
                        <DateInput
                          value={value}
                          name={field}
                          onChange={this.updateValue}
                        />
                      </div>
                    :
                    (type=='select' && selectValues[field] && selectValues[field].length) ?
                    <AutoSelectInput
                      className = {`input select ${field} ${(focused||value) ? 'active':''}`}
                      options = {selectValues[field]}
                      name={field}
                      onFocus= {this.onFocusSelect}
                      onBlur= {this.onBlurSelect}
                      onChange={this.handleSelect}
                      value = {value}
                    />
                    :
                    <TextInput
                      className={`input ${field} ${(value) ? 'active':''}`}
                      type={type}
                      name={field}
                      onChange={this.updateValue}
                      value={value}
                    />
                  }
                  <label className= {`label ${(required) ? "required":""}`} >
                    <span>{this.i18n.t(`user.myAccount.addresses.${field}Label`)}</span>
                  </label>
                  {
                    (inputError.length!==0) &&
                    <div className="ez-error-wrapper">
                      <span>{this.i18n.t(inputError)}</span>
                    </div>
                  }
                </div>
              )
            })
          }
          <div className="select-default-addresses col-12 col-mb-12">
            <AutoSelectInput
              className = {`input select ${defaultSelect['focused'] ? 'focused': ''}`}
              options = {selectValues['defaultAddress']}
              name={'defaultAddress'}
              onFocus= {this.onFocusDefaultAddressSelect}
              onBlur= {this.onBlurDefaultAddressSelect}
              onChange={this.handleDefaultAddressSelect}
              value = {defaultSelect['value']}
            />
          </div>
          </form>
          <div className="add-address-actions col-12">
            <button className="save-button" onClick={this.updateCustomer}>{this.i18n.t('user.myAccount.contactInfo.save')}</button>
            {
              isMobile &&
              <Link href='/my-account/me'><label className="cancel-button ez-secondary-button">{this.i18n.t('user.myAccount.contactInfo.cancel')}</label></Link>
            }
          </div>
      </div>
    );
  }
}
function mapStateToProps(state){
  return {
    isSignedIn:state.user.isSignedIn,
    user:state.user,
    storeConfiguration:state.storeConfig,
    storeCountries:state.storeCountries,
    isMobile: state.isMobile
  };
}

export default connect(mapStateToProps,{updateUser,openSideBar})(AddAddressForm);
