import EzComponent                from '@frontend/components/EzComponent';

import { connect }                from 'react-redux'
import Cookies                    from 'nookies';

import CartProductItem            from '@frontend/components/minicart/CartProductItem';
import CheckoutButton             from '@frontend/components/buttons/checkout-button/CheckoutButton';
import Coupon                     from '@frontend/components/coupons/Coupon';

import changeItemMutation         from '@frontend/services/mutations/changeCartItem.graphql'
import deleteItemMutation         from '@frontend/services/mutations/deleteCartItem.graphql'
import { setCart }                from '@frontend/actions';
import cookie                     from 'cookie';

import "@frontend/components/minicart/cart-totals/index.less";

class MiniCartTotals extends EzComponent{
  render(){
    const { cartData,
      storeConfiguration,
      }                          = this.props;

    const products 			                  = (cartData && cartData.items)? cartData.items: [];
		const showCancelCoupon 		            = (cartData && cartData.subtotal && cartData.coupon_code) ? true : false;
		const numProducts                     = products.length;
		const subtotal = !showCancelCoupon ? cartData.subtotal : cartData.subtotal_discount; 
		const freeShippingMin = (storeConfiguration.freeShippingMin) ? parseFloat(storeConfiguration.freeShippingMin) : 0;
		const shippingTax = (storeConfiguration.shippingTax) ? parseFloat(storeConfiguration.shippingTax) : 0;
		const shippingTaxTotal = (subtotal > freeShippingMin) ? this.i18n.t('miniCart.freeShipping') : `${shippingTax} ${storeConfiguration.currencySymbol}`;
		const total = (subtotal > freeShippingMin) ? parseFloat(subtotal).toFixed(2) : parseFloat(subtotal + shippingTax).toFixed(2);
	  	const discount = (parseFloat(cartData.subtotal) - parseFloat(cartData.subtotal_discount)).toFixed(2);
    return (
			<div className="minicart-totals-wrapper">
				<div className="ez-minicart-totals">
					<div className="coupon-container totals-section col-group">
						<Coupon showCancel={showCancelCoupon} couponCode={cartData.coupon_code}/>
					</div>
					{
						discount > 0 &&
						<div className="total totals-section discount col-group">
							<span className="label col-8 col-mb-8">{`${this.i18n.t('miniCart.discount')}`}</span>
							<span className="value  col-4 col-mb-4">{`${discount} ${storeConfiguration.currencySymbol}`}</span>
						</div>
					}
					<div className="total totals-section col-group">
							<span className="label col-8 col-mb-8">{`${this.i18n.t('miniCart.total')}`}</span>
						<span className="value  col-4 col-mb-4">{`${parseFloat(cartData.total).toFixed(2)} ${storeConfiguration.currencySymbol}`}</span>
					</div>
				</div>
			</div>
    );
  }
}
function mapStateToProps(state){
  return {
    cartData:           state.minicart.cart,
    user:               state.user,
    storeConfiguration: state.storeConfig
  }
}

export default connect(mapStateToProps, {setCart})(MiniCartTotals);
