import {SearchBar as StoreSearchBar}        from '@themes/store/frontend/components/searchbar/SearchBar';
import { connect }                          from 'react-redux';
import Link                                 from 'next/link';
import { setCookie,
  destroyCookie,
  parseCookies
}                                           from 'nookies';
import {
  getSearchData,
  setSearchValue,
  deleteSearchResults,
 }                                          from '@frontend/actions';
import ProductList                          from '@frontend/components/product-list/ProductList';
import SearchResult                         from '@frontend/pages/search-result';
import ChangeTheme                          from '@frontend/components/change-theme';

export class SearchBar extends StoreSearchBar{

  state = {
    showSearchBar: false,
    typingTimeout: 0
  }

  closeSearchBar = () => {
    this.handleSearcTermClick("");
    this.setState((state)=>({showSearchBar: false}))
  }

  toggleSearchBar = () =>{
    if (this.state.showSearchBar === false) {
      this.setState((state)=>({showSearchBar: !state.showSearchBar}))
    }
  }

  handleKeyDown= (e) =>{
    if (e.key === 'Enter') {
      e.preventDefault();
    }
  }

  handleChange = async (event) => {
    const { typingTimeout } = this.state;
    const {
      storeConfig,
      getSearchData,
      setSearchValue,
      deleteSearchResults,
    }                  = this.props;
    const searchValue  = event.target.value;

    const storeId      = (storeConfig && storeConfig.storeId) ? parseInt(storeConfig.storeId) : 0;
    this.props.setSearchValue(searchValue);

    if (typingTimeout) {
       clearTimeout(typingTimeout);
    }

    this.setState({
       forceHide: false,
       typingTimeout: setTimeout(async() => {
         const searchVariables = {
           searchText:       searchValue,
           actualPage:       SearchResult.defaultOptions.currentPage,
           pageSize:         SearchResult.defaultOptions.pageSize,
           selectedFilters:  SearchResult.defaultOptions.filters,
           storeId,
           customerGroup:    0
         };
         const postsVariables = {
           searchText: searchValue,
           actualPage: SearchResult.defaultOptions.currentPage,
           pageSize:   SearchResult.defaultOptions.pageSize,
           storeId
         };
         if(!searchValue){
           await deleteSearchResults();
         }else{
           this.updateRecentSearchs(searchVariables);
           await getSearchData(this.context,searchVariables);
         }

        }, 1000)
    });

  }


  updateRecentSearchs(searchVariables)
  {
    let cookies = parseCookies();
    // Check if cookies exists
    if (cookies.ezSearchTerms != undefined && cookies.ezSearchTerms != "") {
      let newCookies = cookies.ezSearchTerms.split(",");
      if (!newCookies.includes(searchVariables.searchText)) {
        newCookies.push(searchVariables.searchText);
        newCookies.join(",");
        setCookie({},'ezSearchTerms',newCookies,{
          maxAge: 60 * 60,
          path: '/',
        });
      }
    } else {
      setCookie({},'ezSearchTerms',searchVariables.searchText,{
        maxAge: 60 * 60,
        path: '/',
      });
    }
  }

  getTrends(trends)
  {
    let result = [];
    if(trends && trends.length){
      for (var i = 0; i < 3; i++) {
        let current = trends[i];
        result.push(
          <span key={current} onClick={() => {this.handleSearcTermClick(current)}} className="ez-rsc-result">
            {current}
          </span>
        )
      }
    }
    return result;
  }

  handleSearcTermClick(searchTerm){
    const { typingTimeout } = this.state;
    const {
      storeConfig,
      getSearchData,
      setSearchValue,
      deleteSearchResults,
    }                  = this.props;
    const storeId      = (storeConfig && storeConfig.storeId) ? parseInt(storeConfig.storeId) : 0;
    this.props.setSearchValue(searchTerm);
    if (typingTimeout) {
       clearTimeout(typingTimeout);
    }

    this.setState({
       typingTimeout: setTimeout(async() => {
         const searchVariables = {
           searchText:       searchTerm,
           actualPage:       SearchResult.defaultOptions.currentPage,
           pageSize:         SearchResult.defaultOptions.pageSize,
           selectedFilters:  SearchResult.defaultOptions.filters,
           storeId,
           customerGroup:    0
         };
         const postsVariables = {
           searchText: searchTerm,
           actualPage: SearchResult.defaultOptions.currentPage,
           pageSize:   SearchResult.defaultOptions.pageSize,
           storeId
         };
         if(!searchTerm){
           await deleteSearchResults();
         }else{
           this.updateRecentSearchs(searchVariables);
           await getSearchData(this.context,searchVariables);
         }

        }, 1000)
    });
  }

  getRecentSearchTerms()
  {
    let cookies = parseCookies();
    if (cookies.ezSearchTerms != undefined) {
      let result = [];
      for (var i = 0; i < 3; i++) {
        let current = cookies.ezSearchTerms.split(",").reverse()[i];
        if (current != undefined) {
          result.push(
            <span key={current} onClick={() => {this.handleSearcTermClick(current)}} className="ez-rsc-result">
              {current}
            </span>
          )
        }
      }
      return result;
    }
  }

  getSearchFooter()
  {
    let trends = this.props.storeConfig.trends;
    if (trends != null) {
      trends = trends.split(",")
    }
    return(
      <div className="ez-empty-search-container">
        <div className="ez-recent-search-container">
          <span className="ez-rsc-title">{this.i18n.t('searchBar.recent')}</span>
          <ul>
            {this.getRecentSearchTerms()}
          </ul>
        </div>
        <div className="ez-popular-search-container">
          <span className="ez-rsc-title">{this.i18n.t('searchBar.popular')}</span>
          <ul>
            {this.getTrends(trends)}
          </ul>
        </div>
        <div className="ez-stores">
          <span className="ez-spot-icon"></span>
          <Link {...this.router.get('custom',{slug:'storelocator'})}>
            <a className="ez-store-title">{this.i18n.t('searchBar.findYourStore')}</a>
          </Link>
        </div>
        <div className="ez-search-footer">
          <ul>
            <li className="ez-sf-item">{this.i18n.t('searchBar.conditions')}</li>
            <li className="ez-sf-item">{this.i18n.t('searchBar.club')}</li>
            <li className="ez-sf-item">{this.i18n.t('searchBar.legal')}</li>
            <li className="ez-sf-item">{this.i18n.t('searchBar.job')}</li>
            <li className="ez-sf-item">{this.i18n.t('searchBar.warning')}</li>
            <li className="ez-sf-item">{this.i18n.t('searchBar.faq')}</li>
            <li className="ez-sf-item">{this.i18n.t('searchBar.stores')}</li>
          </ul>
        </div>
        {
          this.props.isMobile &&
          <span className="ez-show-mobile-menu">

          </span>
        }
      </div>
    );
  }

  hitSearchResult()
  {
    this.handleSearcTermClick("");
    this.setState((state)=>({showSearchBar: false}))
  }

  render(){
    const {
      colData,
      search,
      isMobile,
      showSearchInput
    }                           = this.props;
    const { searchValue='' }    = search;
    let   { showSearchBar }     = this.state;
    const productsQty           = search.items          ? search.items.length          : 0;
    let   showResults           = showSearchBar ? "active" : "";
    return(
      <div className={`ez-search-bar-wrapper ${colData} ${showResults}`}>
        <div className="ez-search-bar-show">
          {
            !isMobile && showSearchInput &&
            <div className="close-button close-search-icon" onClick={this.closeSearchBar}></div>
          }
          <div className={`ez-search-input ${showSearchInput ? '' : 'hidden'}`}>
              <input
                type="text"
                // value={searchValue}
                // onChange={this.handleChange}
                // onKeyDown={this.handleKeyDown}
                // onClick={this.toggleSearchBar.bind(this)}
                // autoFocus={isMobile}
                placeholder={this.i18n.t('searchBar.search')}
              />
            <span className="submit-text">{this.i18n.t('searchBar.search')}</span>
          </div>
        </div>
        {
          productsQty == 0 &&
          this.getSearchFooter()
        }
        {
          showSearchBar &&
          <div className={`ez-search-bar`}>
            <ChangeTheme active="black"/>
            {
              isMobile &&
              <div className="close-button close-search-icon" onClick={this.closeSearchBar}></div>
            }
            <div className="search-results col-group">
              <div className="ez-search-result-products col-12">
                <p className="product-result-title">{this.i18n.t('searchBar.productResult',[productsQty,searchValue])}</p>
                <ProductList
                  products={search.items}
                  showRating={true}
                  loading={false}
                  hitSearchResult={this.hitSearchResult.bind(this)}
                  hasMoreProducts={false}
                  />
                </div>
            </div>
          </div>
        }
      </div>
    );
  }
}
function mapStateToProps({storeConfig,search,isMobile}){
  return {storeConfig,search,isMobile};
}
export default connect(mapStateToProps,{getSearchData,setSearchValue,deleteSearchResults})(SearchBar);
