import EzComponent                           from '@frontend/components/EzComponent';
import { connect }                           from 'react-redux';
import {setActiveTheme,
        setMenuBackground}                   from '@frontend/actions/theme';
import VisibilitySensor                      from 'react-visibility-sensor';

import '@frontend/components/change-theme/index.less';

class ChangeTheme extends EzComponent{
  state ={
    lastValue: false
  }
  componentDidMount(){
    this.props.setActiveTheme(this.props.active)
    if (this.props.background) {
      this.props.setMenuBackground(this.props.background)
    }
  }
  onChange (isVisible) {
    if (isVisible) {
      this.props.setActiveTheme(this.props.active)
      if (this.props.background) {
        this.props.setMenuBackground(this.props.background)
      }
    }
  }
  render(){
    return(
      <VisibilitySensor onChange={this.onChange.bind(this)}>
        <span className="visibility-check"></span>
      </VisibilitySensor>
    )
  }
}

function mapStateToProps({storeConfig,theme}){
  return {
    isMobile: storeConfig.isMobile,
    theme
  }
}
export default connect(mapStateToProps,{setActiveTheme,setMenuBackground})(ChangeTheme);
