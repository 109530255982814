import EzComponent              from '@frontend/components/EzComponent';
import ForgottenPasswordForm  from '@frontend/components/forgotten-password/form';

import '@frontend/components/side-bar/sections/forgotten-password/index.less';

class SideBarForgottenPassword extends EzComponent{
	render() {
		return (	
			<div className="forgotten-password-section">
                <div className="section-title col-offset-3 col-6 col-mb-12">
                    <span>{this.i18n.t('user.forgottenPassword.pageTitle')}</span>
                </div>
                <div className="col-offset-3 col-6 col-mb-12">
                    <p className="forgotten-password-description">{this.i18n.t('user.forgottenPassword.description')}</p>
                </div>
                <ForgottenPasswordForm className="col-offset-3 col-6 col-mb-12"/>
        </div>
		);
	}
  
}
export default SideBarForgottenPassword;
  