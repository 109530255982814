import EzFormComponent          from '@frontend/components/EzFormComponent';

import _has                 from 'lodash/has'
import _unset               from 'lodash/unset'
import moment               from 'moment';
import { connect }          from 'react-redux'

import Link                 from 'next/link';
import dynamic              from 'next/dynamic';

const TextInput            = dynamic(()=> import('@frontend/components/basic-components/form/TextInput'));
const AutoSelectInput      = dynamic(()=> import('@frontend/components/basic-components/form/auto-select-input/AutoSelectInput'));
const DateInput            = dynamic(()=> import('@frontend/components/basic-components/form/date-input/DateInput'));

import { updateUser }       from '@frontend/actions';
import { isFulfilled } from 'q';

export class EditCustomerForm extends EzFormComponent{
  state = {
    user: null,
    formData: {
      firstname:        { field:'firstname',       type:'text',     value:'', validation:'lettersWithSpaces,notEmpty',        required:true },
      lastname:         { field:'lastname',        type:'text',     value:'', validation:'lettersWithSpaces,notEmpty',        required:true },
      dob:              { field:'dob',            type:'date',     value:'', validation:'',                                  required:false },
      gender:           { field:'gender',          type:'select',   value:'', validation:'',                                  required:false,   focused:false },
      taxvat:           { field:'taxvat',             type:'text',     value:'', validation:'notEmpty',                          required:true }
    },
    selectValues:{
      gender: [{value:1,label:this.i18n.t('genders.male')},{value:2,label:this.i18n.t('genders.female')}]
    },
    error:[]
  }

  updateCustomer = async () =>{
    const { user:{ token },user } = this.props;
    const {formData} = this.state;
    let userData = user;
    userData['addresses'].map(address=>{
      address.custom_attributes=[];
    });
    Object.keys(formData).map(key=>{
      if((formData[key]['value'].length || parseInt(formData[key]['value'])) && formData[key]['value']!=userData[key]){
        if (userData.hasOwnProperty(key)){
          if(key=='dob'){
            userData[key] = moment(formData[key]['value'],'DD/MM/YYYY').format('YYYY/MM/DD');
          }else{
            userData[key] = formData[key]['value'];
          }
        }else if(userData['custom_attributes']&&userData['custom_attributes'].filter(item=>item.attribute_code==key)){
          let newCustomAttributes = [];
          userData['custom_attributes'].map((attr=>{
            if(attr.attribute_code==key &&
              formData[attr['attribute_code']] &&
              formData[attr['attribute_code']]['value']!=attr.value){
                newCustomAttributes.push({attribute_code:key,value:formData[attr['attribute_code']]['value']});
            }else{
              newCustomAttributes.push(attr);
            }
          }));
          userData['custom_attributes']= newCustomAttributes;
        }
      }
    });
    _unset(userData,'token');
    _unset(userData,'error');
    _unset(userData,'orders');
    _unset(userData,'wishlist_items');
    _unset(userData,'isSignedIn');
    let variables = {
      token,
      customer:userData
    };
    console.log(variables);
    await this.props.updateUser(this.context,variables);
    this.router.redirect('myAccountMe');

  }
  onFocusSelect= (name)=>{
    let { formData } = this.state;
    formData[name]['focused'] = true;
    this.setState({ formData });
  }
  onBlurSelect= (name)=>{
    let { formData } = this.state;
    formData[name]['focused'] = false;
    this.setState({ formData });
  }
  componentDidMount () {
    const { user } = this.props;
    let { formData } = this.state;
    if (user && user.id){
      Object.keys(formData).map(key=>{
        if(user[key]){
          formData[key]['value'] = user[key];
        }else if(user['custom_attributes']&&user['custom_attributes'].filter(item=>item.attribute_code==key).length){
          formData[key]['value'] = user['custom_attributes'].filter(item=>item.attribute_code==key)[0]['value'];
        }
      });
      this.setState({formData});
    }
  }
  componentDidUpdate () {
    const { user } = this.props;
    let { formData } = this.state;
    let hasChanges = false;
    Object.keys(formData).map(key=>{
      if(user[key] && !formData[key]['value'].length && formData[key]['value']!=user[key]){
        formData[key]['value'] = user[key];
        hasChanges = true;
      }else if(user['custom_attributes']&&user['custom_attributes'].filter(item=>item.attribute_code==key).length){
        if (!formData[key]['value'].length &&
            formData[key]['value'] != user['custom_attributes'].filter(item=>item.attribute_code==key)[0]['value'] &&
            user['custom_attributes'].filter(item=>item.attribute_code==key)[0]['value'] != ' '
          ){
          formData[key]['value'] = user['custom_attributes'].filter(item=>item.attribute_code==key)[0]['value'];
          hasChanges = true;
        }
      }
    });
    if (hasChanges){
      this.setState({formData});
    }
  }
  render(){
    const { formData,error,selectValues } = this.state;
    const { user} = this.props;
    return(
        <div className="edit-customer-form ez-form">
            <form>
            {
              Object.keys(formData).map((inputKey,index)=>{
                let   { field,
                        value,
                        type,
                        focused,
                        required }  = formData[inputKey];
                const inputError    = (error[inputKey]) ? error[inputKey] : '';
                return(
                  <div key={index} className={`field ${type} ${(focused) ? 'focused':''}`}>
                    {
                      (type=='date') ?
                        <DateInput
                          value={(value.length) ? value : moment().format('DD/MM/YYYY') }
                          name={field}
                          onChange={this.updateValue}
                          onFocus= {this.onFocusSelect}
                          onBlur= {this.onBlurSelect}
                        />
                      :
                      (type=='select' && selectValues[field] && selectValues[field].length) ?
                      <AutoSelectInput
                        className = {`input ${field} ${(focused||value) ? 'active':''}`}
                        options = {selectValues[field]}
                        name={field}
                        onFocus= {this.onFocusSelect}
                        onBlur= {this.onBlurSelect}
                        onChange={this.updateValue}
                        value = {value}
                      />
                      :
                      <TextInput
                        className={`input ${field} ${(value) ? 'active':''}`}
                        type={type}
                        name={field}
                        onChange={this.updateValue}
                        value={value}
                      />
                    }
                    <label className= {`label ${(required) ? "required":""}`} >
                      <span>{this.i18n.t(`user.myAccount.contactInfo.${field}Label`)}</span>
                    </label>
                    {
                      (inputError.length!==0) &&
                      <div className="ez-error-wrapper">
                        <span>{this.i18n.t(inputError)}</span>
                      </div>
                    }
                  </div>
                )
              })
            }
            </form>
            <div className="edit-customer-actions">
              <button className="save-button" onClick={this.updateCustomer}>{this.i18n.t('user.myAccount.contactInfo.save')}</button>
              <Link {...this.router.get('myAccountMe')}><a className="cancel-button ez-secondary-button">{this.i18n.t('user.myAccount.contactInfo.cancel')}</a></Link>
            </div>
        </div>
    );
  }
}
function mapStateToProps({user}){
  return {user};
}

export default connect(mapStateToProps,{updateUser})(EditCustomerForm);
