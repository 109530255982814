import EzComponent              from '@frontend/components/EzComponent';
import { connect }              from 'react-redux';

import SignInSection						from '@frontend/components/side-bar/sections/sign-in';
import CreateAccountSection 		from '@frontend/components/side-bar/sections/create-account';
import MyAccountSection				  from '@frontend/components/side-bar/sections/my-account';
import EditCustomerSection			from '@frontend/components/side-bar/sections/edit-customer';
import AddAddressSection			from '@frontend/components/side-bar/sections/add-address';
import EditAddressSection			from '@frontend/components/side-bar/sections/edit-address';
import AddressBookSection			from '@frontend/components/side-bar/sections/address-book';
import MyOrdersSection				from '@frontend/components/side-bar/sections/my-orders';
import NewAccountSection			from '@frontend/components/side-bar/sections/new-account';
import ForgottenPasswordSection		from '@frontend/components/side-bar/sections/forgotten-password';
import SideBarActions             from '@frontend/components/side-bar/actions';
import '@frontend/components/side-bar/index.less';

class SideBar extends EzComponent{
	render() {
		const { sideBar:{show, component,props},isMobile } = this.props;
		if(show && !isMobile){
			return (
				<div className={`side-bar ${show ? 'open' : ''} ${component ? component:''}`}>
					<div className="side-bar-content">
						<SideBarActions />
						{
							component=='signin' &&
							<SignInSection />
						}
						{
							component=='create-account' &&
							<CreateAccountSection />
						}
						{
							component=='my-account' &&
							<MyAccountSection />
						}
						{
							component=='edit-customer' &&
							<EditCustomerSection />
						}
						{
							component=='edit-address' &&
							<EditAddressSection id={props.id}/>
						}
						{
							component=='add-address' &&
							<AddAddressSection/>
						}
						{
							component=='address-book' &&
							<AddressBookSection/>
						}
						{
							component=='my-orders' &&
							<MyOrdersSection/>
						}
						{
							component=='new-account' &&
							<NewAccountSection/>
						}
						{
							component=='forgotten-password' &&
							<ForgottenPasswordSection/>
						}
					</div>
				</div>
			);
		}else{
			return null;
		}
	}
  
}
function mapStateToProps({sideBar,isMobile}){
	return {
		sideBar,
		isMobile
	}
}
export default connect(mapStateToProps)(SideBar);
  