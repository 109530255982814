import React                            from 'react'

import { connect }                      from 'react-redux';
import memoize                          from "memoize-one";
import EzPublicPage                     from "@frontend/components/EzPublicPage";

import { getSearchData,setSearchValue } from '@frontend/actions';

import ProductList                      from '@frontend/components/product-list/ProductList';
import Base                             from "@frontend/layout/base";

import '@frontend/less/layout/search-result-view.less';

export class SearchResult extends EzPublicPage {
  state = {
    filters:              [],
    currentPage:          1,
    pageSize:             20,
    canLoadMoreProducts:  true
  }

  // TODO: This method might be moved to base theme class
  static getMetadata(){
    return {
      title: "searchResult.tagTitle",
      meta_title: "searchResult.metaTitle",
      meta_description: "",
      meta_keywords: ""
    }
  };

  // for default options
  static defaultOptions = {
    filters:     [],
    currentPage: 1,
    pageSize:    20
  }

  static async getInitialProps (context) {
    let baseProps     = await super.getInitialProps(context);

    const { store,query:{ q:searchValue },req={} } = context;
    let {storeConfig} = req;

    if(!storeConfig){
      storeConfig = store.getState().storeConfig || null;
    }

    const storeId     = (storeConfig && storeConfig.storeId) ? parseInt(storeConfig.storeId) : 0;
    let variables     = {
      searchText:       searchValue,
      actualPage:       SearchResult.defaultOptions.currentPage,
      pageSize:         SearchResult.defaultOptions.pageSize,
      selectedFilters:  SearchResult.defaultOptions.filters,
      storeId,
      customerGroup:    0,
      isSearchResultPage: true
    };

    await store.dispatch(getSearchData(context,variables));
    await store.dispatch(setSearchValue(searchValue));

    return {...baseProps,searchValue};
  }

  loadMore = async () => {
    const { filters,canLoadMoreProducts }                           = this.state;
    const { searchValue, searchResultsPageData,storeConfiguration } = this.props;
    if(canLoadMoreProducts){
      this.setState({canLoadMoreProducts:false});
      let variables = {
        searchText:       searchValue,
        actualPage:       searchResultsPageData.actualPage+1,
        pageSize:         SearchResult.defaultOptions.pageSize,
        selectedFilters:  filters,
        storeId:          (storeConfiguration.storeId) ? parseInt(storeConfiguration.storeId) : 0,
        isSearchResultPage: true
      };

      await this.props.getSearchData(this.context,variables,searchResultsPageData);

      this.setState({canLoadMoreProducts:true});
    }
  }

  componentDidUpdate (prevProps, prevState) {
    if(this.props.user && this.props.user.id){
      if(!(prevProps.user.group_id && this.props.user.group_id != 0)
          || this.props.user.group_id !== prevProps.user.group_id  ){

        const { pageSize,currentPage,filters }   = this.state;
        const { searchValue,storeConfiguration } = this.props;

        let variables = {
          searchText:       searchValue,
          actualPage:       currentPage,
          pageSize,
          selectedFilters:  filters,
          storeId:          (storeConfiguration.storeId) ? parseInt(storeConfiguration.storeId) : 0,
          customerGroup:    this.props.user.group_id
        };

        this.props.getSearchData(this.context,variables);
      }
    }
  }

  render () {
    const { searchResultsPageData,wishlistItems,searchValue } = this.props;
    const { canLoadMoreProducts }                             = this.state;
    const qty                                                 = (searchResultsPageData && searchResultsPageData.items) ? searchResultsPageData.items.length : 0;
    const hasMoreProducts                                     = canLoadMoreProducts && searchResultsPageData.hasMoreProducts;

    return (
      <Base search={searchValue}>
        <div className="ez-page search-result-page" id="search-result-page">
        {
          (qty!=0) ?
          (
            <>
            <div className="search-result-title">
              <span className="search-result-title-qty">
                {(qty == 1) ? this.i18n.t('searchResult.oneResult',[qty]) : this.i18n.t('searchResult.multipleResults',[qty])}
              </span>
              <span className="search-result-title-value">{` "${searchValue}"`}</span>
            </div>
            <div className="ez-search-result-content row col-group">
              <div className="ez-search-result-products col-12">
              <ProductList
                products={searchResultsPageData.items}
                showRating={true}
                loading={false}
                hasMoreProducts={hasMoreProducts}
                loadMore={this.loadMore}
                />
              </div>
            </div>
            </>
          ):
            <span className="empty-list">{this.i18n.t('searchResult.notResult',[searchValue])}</span>
        }
        </div>
      </Base>
    )
  }
}
function mapStateToProps ({user,storeConfig,searchResultsPageData}){
  return {
    user:               user.user,
    wishlistItems:      user.wishlist_items,
    wishlistItemsCount: user.wishlistItemsCount,
    storeConfiguration: storeConfig,
    searchResultsPageData
  }
}

export default connect(mapStateToProps,{getSearchData,setSearchValue})(SearchResult);
