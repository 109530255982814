import { CartProductItem as BaseCartProductItem } from '@themes/store/frontend/components/minicart/CartProductItem';
import { connect }                                from 'react-redux'
import { deleteItemFromCart,
          changeCartItem }                        from '@frontend/actions';
import ProductPrice                               from '@frontend/components/product/price/ProductPrice';

class CartProductItem extends BaseCartProductItem{
  state = {
    newQty: false
  }
  addQty = (addend) => () => {
    let { data:{qty} } = this.props;
    const newQty      = (qty + addend) > 0 ? (qty + addend) : 1 ;
    this.updateAttribute(newQty,addend)
  }


  updateAttribute = (newQty, addend) => {
    let { data: { qty },storeConfiguration, cartData, user } = this.props;
    let item = this.props.data;
    let oldQty = item.qty;
    item.qty = newQty;
    let variables = {
      itemId:      item.item_id,
      sku:         item.sku,
      name:        item.name,
      qty:         parseInt(item.qty),
      oldQty:      parseInt(oldQty),
      newQty:      parseInt(newQty),
      final_price: item.final_price,
      storeId: (storeConfiguration.storeId) ? parseInt(storeConfiguration.storeId) : 0,
      storeCode: (storeConfiguration.storeCode) ? storeConfiguration.storeCode : "default",
      quoteId: (cartData.quote_id) ? cartData.quote_id : '',
      customerToken: (user.token) ? user.token : '',
      customerId: (user.id) ? user.id : null
    }
    if (variables&&variables.sku&&variables.qty) {
      this.props.changeCartItem(this.context, variables);
    }
  }

  manualInputChange(qty,data){
    const newQty = parseInt(qty);
    this.updateAttribute(newQty);
    
  }

  render(){
    const { data:{ currency,name,child_info,qty,short_description, error },data } = this.props;
    const minicartImage                              = this.getMiniCartImage();
    const productOptions                             = child_info.options;
    const { price,finalPrice,discount }              = this.getProductPrice();
    let quantity                                     = this.state.newQty == false ? qty : this.state.newQty;
    return (
      <div className="product-item-container col-group">
        <div className="minicart-item-actions">
          <span className="delete-item" onClick={this.onItemDelete}></span>
        </div>
        <div className="product-image-wrapper col-mb-3">
          <img src={minicartImage} alt={name} />
        </div>
        <div className="product-item-details col-mb-9">
          <div className="product-item-name">
            {
              name &&
              name.toLowerCase()
            }
          </div>
          <div className="product-item-short-description">
            <span></span>
          </div>
          <ProductPrice className="col-mb-12" regular_price={price} final_price={finalPrice}/>
          {
            (productOptions && productOptions.length) &&
              <div className="product-item-options">
                {productOptions.map((option,index) =>{
                  return(
                    <div className="product-option" key={index}>
                      <span className="option-label">{option.attribute_label ? option.attribute_label:option.attribute_default_label}</span>
                      <span className="option-value"> {option.option_value}</span>
                    </div>
                  )
                })}
              </div>
          }
          <div className="product-item-pricing col-group">
            <div className="details-qty col-mb-12">
              <span className="remove-qty" onClick={this.addQty(-1)}></span>
              <input className="qty-number" value={quantity} min="1" onChange={(e) => this.manualInputChange(e.target.value,data)}/>
              <span className="add-qty" onClick={this.addQty(1)}></span>
            </div>
          </div>
          {error &&
            <div className="cart-item-error">
                <span className="message error" >{error}</span>
            </div>
          }
        </div>
      </div>
    );
  }
}

function mapStateToProps(state){
  return {
    cartData:           state.minicart.cart,
    user:               state.user,
    storeConfiguration: state.storeConfig
  }
}

export default connect(mapStateToProps,{deleteItemFromCart,changeCartItem})(CartProductItem);
