import EzComponent    from '@frontend/components/EzComponent';
import { connect }    from 'react-redux';
import ProductSlider  from '@frontend/components/product-slider/ProductSlider';

import '@frontend/components/minicart/cross-sell-products/index.less';

class CrossSellProducts extends EzComponent{

  render(){
    const {products,wishlistItems,className} = this.props;
    const productsLength = (products) ? products.length : 0;
    let   slides         = this.props.storeConfiguration.isMobile ? 2 : 4;
    return (productsLength!=0) ?
      (
        <div className={`cross-sell-products-container ${className}`}>
          <div className="cross-sell-products-inner">
            <h3 className="cross-sell-products-title">
              {
                this.props.storeConfiguration.isMobile &&
                this.i18n.t('crossSellProducts.titleMobile')
              }
              {
                !this.props.storeConfiguration.isMobile &&
                this.i18n.t('crossSellProducts.title')
              }
            </h3>
            <ProductSlider
              className="cross-sell-products-container"
              products={products}
              wishlistItems={wishlistItems}
              infinite={true}
              isPlaying={false}
              visibleSlides={slides}
            />
          </div>
        </div>
    ) : null;
  }
}


function mapStateToProps ({user,storeConfig}){
  return {
    storeConfiguration: storeConfig
  }
}

export default connect(mapStateToProps,{})(CrossSellProducts);
