import EzComponent              from '@frontend/components/EzComponent';
import SigninBox          			from '@frontend/components/signin/SigninBox'
import CreateAccountLink  			from '@frontend/components/signin/CreateAccountLink'

import '@frontend/components/side-bar/sections/sign-in/index.less';

class SideBarSignIn extends EzComponent{
	render() {
		return (	
			<div className="sign-in">
				<SigninBox />
				<CreateAccountLink />
			</div>
		);
	}
  
}
export default SideBarSignIn;
  