export const openSideBar = (component,backTo=[],props={}) => {
    return {
      type: "OPEN_SIDE_BAR",
      component,
      backTo,
      props
    }
  }
  
  export const closeSideBar = () => {
    return {
      type: "CLOSE_SIDE_BAR"
    }
  }