import EzComponent          from '@frontend/components/EzComponent';
import Link                 from 'next/link'

export class MegaMenuItem extends EzComponent {
  state = {
    childrenActive:false
  }

  toggleChildrenActive = ()=>{
    this.setState((state)=>({childrenActive:!state.childrenActive}));
  }

  getLinkData(type,url,category= null){
    const hrefArray = {
      'category' : 'category',
      'page'     : 'cmsPage'
    };

    const linkType = hrefArray[type] || '';
    url            = url             || '';
    const id       = linkType === 'category' ? category : url;
    const linkData = this.router.get(linkType,{id,slug:url});

    return linkData;
  }

  render(){
    const { childrenActive } = this.state;
    const {level,data:{item_type,label,category=null,url,children=[],category_id,url_path}} = this.props;
    const childrenData = (item_type=='category' && children.length) ? children :null;

    const linkData = this.getLinkData(item_type,url,category_id);

    return (
      <>
      {
        (url_path) &&
        <Link  {...linkData}><a className="item-name">{label}</a></Link>
      }
      {
        (!url_path&&item_type=='category') &&
        <span className="item-name">{label}</span>
      }
      {
        (childrenData && level<=2) &&
        <>
        <span className={`mobile-show-content ${(childrenActive)? "active":""}`} onClick={this.toggleChildrenActive}></span>
        <ul className={`item-category-content level-${level} ${(childrenActive)? "active":""}`}>
        {
          childrenData.map((child,index)=>{
            return (
              <li className={`megamenu-item level-${level}`} key={index}>
                <MegaMenuItem data={child} level={level+1} />
              </li>
            )
          })
        }
        </ul>
        </>
      }
      </>
    );
  }
}

export default MegaMenuItem;
