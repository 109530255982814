import EzComponent from '@frontend/components/EzComponent';
import { connect } from 'react-redux';

import '@frontend/components/safe-buy/index.less';

class SafeBuy extends EzComponent {

    render() {

        return (
            <div className="safe-buy-wrapper">
                <div className="bottom-lovely">
                    <span className="safe-buy">{this.i18n.t('safeBuy.label')}</span>
                    <ul className="columns col-group">
                        <li className="column col-mb-12 col-4">
                            <span className="icon payment"></span>
                            <span className="safe-buy-title">{this.i18n.t('safeBuy.paymentMethodsTitle')}</span>
                            <p className="safe-buy-content">
                                {this.i18n.t('safeBuy.paymentMethods1')} <br/>
                                {this.i18n.t('safeBuy.paymentMethods2')}
                            </p>
                        </li>
                        <li className="column col-mb-12 col-4">
                            <span className="icon shipping"></span>
                            <span className="safe-buy-title">{this.i18n.t('safeBuy.freeShippingTitle')}</span>
                            <p className="safe-buy-content">
                                {this.i18n.t('safeBuy.freeShipping1')} <br/>
                                {this.i18n.t('safeBuy.freeShipping2')} <br/>
                                {this.i18n.t('safeBuy.freeShipping4')}
                            </p>
                        </li>
                        <li className="column col-mb-12 col-4">
                            <span className="icon delivery"></span>
                            <span className="safe-buy-title">{this.i18n.t('safeBuy.deliveryTimeTitle')}</span>
                            <p className="safe-buy-content">
                                {this.i18n.t('safeBuy.deliveryTime1')} <br/>
                                {this.i18n.t('safeBuy.deliveryTime2')} <br/>
                                {this.i18n.t('safeBuy.deliveryTime3')}
                            </p>
                        </li>
                    </ul>
                </div>
            </div>
        )
    }

}
function mapStateToProps({ isMobile }) {
    return {
        isMobile,
    }
}
export default connect(mapStateToProps)(SafeBuy);
