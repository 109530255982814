import EzComponent          from '@frontend/components/EzComponent';

import { Table }            from 'react-bootstrap'
import Link                 from 'next/link';
import { connect }          from 'react-redux'

import { MAX_ORDERS_PAGE,
          getUserOrders }   from '@frontend/actions';
import Pagination           from '@frontend/components/pagination/Pagination';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'

import { addItemToCart }    from '@frontend/actions';
import '@frontend/components/my-account/orders/user-orders.less';

class UserOrders extends EzComponent{
  state = {
    userOrders: [],
    storeConfiguration:{},
    actualPage: 0,
    npages: 1,
  }



  addOrderItemsToCart= (order) => ()=>{
    const { items } = order;
    items.map((item)=>{
      let { qty,product_options,product_type } = item;
      const { cartData, user,storeConfiguration } = this.props;
      let product_option = {};
      let options = JSON.parse(product_options);
      let selected_options = options['attributes_info'];
      if (product_type == 'configurable'){
        let configurable_item_options=[];
        selected_options.map((option)=>{
          const configurable_option = {
            option_id:    parseInt(option.option_id),
            option_value: parseInt(option.option_value)
          }
          configurable_item_options.push(configurable_option)
        });
        product_option['extension_attributes']={
          configurable_item_options
        }
      }
      let variables = {
        sku: item.sku,
        qty: qty,
        storeId:(storeConfiguration.storeCode) ? parseInt(storeConfiguration.storeId) : 0,
        storeCode:(storeConfiguration.storeCode) ? storeConfiguration.storeCode : 'default',
        quoteId: (cartData.quote_id) ? cartData.quote_id: '',
        customerToken: (user.token) ? user.token : '',
        customerId: (user.id) ? user.id : 0,
        product_option
      }
      this.props.addItemToCart({client:this.context.client},variables);
    })
  }    
  render(){
    if (this.props.orders){
      const { actualPage } = this.state;
      const { orders } = this.props;
      return (
        <div className="user-orders-container">
          {
            (!orders || orders.length===0) ?
            <div className="not-user-orders"><span>{this.i18n.t('user.myAccount.orders.notOrders')}</span></div>
            :
            <div className="user-orders">
              {
                orders.map((order,idx)=>{
                  const { order_id,date,items=[]} = order;
                  dayjs.extend(utc)
                  dayjs.extend(timezone)
                  dayjs.tz.guess()
                  dayjs.tz.setDefault("Europe/Madrid")
                  const dateArray = date.split("/");
                  let dateStr = `${dateArray[0]} de ${this.i18n.t(`months.month${parseInt(dateArray[1])-1}`)} de ${dateArray[2]}`;
                  return (
                    <div key={`user-order-${order_id}`} className="order-container">
                      <div className="order-date">{dateStr} - {this.i18n.t('user.myAccount.orders.state')}: {this.i18n.t('user.myAccount.orders.' + order.status)}</div>
                      <div className="order-items">
                        {
                          items.map((item,index)=>{
                            let {sku,product_type,product_options='',name,qty} = item;
                            let configurable_name='';
                            if (product_type=='configurable' && product_options.length){
                              let options = JSON.parse(product_options);
                              configurable_name = name.toLowerCase();
                              configurable_name = configurable_name.charAt(0).toUpperCase() + configurable_name.slice(1);
                              name = options.simple_name;
                            }
                            return (
                              <div key={`order-item-${sku}`} className={`order-item ${(index==0) ? 'first' :''}`}>
                                <div className="order-item-field field name">
                                  <span className="name">{name}</span>
                                </div>
                                {
                                  configurable_name && configurable_name.length &&
                                  <div className="order-item-field field configurable-name">
                                    <span className="name">{configurable_name}</span>
                                  </div>
                                }
                                <div className="order-item-qty">
                                  {qty}
                                </div>
                              </div>
                            )
                          }
                          )
                        }
                        <div className="repeat-order-action">
                          <button className="repeat-button" onClick={this.addOrderItemsToCart(order)}>{this.i18n.t('user.myAccount.orders.repeat')}</button>
                        </div>
                        {
                          (order.status === "complete" || order.status === "complete_delivered") &&
                          <div className="return-order-action">
                            <a href={`/returns?code=${order.protect_code}`}> 
                              <button className="return-button" >{this.i18n.t('user.myAccount.orders.return')}</button>
                            </a>
                            
                          </div>
                        }
                      </div>
                    </div>
                  )
                })
              }
            </div>
          }
        </div>

      )
    }else{
      return null;
    }
  }
}
function mapStateToProps(state){
  return {
    userOrders: state.order.userOrders,
    storeConfiguration:state.storeConfig,
    user: state.user,
    cartData: state.minicart.cart
  }
}
export default connect(mapStateToProps,{getUserOrders, addItemToCart})(UserOrders);
