import { DefaultAddresses as
          StoreDefaultAddresses }   from '@themes/store/frontend/components/my-account/addresses/DefaultAddresses.jsx';
import EzComponent                  from '@frontend/components/EzComponent';
import Link                         from 'next/link'
import { connect }                  from 'react-redux';
import { openSideBar }              from '@frontend/actions/side-bar';

import Address                      from '@frontend/components/my-account/addresses/Address'

import '@frontend/components/my-account/addresses/default-address.less';

class DefaultAddresses extends StoreDefaultAddresses{
  openSideBarAddressBook = ()=>{
    if (this.props.openSideBar){
      this.props.openSideBar('address-book',['my-account']);
    }
  }
  openSideBarAddAddress = ()=>{
    let { sideBar:{backTo} } = this.props;
    if (this.props.openSideBar){
      backTo.push('address-book');
      this.props.openSideBar('add-address',backTo);
    }
  }
  render(){
    const { addresses,isMobile,defaultShippingTitle=null,defaultBillingTitle=null,goToAddressBook=false } = this.props;
    if (!addresses){
      return null;
    }
    const defaults = this.searchDefaultAddresses(addresses);
    return (
      <div className="default-addresses col-group">
        <div className="default-billing-address default-address">
          <div className="section-header col-group">
            {
              defaultBillingTitle ? 
                <span className="section-title col-11 col-mb-10">{defaultBillingTitle}</span>
              :
                <span className="section-title col-11 col-mb-10">{this.i18n.t('user.myAccount.billingAddresses')}</span>
            }
          </div>
          {
            (defaults.defaultBilling) ?
            <Address address={defaults.defaultBilling} goToAddressBook={goToAddressBook}/>
            :
            <div>
            <span className="no-default">{this.i18n.t('user.myAccount.addresses.notDefaultBilling')}</span>
            <div className="address-actions">
            {
              isMobile ?
              <>
              {
                goToAddressBook ? 
                  <Link {...this.router.get('myAccountMyAddresses')}><a className="section-action-button">{this.i18n.t('user.myAccount.addAddress')}</a></Link>
                  :
                  <Link {...this.router.get('myAccountAddAddress')}><a className="section-action-button">{this.i18n.t('user.myAccount.addAddress')}</a></Link>
              }
              </>
              :
              <>
              {
                goToAddressBook ?
                <span className="section-action-button" onClick={this.openSideBarAddressBook}>{this.i18n.t('user.myAccount.addAddress')}</span>
                :
                <span className="section-action-button" onClick={this.openSideBarAddAddress}>{this.i18n.t('user.myAccount.addAddress')}</span>
              }
              </>
            }
            </div>

          </div>
          }
        </div>
        <div className="default-shipping-address default-address">
          <div className="section-header col-group">
            {
              defaultShippingTitle ? 
                <span className="section-title col-11 col-mb-10">{defaultShippingTitle}</span>
              :
                <span className="section-title col-11 col-mb-10">{this.i18n.t('user.myAccount.shippingAddresses')}</span>
            }
          </div>
          {
            (defaults.defaultShipping) ?
            <Address address={defaults.defaultShipping} goToAddressBook={goToAddressBook}/>
            :
            <div>
            <span className="no-default">{this.i18n.t('user.myAccount.addresses.notDefaultShipping')}</span>
            <div className="address-actions">
            {
              isMobile ?
              <>
              {
                goToAddressBook ? 
                  <Link {...this.router.get('myAccountMyAddresses')}><a className="section-action-button">{this.i18n.t('user.myAccount.addAddress')}</a></Link>
                  :
                  <Link {...this.router.get('myAccountAddAddress')}><a className="section-action-button">{this.i18n.t('user.myAccount.addAddress')}</a></Link>
              }
              </>
              :
              <>
              {
                goToAddressBook ?
                <span className="section-action-button" onClick={this.openSideBarAddressBook}>{this.i18n.t('user.myAccount.addAddress')}</span>
                :
                <span className="section-action-button" onClick={this.openSideBarAddAddress}>{this.i18n.t('user.myAccount.addAddress')}</span>
              }
              </>
            }
            </div>
          </div>
          }
        </div>
      </div>
    );


  }

}
function mapStateToProps({isMobile,sideBar}){
  return { isMobile,sideBar };
}
export default connect(mapStateToProps,{ openSideBar })(DefaultAddresses);
