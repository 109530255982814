import EzComponent                      from '@frontend/components/EzComponent';
import { connect }                      from 'react-redux';
import { registerStockNotification }    from '@frontend/actions/stock-notification';
import ReducedModal                     from '@frontend/components/basic-components/reduced-modal';

import '@frontend/components/stock-notification/modal/index.less';

class StockNotificationModal extends EzComponent {

    state = {
        email: "",
        identifier: false,
        submitting: false,
        submitted: false,
        result: false,
        message: false,
        showSubmit: false,
        mobile: {
            showButton: true
        }
    }

    handleChange(e) {
        let testEmail = this.testEmail(e.currentTarget.value);
        this.setState({
            email: e.currentTarget.value,
            showSubmit: testEmail
        })
    }

    testEmail(email) {

        let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        if (email.includes("+")) {
            return false;
        }

        if (re.test(email)) {
            return true;
        }
        else {
            return false;
        }
    }

    registerStock = async () => {
        if (!this.state.email) {
            return;
        }
        const checkEmail = this.testEmail(this.state.email);
        if (!checkEmail) {
            this.setState({
                submitted: true,
                message: this.i18n.t('stock.invalidEmail')
            })
            return;
        }

        const { product } = this.props;
        if (!product) {
            return;
        }
        let variables = {
            email: this.state.email,
            identifier: product.product_id.toString()
        };

        let stockResponse = await this.props.registerStockNotification(this.context.client, variables);
        const dataResponse = stockResponse.data.stock_notification_response;
        this.setState({
            submitted: true,
            result: dataResponse.ok,
            message: dataResponse.message,
            mobile: { showButton: true }
        })
    }

    closeMobileModal = () => {
        this.setState({
            mobile: {
                showButton: true
            }
        })
    }

    closeModal = () => {
        this.setState({ submitted: false })
    }

    enableMobileForm = () => {
        this.setState({
            mobile: {
                showButton: false
            }
        })
    }

  

    render() {
        return (
            <div className="stock-notification-wrapper">
                {
                    this.state.mobile.showButton &&
                    <span onClick={this.enableMobileForm.bind(this)} className="enable-mobile-form">
                        {this.i18n.t('stock.sendProductList')}
                    </span>
                }
                {
                    !this.state.mobile.showButton &&
                    <ReducedModal className="mobile-form" closeModal={this.closeMobileModal}>
                        <span className="mobile-main-stock-title">
                            {this.i18n.t('stock.outOfStockProduct')}
                        </span>
                        <span className="mobile-sub-stock-title">
                            {this.i18n.t('stock.mobileMessage')}
                        </span>
                        <input value={this.state.email} onChange={(e) => { this.handleChange(e) }} type="email" placeholder={this.i18n.t('stock.mobilePlaceholder')} />
                        {
                            this.state.showSubmit &&
                            <span onClick={this.registerStock.bind(this)} className="mobile-submit-stock-notification">
                                {this.i18n.t('stock.mobileSendWarn')}
                            </span>
                        }
                    </ReducedModal>
                }
                {
                    this.state.submitted &&
                    <ReducedModal className="form-submission-result" closeModal={this.closeModal}>
                        <span>
                            {this.state.message}
                        </span>
                    </ReducedModal>
                }
            </div>
        )
    }

}
function mapStateToProps({ isMobile }) {
    return {
        isMobile
    }
}
export default connect(mapStateToProps, { registerStockNotification })(StockNotificationModal);


