import EzComponent    from '@frontend/components/EzComponent';
import Link           from 'next/link';
import {withRouter}   from 'next/router';
import '@frontend/components/header/wishlist-link/wishlist-link.less';


class AccountMenu extends EzComponent{
  render(){
    const { colData } = this.props;
    const inWishlist = (this.props.router.pathname == '/my-account/wishlist');

    return (
      <div className="ez-wishlist-link ez-nav-span">
        <Link {...this.router.get('myAccountWishlist')}><span className={`link-icon${(inWishlist) ? ' in-wishlist' : ''}`}></span></Link>
      </div>
    );
  }
}

export default withRouter(AccountMenu);
