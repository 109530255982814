import EzComponent        from '@frontend/components/EzComponent';
import MegaMenu           from '@frontend/components/megamenus/MegaMenu';

export class Navigation extends EzComponent {
    render() {
    return (
        <MegaMenu />
    );
  }
}



export default Navigation;
