import EzComponent from '@frontend/components/EzComponent';
import Textarea    from 'react-textarea-autosize';

class TextInput extends EzComponent {
  state = {
    inputValue : ''
  };
  handleInputChange = ({ target }) => {
    const value                  = target.value;
    const { index,opIndex,name,charLimit=-1 } = this.props;
    if ((charLimit != -1) && (value.length > charLimit)) {
        return;
    }
    let inputData = {
      value,
      name,
      index,
      opIndex,
      remainingChars: (charLimit != -1) ? (charLimit - value.length) : -1
    };
    this.setState ({inputValue:value});
    this.props.onChange(inputData);
  }
  onClick = (ev) => {
    if (this.props.onClick) {
      this.props.onClick(ev);
    }
  }
  onFocus = (text) => {
    const { name, onFocus: onFocusProp } = this.props;
    if (onFocusProp) {
      onFocusProp(name);
    }
  }
  onBlur = (text) => {
    const { name, onBlur: onBlurProp } = this.props;
    if (onBlurProp) {
      onBlurProp(name);
    }
  }
  static getDerivedStateFromProps (props, state){
    const { value } = props;
    const { inputValue } = state;
    if (value != inputValue) {
      return { inputValue: value }
    }
    return {};
  }
  render(){
    const { id, value, inputKey, type, className='', placeholder='', maxLines=5, minLines=1, readonly=false } = this.props;
    let { inputValue } = this.state;
    if (!inputValue.length && value.length) {
      inputValue = value;
    }
    if (type === 'text' || type === 'password') {
      return (
          <input
            id={id}
            className={className}
            type={type}
            name={inputKey}
            onChange={this.handleInputChange}
            value={inputValue}
            placeholder={placeholder}
            onClick={this.onClick}
            onFocus={this.onFocus}
            onBlur={this.onBlur}
            readOnly={readonly}
          />
      )
    } else if (type === 'textarea') {
        return (
          <Textarea
              className={className}
              name={inputKey}
              onChange={this.handleInputChange}
              value={value}
              placeholder={placeholder}
              maxRows={maxLines}
              minRows={minLines}
              onFocus={this.onFocusSelect}
              onBlur={this.onBlurSelect}
            />
        )
    } else {
        return null;
    }
  }
}

export default TextInput;
