import React              from 'react';
import EzComponent        from '@frontend/components/EzComponent';
import ProductListItem    from '@frontend/components/product-list/product-item/ProductListItem';
import LoadMoreProducts   from '@frontend/components/product-list/LoadMoreProducts';
import Video              from '@frontend/components/basic-components/video';
import _get               from 'lodash/get';
import _map               from 'lodash/map';
import _set               from 'lodash/set';
import _includes          from 'lodash/includes';
import settings           from '@frontend/settings/components/product-list';
import {
  FRAGRANCE_CATEGORY_ID,
  SUN_PROTECTION_CATEGORY_ID,
  BLACK_FRIDAY_CATEGORY_ID,
  XMAS_PRESENTS_CATEGORY_ID,
  HERITAGE_CATEGORY_ID,
  HOMECOMING_CATEGORY_ID,
  CAPILAR_CATEGORY_ID,
  GIFT_IDEAS_CATEGORY_ID
} from '@frontend/constants';


import '@frontend/components/product-list/product-list.less';

const PATTERN = ["S", "S", "B", "S", "S", "S", "S", "S", "S", "B", "B"];

export class ProductList extends EzComponent {
  state = {
    showScrollTop: false
  }
  lastScrollY = false;
  list = React.createRef();
  boxRef = React.createRef();
  getScrollOffset = () => {
    const list = this.list;
    let offset = 0;

    if (list.current) {
      const height = list.current.offsetHeight;

      offset = document.documentElement.scrollHeight - height;
    }

    return offset;
  }

  handleScroll = () => {
    this.setState({ showScrollTop: (window.scrollY > window.innerHeight) })
  };

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll, true);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  customScroll() {
    this.boxRef.current.scrollIntoView();
  }

  renderProductListItem({product=null, position, className="", pattern="S", label=""}){
    const {
      category,
      wishlistButtonEnable = true,
      showRating = false,
      onClickWishlist } = this.props;

    if (!product) return;

    _set(product,'label',label); 

    return (
      <ProductListItem
        className={`${className}`}
        key={_get(product,'product_id')}
        product={product}
        pattern={_includes(className,"featured-product") ? "B" : pattern}
        position={position}
        hitSearchResult={this.props.hitSearchResult ? this.props.hitSearchResult : false}
        wishlistButtonEnable={wishlistButtonEnable}
        showRating={(showRating && _get(product,'rating_summary') != null)}
        category={category}
        onClickWishlist={onClickWishlist}
      />
    );
  }
  
  renderImageListItem(position, className, content = null, href){
    const Tag             = !!href ? "a" : "li";
    const additionalProps = !!href ? {href} : {};
    return (
      <Tag key={`image-${position}`} className={`ez-product-item arborea ${className}`} {...additionalProps}>
        { content }
      </Tag>
    );
  }

  renderTextList(position, className, title, info){
    return (
      <li key={`text-${position}`} className={`ez-product-item arborea text ${className}`}>
        <h2 className="item-title">{title}</h2>
        <p className="item-info">{info}</p>
      </li>
    );
  }

  /**
   * Print catalog category view in category sun protection
   * @returns 
   */
  chunkSunProtectionProducts(){
    const { products, isMobile } = this.props;
    const sunProtectionArray = _get(settings,`sunProtectionCategoryPattern.${isMobile ? 'mobile' : 'desktop'}`)
    
    return this.customCategoriesGrid(sunProtectionArray);
  }

  /**
   * Return block with homecoming video
   * @returns 
   */
  renderHomecomingImageContent(urlMedia){
    return(
      <div className="product-container">
        <Video src={urlMedia} className="media banner-video absolute-positioned" autoplay="autoplay" loop="loop" muted="muted" playsinline=""></Video>
      </div>
    )
  }

  /**
   * Print catalog category view in category homecoming
   * @returns 
   */
  chunkHomecomingProducts(){
    const { products, isMobile } = this.props;
    const homecomingArray = _get(settings,`homecomingCategoryPattern.${isMobile ? 'mobile' : 'desktop'}`)
    
    return this.customCategoriesGrid(homecomingArray);
  }


  /**
   * Return block with fragance info
   * @returns 
   */
   renderFraganceImageContent() {
    return(
      <div className="banner-text-container absolute-positioned">
        <h2 className="title">{this.i18n.t('categoryPage.rawEssenceTitle')}</h2>
        <h3 className="subtitle">{this.i18n.t('categoryPage.rawEssenceSubtitle')}</h3>
      </div>
    )
  }

  /**
   * Print catalog category view in category fragance
   * @returns 
   */
  chunkFragranceProducts(){
    const { products, isMobile } = this.props;
    const fraganceArray = _get(settings,`fraganceCategoryPattern.${isMobile ? 'mobile' : 'desktop'}`)

    return this.customCategoriesGrid(fraganceArray, this.renderFraganceImageContent());
  }

  /**
   * Return block with capilar info
   * @returns 
   */
  renderCapilarImageContent() {
    return(
      <div className="image-content-block">
        <p className="image-content-title">{this.i18n.t('categoryPage.capilarTitle')}</p>
        <p className="image-content-subtitle"><span className="daily">{this.i18n.t('categoryPage.capilarSubtitleFirstPart')}</span> {this.i18n.t('categoryPage.capilarSubtitleSecondPart')}</p>
        <p className="image-content-text">{this.i18n.t('categoryPage.capilarText')}</p>
      </div>
    )
  }

  /**
   * Print catalog category view in category capilar
   * @returns 
   */
  chunkCapilarProducts(){
    const { products, isMobile } = this.props;
    const capilarArray = _get(settings,`capilarCategoryPattern.${isMobile ? 'mobile' : 'desktop'}`)
    
    return this.customCategoriesGrid(capilarArray, this.renderCapilarImageContent());
  }

  /**
   * Return block with gift ideas video
   * @returns 
   */
   renderGiftImageContent(urlMedia){
    if(!urlMedia) return '';
    return(
      <div className="product-container">
        <Video src={urlMedia} className="media banner-video absolute-positioned" autoplay="autoplay" loop="loop" muted="muted" playsinline=""></Video>
      </div>
    )
  }

  /**
   * Print catalog category view in category capilar
   * @returns 
   */
   chunkGiftIdeasProducts(){
    const { isMobile }   = this.props;
    const giftIdeasArray = _get(settings,`giftIdeasCategoryPattern.${isMobile ? 'mobile' : 'desktop'}`)
    const urlMedia       = _get(settings,`videosUrls.giftIdeasVideos.${!isMobile ? 'urlMediaDesktop': 'urlMediaMobile'}`)
    
    return this.customCategoriesGrid(giftIdeasArray, this.renderGiftImageContent(urlMedia));
  }

  /**
   * Print custom category grid with images and products defined in settings by category
   * @param {array} categoryItemsArray 
   * @returns array finalProductArray
   */
  customCategoriesGrid(categoryItemsArray, bannerContent, additionalContent=""){
    let productIndex = 0;

    const finalProductArray = _map(categoryItemsArray,(categoryItem,index) => {
      const {type,className='',content,additional, title, info, href} = categoryItem;
      let position = index+1;
      
      if(!type || type ==='product'){
        const product = _get(this.props.products,productIndex)
        productIndex++;

        return this.renderProductListItem({product, position, className: _get(categoryItem,"className") })
      }else if(type ==='image'){
        return this.renderImageListItem(position, className, content && !additional ? bannerContent : content && additional ? additionalContent : '',href);
      }else{
        return this.renderTextList(position, className, title, info);
      };
    })

    return finalProductArray;
  }

  chunkProducts() {
    const {products, category} = this.props;
    let finalProductArray = [];
    if(category && category.category_id == HOMECOMING_CATEGORY_ID){
      return this.chunkHomecomingProducts();
    }
    if(category && category.category_id == FRAGRANCE_CATEGORY_ID){
      return this.chunkFragranceProducts();
    }
    if(category && category.category_id == CAPILAR_CATEGORY_ID){
      return this.chunkCapilarProducts();
    }
    if(category && category.category_id == SUN_PROTECTION_CATEGORY_ID){
      return this.chunkSunProtectionProducts();
    }
    const { isCrossSellCategory = false, isSimpleCategory = false } = this.props;
    const product_collection_length = products.length;
    const pattern_repetition = Math.ceil(product_collection_length / PATTERN.length)
    let finalPattern = [];
    for (var i = 0; i < pattern_repetition; i++) {
      finalPattern = finalPattern.concat(PATTERN);
    }

    if(isCrossSellCategory){
      finalPattern = Array(_get(products,'length',0)).fill('S');
    }

    for (var j = 0; j < finalPattern.length; j++) {
      let product = products[j];

      if (product != undefined) {
        const isSmallProduct = finalPattern[j] == "S" || isCrossSellCategory || isSimpleCategory;
        const pattern = isSmallProduct ? "S" : "B"; 
        finalProductArray.push(this.renderProductListItem({product, position: i+1, pattern, label: isSmallProduct ? "" : "top"}));
      }
    }

    return finalProductArray;
  }

  render() {
    const { products, wishlistButtonEnable = true, showRating = false, loading, hasMoreProducts, loadMore, category, itemClassName = "" } = this.props;
    
    return (
      <>
        {
          this.state.showScrollTop &&
          <span onClick={this.customScroll.bind(this)} className="scroll-top-category"></span>

        }
        {   this.state.showScrollTop &&
          <div className="category-bg-menu"></div>
        }
        <div ref={this.boxRef}></div>
        <ol className="ez-product-list row col-group" ref={this.list}>
          {
            products &&
            this.chunkProducts()
          }
        </ol>
        
        {
          hasMoreProducts &&
          <LoadMoreProducts loadMore={loadMore} loading={loading} offset={this.getScrollOffset()} />
        }
      </>
    );
  }
}

export default ProductList;
