import React,{ Component }    from 'react';
import EzComponent            from '@frontend/components/EzComponent';

import Address                from '@frontend/components/my-account/addresses/Address'

import '@frontend/components/my-account/addresses/default-address.less';

export class DefaultAddresses extends EzComponent {
  searchDefaultAddresses = (addresses) => {
    let defaults = {
      defaultShipping:null,
      defaultBilling:null
    };
    addresses.map(address=>{
      if (address.default_shipping){
        defaults.defaultShipping = address;
      }
      if (address.default_billing){
        defaults.defaultBilling = address;
      }
    });
    return defaults;
  }

  render(){
    const { addresses } = this.props;
    if (!addresses){
      return null;
    }
    const defaults = this.searchDefaultAddresses(addresses);
    return (
      <div className="default-addresses col-group">
        <div className="default-billing-address col-6">
          <div className="sub-section-title"><span>{this.i18n.t('user.myAccount.addresses.defaultBilling')}</span></div>
          {
            (defaults.defaultBilling) ?
            <Address address={defaults.defaultBilling} />
            :
            <span className="no-default">{this.i18n.t('user.myAccount.addresses.notDefaultBilling')}</span>
          }
        </div>
        <div className="default-shipping-address col-6">
          <div className="sub-section-title"><span>{this.i18n.t('user.myAccount.addresses.defaultShipping')}</span></div>
          {
            (defaults.defaultShipping) ?
            <Address address={defaults.defaultShipping} />
            :
            <span className="no-default">{this.i18n.t('user.myAccount.addresses.notDefaultShipping')}</span>
          }
        </div>
      </div>
    );


  }

}

export default DefaultAddresses;
