import EzComponent    from '@frontend/components/EzComponent';
import WishlistPromo  from '@frontend/components/minicart/wishlist';
import HtmlParser     from '@frontend/components/HtmlParser';
import _get           from 'lodash/get';
import { connect }    from 'react-redux';

import "@frontend/components/minicart/cart-header/index.less";

class MiniCartHeader extends EzComponent {
  render() {
    const { user }  = this.props;
    const banner    = _get(this.props, "minicart.banner");

    return (
      <div className="minicart-header">
        <p className="minicart-title"><span>{this.i18n.t('miniCart.cart')}</span></p>
        <div className="minicart-banner">
          {
            _get(banner, "is_active") &&
            HtmlParser.staticParseComponents(_get(banner,"content"))
          }
          {
            user && user.id &&
            <p className="minicart-user-welcome"><span>{this.i18n.t('miniCart.userWelcome', [user.firstname])}</span></p>
          }
          <p className="minicart-description"><span>{this.i18n.t('miniCart.description')}</span></p>
        </div>
      </div>
    );
  }
}
function mapStateToProps({user, minicart}) {
  return {
    user,
    minicart
  }
}

export default connect(mapStateToProps)(MiniCartHeader);
