import EzComponent          from '@frontend/components/EzComponent';
import Link                 from 'next/link'
import { connect }          from 'react-redux';
import { openSideBar }			from '@frontend/actions/side-bar';
import DefaultAddresses     from '@frontend/components/my-account/addresses/DefaultAddresses';
import OtherAddresses       from '@frontend/components/my-account/addresses/OtherAddresses';

import '@frontend/components/side-bar/sections/address-book/index.less';

class SideBarAddressBook extends EzComponent{
	openAddAddress = ()=>{
		let { sideBar:{backTo} } = this.props;
		backTo.push('address-book');
		this.props.openSideBar('add-address',backTo);
	}
	render() {
		const { user } = this.props;
		return (	
			<div className="address-book-content">
				<div className="section-header col-group">
					<span className="section-title col-11 col-mb-10">{this.i18n.t('user.myAccount.addressInfo')}</span>
				</div>
				<DefaultAddresses  
					addresses={user.addresses} 
					defaultBillingTitle={this.i18n.t('user.myAccount.addresses.defaultBillingTitle')} 
					defaultShippingTitle={this.i18n.t('user.myAccount.addresses.defaultShippingTitle')} 
				/>
				<div className="section-header additional col-group">
					<span className="section-title">{this.i18n.t('user.myAccount.aditionalEntries')}</span>
				</div>
				<OtherAddresses  addresses={user.addresses} />
				<span className="section-action-button" onClick={this.openAddAddress}>{this.i18n.t('user.myAccount.addAddress')}</span>
			</div>
		);
	}
  
}
function mapStateToProps ({user,sideBar}){
	return { user,sideBar };
}
export default connect(mapStateToProps,{openSideBar})(SideBarAddressBook);
  