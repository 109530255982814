import EzComponent            from '@frontend/components/EzComponent';
import PropTypes              from 'prop-types';

import '@frontend/components/pagination/pagination.less';

class Pagination extends EzComponent{

  static propTypes = {
    additionalCurrentPage:     PropTypes.number, // Add X to current page
    data:                      PropTypes.object  // Object with actual page and total pages
  }

  static defaultProps = {
    additionalCurrentPage: 1
  }

  getPaginationArray(){
    const { npages,page }   = this.props.data;
    const middlePagesCount  = [-2,-1,0,1,2]
    const firstPage         = 1;
    const lastPage          = (npages < 1) ? firstPage : parseInt(npages);
    let paginationArray     = [];
    let currentPage         = ((page < firstPage) || (page > lastPage))? firstPage : parseInt(page) + this.props.additionalCurrentPage;
    let middlePages         = [];
    middlePages = middlePagesCount
      .map((val) => currentPage + val)
      .filter((val) => lastPage >= val && val >= 1);

    let { 0:firstValue, length:l,[l-1]: lastValue } = middlePages;

    if(firstValue > 1){
      paginationArray = [1];
    }

    if(firstValue > 2){
      paginationArray = [...paginationArray,'...'];
    }

    paginationArray = [...paginationArray,...middlePages];

    if(lastValue < (lastPage - 1)){
      paginationArray = [...paginationArray,'...',lastPage];

    }else if(lastValue === (lastPage - 1)){
      paginationArray = [...paginationArray,lastPage];
    }

    return {
      paginationArray,
      currentPage,
      lastPage
    };
  }

  goToPage(page){
    const backendPage = parseInt(page) - 1;
    this.props.onChange(backendPage);
  }

  render(){
    const { currentPage, paginationArray,lastPage } = this.getPaginationArray();
    return(
      <div className="pagination-container">
        {
          (currentPage > 1) &&
            <span className="pag-icon previous-page" onClick={this.goToPage.bind(this,currentPage - 1)}></span>
        }
        {
          paginationArray.map((pagItem,i) => {
            return(
              <span
                key={i}
                className={(pagItem === '...' ? 'dots': 'page-item') + (pagItem === currentPage ? ' selected':'')}
                onClick={(pagItem !== currentPage) ? this.goToPage.bind(this,pagItem):undefined}
              >
                {pagItem}
              </span>
            )
          })
        }
        {
          (currentPage < lastPage) &&
            <span className="pag-icon next-page" onClick={this.goToPage.bind(this,currentPage + 1)}></span>
        }
      </div>
    )
  }
}

export default Pagination;
