import React, { Component }         from 'react';

import '@frontend/components/modal/modal.less';

class Modal extends Component{
  state = {
    show : false
  }
  static getDerivedStateFromProps(nextProps, prevState) {
    if(nextProps.show!=prevState.show){
      return {show:nextProps.show};
    }
    return null;
  }

  closeModal=()=>{
    this.setState({show:false});
    document.body.style.overflow = 'unset';

  }
  onHide = () => {
    const { onHide } = this.props;
    this.closeModal();
    onHide();
  }
  cancelAction = () =>{
    const { cancelAction } = this.props;
    this.closeModal();
    cancelAction();
  }
  acceptAction = () =>{
    const { acceptAction } = this.props;
    this.closeModal();
    acceptAction();
  }
  componentDidMount(){
    document.body.style.overflow = 'hidden';
  }
  render(){
    const {
      className,
      titleText,
      cancelButtonText,
      acceptButtonText,
      cancelAction,
      acceptAction,
      showCloseIcon
    }                         = this.props;
    const { show }            = this.state;
    const allowCloseIconClass = showCloseIcon ? 'with-close-icon' : '';

    return(
      <div className={`ez-modal ${className} ${allowCloseIconClass} ${(!show) ? 'hidden':''}`}>
        <div className="modal-wrapper">
          <div className="modal-header">
              <div className="modal-title"><span>{titleText}</span></div>
              {
                showCloseIcon &&
                  <span className="icon-close" onClick={this.onHide}></span>
              }
          </div>

          <div className="modal-body">
            {this.props.children}
          </div>
          <div className="modal-footer">
            {
              acceptButtonText &&
                <button className="modal-button accept-button" onClick={this.acceptAction}>
                  {acceptButtonText}
                </button>
            }
            {
              cancelButtonText &&
                <button className="modal-button cancel-button" onClick={this.cancelAction}>
                  {cancelButtonText}
                </button>
            }
          </div>
        </div>
        <div className="modal-overlay" onClick={this.onHide}></div>
      </div>
    );
  }
}

export default Modal;
