import {AddAddressForm }   from '@frontend/components/my-account/addresses/AddAddressForm';

import _has                     from 'lodash/has'
import _unset                   from 'lodash/unset'
import { connect }              from 'react-redux'
import {updateUser}             from '@frontend/actions'
import { openSideBar }          from '@frontend/actions/side-bar';

export class EditAddressForm extends AddAddressForm{
  
  getAddressData = ()=>{
    let { formData,defaultShipping,defaultBilling,defaultSelect,selectValues } = this.state;
    const { user,id } = this.props;
    if(id && user && user.id){
      let address = null;
      if (user.addresses){
        user.addresses.map(userAddress=>{
          if(userAddress.id == id){
            address = userAddress;
          }
        });
      }
      if (address){
        Object.keys(formData).map(inputKey=>{
          if(inputKey=='region'){
            if(address[inputKey]['region_id']){
              formData[inputKey]['value'] = (address[inputKey]) ? address[inputKey]['region_id'] : '';
            }else{
              formData[inputKey]['value']       = address[inputKey]['region']
              formData[inputKey]['type']        = "text";
              formData[inputKey]['required']    = false;
            }
          }else if(inputKey=='street'){
            formData[inputKey]['value'] = (address[inputKey].length) ? address[inputKey][0] : '';
          }else if(inputKey=='country'){
            formData[inputKey]['value'] = (address['country_id']) ? address['country_id'] : '';
            selectValues["actualRegions"] = formData[inputKey]['value'];
          }else{
            formData[inputKey]['value'] = (address[inputKey]) ? address[inputKey] : '';
          }
        });
        defaultShipping = (address.default_shipping == true);
        defaultBilling = (address.default_billing == true);
        if (!defaultShipping && !defaultBilling ){
          defaultSelect['value'] = 0;
        }else if (defaultShipping && !defaultBilling ) {
          defaultSelect['value'] = 1;
        }else if (!defaultShipping && defaultBilling ) {
          defaultSelect['value'] = 2;
        }else if (defaultShipping && defaultBilling ) {
          defaultSelect['value'] = 3;
        }
      }
      this.setState({address,formData,defaultShipping,defaultBilling,defaultSelect,selectValues});
    }
  }

  componentDidMount () {
    this.getAddressData();
    this.getCountriesData();
  }

}
function mapStateToProps(state){
  return {
    isSignedIn:state.user.isSignedIn,
    user:state.user,
    storeConfiguration:state.storeConfig,
    storeCountries:state.storeCountries,
    isMobile: state.isMobile
  };
}

export default connect(mapStateToProps,{updateUser,openSideBar})(EditAddressForm);
