import EzComponent                from '@frontend/components/EzComponent';
import { connect }                from 'react-redux'
import { deleteItemFromCart,
          changeCartItem }        from '@frontend/actions';
import ProductPrice               from '@frontend/components/product/price/ProductPrice';

export class CartProductItem extends EzComponent{

  onItemDelete = ()=>{
    const item = this.props.data;
    const { storeConfiguration,cartData,user } = this.props;
    let variables = {
      itemId:         item.item_id,
      sku:            item.sku,
      final_price:    item.final_price,
      name:           item.name,
      qty:            item.qty,
      storeId:        (storeConfiguration.storeId) ? parseInt(storeConfiguration.storeId) : 0,
      storeCode:      (storeConfiguration.storeCode) ? storeConfiguration.storeCode : "default",
      quoteId:        (cartData.quote_id) ? cartData.quote_id: '',
      customerToken:  (user.token) ? user.token : '',
      customerId:     (user.id) ? user.id : null,
      customerGroup:  (user.id) ? user.group_id : 0,
      enableCrossSellProducts: storeConfiguration. enableMinicartCrossSell
    };
    this.props.deleteItemFromCart(this.context,variables);
  }
  onQtyChange = (event)=>{
    const { storeConfiguration,cartData,user } = this.props;
    let item = this.props.data;
    let newQty = event.target.value;
    let oldQty = item.qty;
    if(event.target.value){
      item.qty = newQty;
      let variables = {
        itemId: item.item_id,
        sku:    item.sku,
        name:   item.name,
        qty:    parseInt(item.qty),
        oldQty: parseInt(oldQty),
        newQty: parseInt(newQty),
        final_price: item.final_price,
        storeId:(storeConfiguration.storeId) ? parseInt(storeConfiguration.storeId) : 0,
        storeCode:(storeConfiguration.storeCode) ? storeConfiguration.storeCode : "default",
        quoteId: (cartData.quote_id) ? cartData.quote_id: '',
        customerToken: (user.token) ? user.token : '',
        customerId:     (user.id) ? user.id : null,
        customerGroup:  (user.id) ? user.group_id : 0,
        enableCrossSellProducts: storeConfiguration. enableMinicartCrossSell
      }
      this.props.changeCartItem({client:this.context.client},variables);
    }
  }
  getMiniCartImage = () =>{
    const { galleryTypes }                                 = this.props.storeConfiguration;
    const { data:{ media_gallery,child_info,product_id } } =  this.props;

    if(media_gallery.length){
      const gallery        = JSON.parse(media_gallery);
      let productGallery   = '';

      if(child_info.options && child_info.options.length){
        const childId = child_info.product_id;

        productGallery = gallery.childGallery ? JSON.parse(gallery.childGallery[childId])[galleryTypes['mini_cart']]: '';

      }else{
        productGallery = gallery.productGallery ? JSON.parse(gallery.productGallery)[galleryTypes['mini_cart']] : '';
      }

      return (productGallery) ? productGallery[0] : '';
    }
  }

  getProductPrice = () => {
    const {
      data:{
        child_info,
        price:parentPrice,
        final_price:finalPrice
      }
    }                 = this.props;
    const hasChildren = !!child_info.options;
    const price       = hasChildren ? child_info.price : parentPrice;
    const discount = Math.ceil(100 - (finalPrice*100/price));

    return {price,finalPrice,discount};
  }
  render(){
    const { data }                      = this.props;
    const minicartImage                 = this.getMiniCartImage();
    const productOptions                = data.child_info.options;
    const { price,finalPrice,discount } = this.getProductPrice();
    let qty = data.qty;
    return (
      <div className="product-item-container col-group">
        <div className="product-image-wrapper col-4">
          <img src={minicartImage} alt={data.name} />
        </div>
        <div className="product-item-details col-8">
          <strong className="product-item-name">{data.name}</strong>
          {
            (productOptions && productOptions.length) &&
              <div className="product-item-options">
                {productOptions.map(option =>{
                  return(
                    <div className="product-option">
                      <span className="option-label">{option.attribute_label ? option.attribute_label:option.attribute_default_label}</span>
                      <span className="option-value"> {option.option_value}</span>
                    </div>
                  )
                })}
              </div>
          }

          <div className="product-item-pricing">
            <ProductPrice regular_price={price} final_price={finalPrice}/>
            <div className="details-qty">
              <label className="qty-label">{this.i18n.t('miniCart.qty')}</label>
              <input className="number" value={qty} onChange={this.onQtyChange} min="1" />
            </div>
            <div className="minicart-item-actions">
              <button className="delete-item" onClick={this.onItemDelete}>
                <span>{this.i18n.t('miniCart.itemDelete')}</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
function mapStateToProps(state){
  return {
    cartData:           state.minicart.cart,
    user:               state.user,
    storeConfiguration: state.storeConfig
  }
}
export default connect(mapStateToProps,{deleteItemFromCart,changeCartItem})(CartProductItem);
