import { Address as 
          StoreAddress }    from '@themes/store/frontend/components/my-account/addresses/Address';
import { connect }          from 'react-redux';
import Link                 from 'next/link';
import _unset               from 'lodash/unset';
import Cookies              from 'nookies';
import cookie               from 'cookie';

import { setUser,
          updateUser }      from '@frontend/actions';
import updateCustomerData   from '@frontend/services/mutations/updateCustomerData.graphql'

import { openSideBar }      from '@frontend/actions/side-bar';

import '@frontend/components/my-account/addresses/address.less'

class Address extends StoreAddress {
  state = {
    storeCountries:{},
    user:{},
    storeConfiguration:{}
  }
  openSideBar = (id) => () => {
    let { sideBar:{backTo}} = this.props;
    if (this.props.openSideBar){
      backTo.push('address-book');
      this.props.openSideBar('edit-address',backTo,{id});
    }
  }
  openSideBarAddressBook = ()=>{
    if (this.props.openSideBar){
      this.props.openSideBar('address-book',['my-account']);
    }
  }
  render(){
    const { className,address,showActions=true,isMobile,goToAddressBook=false } = this.props;
    const region = (typeof(address.region)=='string') ? address.region : address.region.region;
    const street = (typeof(address.street)=='string') ? address.street : this.getCompleteStreet(address.street);
    const country = this.getCountryName(address.country_id);
    return (
      <div className={`address-wrapper ${className}`}>
        <div className="customer-name address-field">
          <span className="label">{this.i18n.t('user.myAccount.addresses.firstnameLabel')}:</span>
          <span className="value">{`${address.firstname} ${address.lastname}`}</span>
        </div>
        <div className="city-info address-field">
          <span className="label">{this.i18n.t('user.myAccount.addresses.streetLabel')}:</span>
          <span className="value">{`${street}, ${address.postcode}, ${address.city}, ${region}`}</span>
        </div>
        <div className="telephone address-field">
          <span className="label">{this.i18n.t('user.myAccount.addresses.telephoneLabel')}:</span>
          <span className="value">{address.telephone}</span>
        </div>
        {
          isMobile ?
          <div className="address-actions">
            {
              goToAddressBook ? 
                <Link {...this.router.get('myAccountMyAddresses')}><span className="edit-address-button">{this.i18n.t('user.myAccount.addresses.edit')}</span></Link>
              :
                <Link {...this.router.get('myAccountEditAddress',{ id: address.id })}><span className="edit-address-button">{this.i18n.t('user.myAccount.addresses.edit')}</span></Link>
            }
            <span onClick={this.deleteAddress} className="delete-address-button">{this.i18n.t('user.myAccount.addresses.delete')}</span>
          </div>
          :
          <div className="address-actions">
            {
              goToAddressBook ? 
                <span className="edit-address-button" onClick={this.openSideBarAddressBook}>{this.i18n.t('user.myAccount.addresses.edit')}</span>
              :
                <span className="edit-address-button" onClick={this.openSideBar(address.id)}>{this.i18n.t('user.myAccount.addresses.edit')}</span>

            }
            <span onClick={this.deleteAddress} className="delete-address-button">{this.i18n.t('user.myAccount.addresses.delete')}</span>
          </div>
        }
      </div>
    );
  }
}
function mapStateToProps(state){
  return {
    storeConfiguration:state.storeConfig,
    storeCountries:state.storeCountries,
    user: state.user,
    isMobile: state.isMobile,
    sideBar: state.sideBar
  };
}
export default connect(mapStateToProps,{setUser,updateUser,openSideBar})(Address);
