import EzComponent from '@frontend/components/EzComponent';
import { connect } from 'react-redux'

export class RetailContainer extends EzComponent {

    componentDidMount(){
        if (typeof(window.retailrocket) != "undefined") {
            if (typeof(window.retailrocket.markup) != "undefined") {
                if (typeof(window.retailrocket.markup.render) != "undefined") {
                    window.retailrocket.markup.render();
                }
            }
        }
        
        console.log("Retail reload render")
    }
   
    render() {
        const { productId, products, categoryId, theme: { storeConfig: { langCode: stockId } } } = this.props;
        const blockId = this.props.blockId ? this.props.blockId : this.props.blockid;
        let result                  = [];
        
        if (productId) {
            result.push(<div key="product-view-retail" id="product-view" data-retailrocket-markup-block={blockId} data-product-id={productId} data-stock-id={stockId}></div>)
        } else if (categoryId){
            result.push(<div key="category-view-retail" id="category-view" data-retailrocket-markup-block={blockId} data-category-id={categoryId} data-stock-id={stockId}></div>)
        } else if (products) {
            result.push(<div key="products-view-retail" id="products-view" data-retailrocket-markup-block={blockId} data-products={products} data-stock-id={stockId}></div>)
        } else {
            result.push(<div key="remaining-block-retail" id="remaining-block" data-retailrocket-markup-block={blockId} data-stock-id={stockId}></div>)
        }
        return result;
    }
}

function mapStateToProps(theme) {
    return {
        theme
    }
}
export default connect(mapStateToProps, {})(RetailContainer);
