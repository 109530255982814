import EzComponent            from '@frontend/components/EzComponent';
import { connect }            from 'react-redux'
import Cookies                from 'nookies';
import cookie                 from 'cookie';

import { cancelCartCoupon }   from '@frontend/actions';

class DeleteCouponButton extends EzComponent {

  //// TODO: handle coupon errors
  handleDeleteCoupon = async () =>{
    const {
      cartData,
      user,
      storeConfiguration
    } = this.props;
    let variables= {
      storeId:(storeConfiguration.storeCode) ? parseInt(storeConfiguration.storeId) : 0,
      storeCode:(storeConfiguration.storeCode) ? storeConfiguration.storeCode : 'default',
      quoteId: (cartData.quote_id) ? cartData.quote_id: '',
      customerToken: (user.token) ? user.token : '',
      customerId:   (user.id) ? user.id : null,
      customerGroup: (user.group_id) ? user.group_id : 0,
      enableCrossSellProducts: storeConfiguration. enableMinicartCrossSell
    };
    await this.props.cancelCartCoupon({client:this.context.client},variables);
    this.props.deleted();
  }
  render(){
    const { className='' } = this.props;

    return (
      <button className={`delete-coupon ez-button ${className}`} onClick= {this.handleDeleteCoupon}>{this.i18n.t('coupons.deleteCoupon')}</button>
    );
  }
}

function mapStateToProps(state){
  return {
    cartData: state.minicart.cart,
    user:state.user,
    isSignedIn:state.user.isSignedIn,
    storeConfiguration:state.storeConfig
  }
}
export default connect(mapStateToProps, { cancelCartCoupon }) (DeleteCouponButton);
