import EzComponent from '@frontend/components/EzComponent';
import { connect } from 'react-redux'

import { validateCartItems } from '@frontend/actions/cart';

import '@frontend/components/buttons/checkout-button/checkout-button.less';

class CheckoutButton extends EzComponent {
    state = {
        products: [],
        cartData: {
            total: 0
        },
        user: {},
        isSignedIn: false,
        showCart: false,
        storeConfiguration: {}
    }

    goToCheckout = () => {
        const { cartData, user, storeConfiguration } = this.props;
        let variables = {
            quoteId: (cartData.quote_id) ? cartData.quote_id : '',
            customerToken: (user.token) ? user.token : '',
            storeConfiguration: storeConfiguration
        }
        this.props.validateCartItems(this.context, variables);
        // const { cartData, user, storeConfiguration } = this.props;
        // if (user.id) {
        //     return this.router.goToExternalUrl(storeConfiguration.checkoutURL + "?token=" + user.token);
        // } else {
        //     return this.router.goToExternalUrl(storeConfiguration.checkoutURL + "?masked=" + cartData.quote_id + "&store=" + storeConfiguration.storeId);
        // }
    }

    render() {
        const { className } = this.props;
        return (
            <a href="#" onClick={this.goToCheckout} className={`checkout ez-button go-to-checkout-button ${className}`}><span>{this.i18n.t('miniCart.buttonCheckout')}</span></a>
        );
    }
}

function mapStateToProps({ minicart, user, storeConfig }) {
    return {
        cartData: minicart.cart,
        user: user,
        storeConfiguration: storeConfig
    }
}
export default connect(mapStateToProps, { validateCartItems })(CheckoutButton);
