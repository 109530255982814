import EzComponent              from '@frontend/components/EzComponent';
import UserOrders           from '@frontend/components/my-account/orders/UserOrders'
import { connect }			from 'react-redux'
import '@frontend/components/side-bar/sections/my-orders/index.less';

class SideBarMyOrders extends EzComponent{
	render() {
		const { user } = this.props;
		return (	
			<div className="my-orders-content">
				<div className="section-header col-group">
								<span className="section-title col-11 col-mb-10">{this.i18n.t('user.myAccount.myOrders')}</span>
							</div>
				<UserOrders orders={user.orders} />
        	</div>
		);
	}
  
}
function mapStateToProps ({user}){
	return {user};
}
export default connect(mapStateToProps)(SideBarMyOrders);
  