import EzFormComponent              from '@frontend/components/EzFormComponent';
import cookie                       from 'cookie';
import { connect }                  from 'react-redux';
import { createAccount,
        addFlashMessage }           from '@frontend/actions';

import TextInput                    from '@frontend/components/basic-components/form/TextInput';
import {
  ERROR,
  SUCCESS }                         from '@frontend/constants'
// TODO: Find a better name for component.
export class CreateAccountBox extends EzFormComponent {
  state = {
    formData: {
      firstName:        { field:'firstName',       type:'text',     value:'', validation:'lettersWithSpaces,notEmpty', required:true },
      lastName:         { field:'lastName',        type:'text',     value:'', validation:'lettersWithSpaces,notEmpty', required:true },
      email:            { field:'email',           type:'text',     value:'', validation:'email,notEmpty',             required:true },
      password:         { field:'password',        type:'password', value:'', validation:'validPassword,notEmpty',     required:true },
      confirmPassword:  { field:'confirmPassword', type:'password', value:'', validation:'validPassword,notEmpty',     required:true }
    },
    storeConfiguration:{},
    error: ''
  }
  createAccount = async (e)=>{
    const { formData,formData:{email,firstName,lastName,password,confirmPassword} } 	 = this.state;
    const equalPasswords = (password.value === confirmPassword.value);

    e.preventDefault();
    e.stopPropagation();

    if(this.validateFields() && equalPasswords){
      const { storeConfiguration:{storeId,websiteId} }  = this.props;
      let variables = {
        customer:{
          email:email.value,
          firstname:firstName.value,
          lastname:lastName.value,
          store_id:(storeId) ? storeId : 0,
          website_id:(websiteId) ? parseInt(websiteId) : 0
        },
        password:password.value
      }
      const response = await this.props.createAccount(this.context,variables);
      let message = {};
      if (response == true){
        let newData = formData;
        message['type'] = SUCCESS;
        message['text'] = this.i18n.t('user.createAccount.success');
        this.props.addFlashMessage({message});
        Object.keys(formData).map((inputKey,index)=>{
          newData[inputKey].value='';
        });
        this.setState({ formData:newData, error:'' });
        this.router.redirect('signin');
      }else if(response.error){
        message['type'] = ERROR;
        message['text'] = response.error;
        this.props.addFlashMessage({message});
      }
    }
  }

  render(){
    const { formData,error } = this.state;

    return (
      <form className="ez-form create-account-form">
        {
          Object.keys(formData).map((inputKey,index)=>{
            const field       = formData[inputKey];
            const inputError  = (error[inputKey]) ? error[inputKey] : '';

            return(
              <div key={index} className="field">
                <label className= {`label ${(field.required) ? "required":""}`} >
                  <span>{this.i18n.t(`user.createAccount.${field.field}`)}</span>
                </label>
                <TextInput
                  type={field.type}
                  name={field.field}
                  onChange={this.updateValue}
                  value={field.value}
                />
                {
                  (inputError.length!==0) &&
                  <div className="ez-error-wrapper">
                    <span>{this.i18n.t(inputError)}</span>
                  </div>
                }
              </div>
            )
          })
        }
        {

        }
        <button className="ez-button" onClick={this.createAccount}>
          {this.i18n.t('user.createAccount.buttonLabel')}
        </button>

      </form>
    )
  }
}
function mapStateToProps (state){
  return { storeConfiguration:state.storeConfig }
}
function mapDispatchToProps(dispatch){
  return {
    createAccount: (client,variables) => dispatch(createAccount(client,variables)),
    addFlashMessage: (message) => dispatch(addFlashMessage(message))
  }
}
export default connect(mapStateToProps,mapDispatchToProps)(CreateAccountBox)
