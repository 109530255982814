import EzComponent       from '@frontend/components/EzComponent';
import Link              from 'next/link'

class Icon extends EzComponent{
  render(){
    const {isPng=false, reducedLogo=false} = this.props;

    return (
      <>
      <div className={`ez-icon`}>
        <Link {...this.router.get('/')}>
          <a><img src={`/static/logo.${isPng ? "png" : "jpg"}`}/></a>
        </Link>
      </div>
      {
        reducedLogo &&
        <div className={`ez-icon reduced`}>
          <Link {...this.router.get('/')}>
            <a><img src={`/static/logo_reduced.${isPng ? "png" : "jpg"}`}/></a>
          </Link>
        </div>
      }
      </>
    );
  }
}

export default Icon;
