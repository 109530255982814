import { CreateAccountBox
        as BaseCreateAccountBox }   from '@themes/store/frontend/components/create-account/CreateAccountBox';
import { connect }                  from 'react-redux';
import Link                         from 'next/link';
import { createAccount,
        addFlashMessage }           from '@frontend/actions';
import { openSideBar }              from '@frontend/actions/side-bar';
import { PRIVACY_POLICY }           from '@frontend/constants';
import _get                         from 'lodash/get';

import moment                       from 'moment';
import dynamic                      from 'next/dynamic';

const TextInput            = dynamic(()=> import('@frontend/components/basic-components/form/TextInput'));
const AutoSelectInput      = dynamic(()=> import('@frontend/components/basic-components/form/auto-select-input/AutoSelectInput'));
const DateInput            = dynamic(()=> import('@frontend/components/basic-components/form/date-input/DateInput'));

import {
  ADDRESS_BILLING_TYPE,
  ADDRESS_SHIPPING_TYPE,
  ERROR,
  SUCCESS }                         from '@frontend/constants'

import '@frontend/components/create-account/create-account-box.less';

// TODO: Find a better name for component.
class CreateAccountBox extends BaseCreateAccountBox {
  state = {
    formData: {
      firstName:        { field:'firstName',       type:'text',     value:'', validation:'lettersWithSpaces,notEmpty',        required:true,  className:'col-6 col-mb-6 two-fields'},
      lastName:         { field:'lastName',        type:'text',     value:'', validation:'lettersWithSpaces,notEmpty',        required:true,  className:'col-6 col-mb-6 two-fields'},
      birthday:         { field:'birthday',        type:'date',     value:'', validation:'notEmpty',             required:false, className:'col-12 col-mb-12'},
      email:            { field:'email',           type:'text',     value:'', validation:'email,notEmpty',                    required:true,  className:'col-12 col-mb-12'},
      telephone:        { field:'telephone',       type:'text',     value:'', validation:'numbers',                           required:false, className:'col-12 col-mb-12'},
      password:         { field:'password',        type:'password', value:'', validation:'validPassword,notEmpty',            required:true,  className:'col-12 col-mb-12'},
    },
    formExtra:{
      privacy:    { field:'privacy', value:false, required:true,  error:false},
      newsletter: { field:'newsletter', value:false, required:false, error:false},
      keepSession: {field: 'keepSession',value:true, required:false,error:false}
    },
    error: '',
    storeConfiguration:{},
    showPassword: false
  }
  openSideBar = ()=>{
    if (this.props.openSideBar){
      this.props.openSideBar('new-account');
    }
  }
  createAccount = async (e)=>{
    const {
      formData: {
        firstName,
        lastName,
        birthday,
        telephone,
        email,
        password
      },
      formData,
      formExtra } 	 = this.state;

    e.preventDefault();
    e.stopPropagation();
    if(this.validateFields() && formExtra.privacy.value){
      const { storeConfiguration:{storeId,websiteId,address_types},storeCountries,isMobile }  = this.props;
      let variables = {
        customer:{
          email:email.value,
          firstname:firstName.value,
          lastname:lastName.value,
          // dob:(birthday.value.length) ? moment(birthday.value,'DD/MM/YYYY').format('YYYY/MM/DD'): null ,
          custom_attributes:[
            {
              attribute_code: 'customer_phone',
              value: telephone.value
            },
            {
              attribute_code: 'keep_session',
              value: formExtra['keepSession'].value ? '1' : '0'
            }
          ],
          extension_attributes: {
            is_subscribed: formExtra.newsletter.value
          },
          store_id:(storeId) ? parseInt(storeId) : 0,
          website_id:(websiteId) ? parseInt(websiteId) : 0
        },
        password:password.value
      }
      const response = await this.props.createAccount(this.context,variables);
      let message = {};
      if (response == true){
        let newData = formData;
        Object.keys(formData).map((inputKey,index)=>{
          newData[inputKey].value='';
        });
        this.setState({ formData:newData, error:'' });
        if (isMobile){
          this.router.redirect('newAccount');
        }else{
          this.openSideBar('new-account');
        }
      }else if(response.error){
        message['type'] = ERROR;
        message['text'] = response.error;
        this.props.addFlashMessage({message});
      }
    }
  }
  onFocusSelect= (name)=>{
    let { formData } = this.state;
    formData[name]['focused'] = true;
    this.setState({ formData });
  }
  onBlurSelect= (name)=>{
    let { formData } = this.state;
    formData[name]['focused'] = false;
    this.setState({ formData });
  }
  handleCheckBox = ({target:{name}}) =>{

    let { formExtra } = this.state;
    formExtra[name].value = !formExtra[name].value;
    this.setState({formExtra});
  }
  toggleCheckBox = (key)=>()=>{
    let { formExtra } = this.state;
    formExtra[key].value = !formExtra[key].value;
    this.setState({formExtra});
  }
  toggleShowPassword = ()=>{
    this.setState((state)=>({showPassword:!state.showPassword}));
  }
  render(){
    const { formData,formExtra,error,selectValues,showPassword } = this.state;
    const { storeConfiguration } = this.props;
    const privacyPolicySettings = _get(PRIVACY_POLICY.byStore, storeConfiguration.storeId, _get(PRIVACY_POLICY.byStore, PRIVACY_POLICY.defaultId));

    return (
      <div className= "ez-form create-account-form">
        <form className="col-group">
          {
            Object.keys(formData).map((inputKey,index)=>{
              let { field,
                      value,
                      type,
                      focused=false,
                      className='',
                      required }  = formData[inputKey];
              const inputError    = (error[inputKey]) ? error[inputKey] : '';

              type = (type=='select' && selectValues[field] && !selectValues[field].length) ? 'text': type;

              return(
                <div key={index} className={`field ${type}  ${className} ${(focused) ? 'focused':''}`}>
                  {
                    (type=='date') ?
                      <div className={`input ${inputKey}`}>
                        <DateInput
                          value={value}
                          name={inputKey}
                          onChange={this.updateValue}
                          onFocus= {this.onFocusSelect}
                          onBlur= {this.onBlurSelect}
                        />
                      </div>
                    :
                    (type=='select' && selectValues[field] && selectValues[field].length) ?
                    <AutoSelectInput
                      className = {`input ${inputKey} ${(focused||value) ? 'active':''}`}
                      options = {selectValues[field]}
                      name={field}
                      onChange={this.handleSelect}
                      onFocus= {this.onFocusSelect}
                      onBlur= {this.onBlurSelect}
                      value = {value}
                    />
                    :
                    <TextInput
                      className={`input ${inputKey} ${(value.length) ? 'active':''}`}
                      type={(type=='password' && showPassword) ? 'text' : type}
                      name={field}
                      onChange={this.updateValue}
                      value={value}
                    />
                  }
                  <label className= {`label ${(required) ? "required":""}`} >
                    <span>{this.i18n.t(`user.createAccount.${field}`)}</span>
                  </label>
                  {
                    type=='password' &&
                    <span className="toggle-show-password" onClick={this.toggleShowPassword}>{this.i18n.t(`user.signIn.${showPassword ? 'hide' : 'show'}Password`)}</span>
                  }
                  {
                    (inputError.length!==0) &&
                    <div className="ez-error-wrapper">
                      <span>{this.i18n.t(inputError)}</span>
                    </div>
                  }
                </div>
              )
            })

          }
          </form>
          <div className="required-field-text"><span>{this.i18n.t('user.createAccount.requiredFields')}</span></div>
          <div className="create-account-actions">
            <div className="form-extra col-group">
            {
              Object.keys(formExtra).map((key,index)=>{
                const { field,value,required } = formExtra[key];
                return(
                  <div key={index} className="extra-field col-12 col-mb-12">
                    <input name={field} type="checkbox" checked={value} onChange={this.handleCheckBox} />
                    <label className= {`label ${(required) ? "required":""}`} onClick={this.toggleCheckBox(key)}>
                      {
                        (field=='privacy') ?
                        <>
                        <span>{this.i18n.t(`user.createAccount.privacy1`)}</span>
                        <span className="bold">{this.i18n.t(`user.createAccount.privacy2`)}</span>
                        <span>{this.i18n.t(`user.createAccount.privacy3`)}</span>
                        <Link  {...this.router.get('custom',{slug:privacyPolicySettings.slug})}><a><span>{this.i18n.t('user.createAccount.privacyLink')}</span></a></Link>
                        </>
                        :
                        (field=='newsletter') ?
                        <>
                        <span className="bold">{this.i18n.t(`user.createAccount.newsletter1`)}</span>
                        <span>{this.i18n.t(`user.createAccount.newsletter2`)}</span>
                        </>
                        :
                        <span className="bold">{this.i18n.t(`user.createAccount.${field}`)}</span>
                      }
                    </label>
                  </div>
                )
              })
            }
            </div>
          <button className="create-account-button" onClick={this.createAccount}>
            {this.i18n.t('user.createAccount.buttonLabel')}
          </button>
        </div>
      </div>

    )
  }
}
function mapStateToProps (state){
  return { storeConfiguration:state.storeConfig,storeCountries:state.storeCountries,isMobile: state.isMobile }
}

export default connect(mapStateToProps,{createAccount,addFlashMessage,openSideBar})(CreateAccountBox)
