import EzComponent        from '@frontend/components/EzComponent';
import Link               from 'next/link';
import { connect }        from 'react-redux';
import { openSideBar }    from '@frontend/actions/side-bar';
import { setMenuHidden }  from '@frontend/actions/theme';
import { openAccountModal,closeAccountModal }   from '@frontend/actions/account-modal';


import '@frontend/components/header/account-link/account-link.less';

class AccountLink extends EzComponent{
  openSideBar = ()=>{
    const { user } = this.props;
    this.props.setMenuHidden(true);
    if ((!user || !user.id) && this.props.openSideBar){
      this.props.openSideBar('signin');
    }else if ( user && user.id && this.props.openSideBar){
      this.props.openSideBar('my-account');
    }
  }

  toggleShowAccountModal = () =>{
    const { showAccountModal,user } = this.props;
    if(!user || !user.isSignedIn){
      return this.router.redirect('signin');
    }
    
    showAccountModal ? this.props.closeAccountModal() : this.props.openAccountModal();
  }

  render(){
    const { colData,user, isMobile } = this.props;
    return (
      <div className="ez-account-link ez-nav-span">
          <span className="account-icon" onClick={!isMobile ? this.openSideBar : this.toggleShowAccountModal}></span>
      </div>
    );
  }
}
function mapStateToProps({user,isMobile}){
  return { user, isMobile };
}
export default connect(mapStateToProps,{openSideBar,setMenuHidden,openAccountModal,closeAccountModal})(AccountLink);
