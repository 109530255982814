import EzComponent        from '@frontend/components/EzComponent';
import { connect }        from 'react-redux';
import { openSideBar }    from '@frontend/actions/side-bar';

import Link               from 'next/link';

import '@frontend/components/signin/create-account-link.less';

class CreateAccountLink extends EzComponent {
  openSideBar = ()=>{
    if (this.props.openSideBar){
      this.props.openSideBar('create-account');
    }
  }
  render(){
    const { isMobile } = this.props;
    return (
      <div className="create-account-link-wrapper">
        <span className="section-title">{this.i18n.t('user.signIn.newCustomer')}</span>
        <div className="create-account-link-content">
          <span className="create-account-benefits">{this.i18n.t('user.signIn.createAccountBenefits')}</span>
          {
            isMobile ? 
              <Link {...this.router.get('createAccount')}><a><span className="create-account-button">{this.i18n.t('user.signIn.createAccount')}</span></a></Link>
            :
              <span className="create-account-button" onClick={this.openSideBar}>{this.i18n.t('user.signIn.createAccount')}</span>
          }
        </div>
      </div>
    );
  }
}

function mapStateToProps({isMobile}){
  return { isMobile };
}
export default connect(mapStateToProps,{ openSideBar })(CreateAccountLink);
