import EzComponent            from '@frontend/components/EzComponent';
import { connect }            from 'react-redux'
import {
  addItemToWishlist,
  deleteItemFromWishlist
}                             from '@frontend/actions';
import { openSideBar }        from '@frontend/actions/side-bar';

import ReducedModal 				  from '@frontend/components/basic-components/reduced-modal';
import Link                   from 'next/link';

import '@frontend/components/buttons/wishlist/wishlist-button.less';

export const ADD_ITEM_ACTION    = 'add_item';
export const DELETE_ITEM_ACTION = 'delete_item';

export class WishlistButton extends EzComponent {
  state = {
    blocked: false,
    showResultModal:false,
    showLogInModal:false,
    resultModalData : {
      ok: false,
      action: ''
    }
  }
  changeItemWishlist = (action)=> async ()=>{
    const { productId } = this.props;
    const { user } = this.props;
    const { blocked } = this.state;
    let result = false;
    if (!blocked){
      if (user.id){
        this.setState({blocked:true});
        if (action==ADD_ITEM_ACTION){
          let variables = {
            customerId:user.id,
            productId
          };
          result = await this.props.addItemToWishlist(this.context,this.i18n,variables);
        }else if (action==DELETE_ITEM_ACTION){
          let variables = {
            customerId:user.id,
            productId
          };
          result = await this.props.deleteItemFromWishlist(this.context,this.i18n,variables);
        }
        if(this.props.onClickWishlist){
          this.props.onClickWishlist();
        }
        this.setState({blocked:false, showResultModal:true, resultModalData:{ok:result,action}});
      }else{
        this.setState({showLogInModal:true});
      }
    }
    

  }
  closeLogInModal = ()=>{
    this.setState({showLogInModal:false})
  }
  closeResultModal = ()=>{
    this.setState({showResultModal:false})
  }
  goToLogIn = ()=>{
    const { isMobile } = this.props;
    this.closeLogInModal();
    if (isMobile){
      this.router.redirect('signin');
    }else{
      this.props.openSideBar('signin');
    }
  }

  getWishlistAction = () => {
    const { wishlistItems, productId } = this.props;

    if(wishlistItems && wishlistItems.length && wishlistItems.includes(productId)){
      return DELETE_ITEM_ACTION;
    }else{
      return ADD_ITEM_ACTION;
    }
  }
  render(){
    const action = this.getWishlistAction();
    const { product } = this.props;
    const { showLogInModal,showResultModal,resultModalData={} } = this.state;
    let productName = product.name;
    if (productName) {
      productName = productName.toLowerCase();
    }
    productName = productName.charAt(0).toUpperCase() + productName.slice(1);
    return (
      <div className="whishlist-actions">
        <button
          className={`wishlist-button ${(action==ADD_ITEM_ACTION) ? 'add':'delete'}`}
          onClick={this.changeItemWishlist(action)}
        >
          <span>
            {this.i18n.t(`${(action==ADD_ITEM_ACTION) ? 'addTo':'deleteFrom'}Wishlist.action`)}
          </span>
        </button>
        {
          showLogInModal &&
          <ReducedModal className="wishlist-log-in-modal" closeModal={this.closeLogInModal}>
            <span className="log-in-message">{this.i18n.t('wishlist.logInToAdd')}</span>
            <div className="log-in-button">
              <span className="log-in" onClick={this.goToLogIn}>{this.i18n.t('wishlist.logIn')}</span>
            </div>
          </ReducedModal>
        }
        {
          showResultModal &&
          <ReducedModal className="wishlist-result-modal" closeModal={this.closeResultModal}>
            {
              resultModalData && resultModalData.action == ADD_ITEM_ACTION &&
                <>
                {
                  resultModalData.ok ?
                    <span className="result-message">{this.i18n.t('wishlist.addSuccess',[productName])}</span>
                  :
                    <span className="result-message">{this.i18n.t('wishlist.addError',[productName])}</span>
                }
                </>
            }
            {
              resultModalData && resultModalData.action == DELETE_ITEM_ACTION &&
                <>
                {
                  resultModalData.ok ?
                    <span className="result-message">{this.i18n.t('wishlist.deleteSuccess',[productName])}</span>
                  :
                    <span className="result-message">{this.i18n.t('wishlist.deleteError',[productName])}</span>
                }
                </>
            }
            {
              resultModalData.ok &&
              <div className="go-to-wishlist-button">
                <Link {...this.router.get('myAccountWishlist')}><span className="go-to-wishlist">{this.i18n.t('wishlist.goToWishlist')}</span></Link>
              </div>
            }
          </ReducedModal>
        }
      </div>
    )
  }
}
function mapStateToProps({ user,isMobile }){
  return {
    user,
    wishlistItems:      user.wishlist_items,
    wishlistItemsCount: user.wishlistItemsCount,
    isMobile
  }
}
export default connect(
  mapStateToProps,
  {addItemToWishlist,deleteItemFromWishlist,openSideBar}
)(WishlistButton);
