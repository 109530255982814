import EzFormComponent             from '@frontend/components/EzFormComponent'
import { ApolloContext }           from 'react-apollo';
import { connect }                 from 'react-redux';
import Link                        from 'next/link';
import {
  signIn
}                                  from '@frontend/actions/user';

import '@frontend/components/signin/signin-box.less';

export class SigninBox extends EzFormComponent {
  static displayName      = "SigninBox";
  static mapStateToProps  = (state) =>{
    return {
      storeConfiguration: state.storeConfig,
      minicart:           state.minicart
    }
  }
  // static mapDispatchToProps = { signIn }
  state = {
    formData: {
      email:            { field:'email',type:'text',value:'',validation:'notEmpty,email',required:true },
      password:         { field:'password',type:'password',value:'',validation:'notEmpty',required:true }
    },
    error: '',
    storeConfiguration:{}
  }

  handleClick = async (e) =>{
    const { formData }                     = this.state;
    const { minicart,storeConfiguration }  = this.props;

    e.preventDefault()
    e.stopPropagation()

    if (this.validateFields()){
      let options= {
        email: formData.email.value,
        password: formData.password.value,
        storeConfiguration,
        minicart
      };
      this.props.signIn(this.context,options,()=>{
        this.router.redirect('myAccountMe');
      })
    }
  }
  onInputChange = ({target})=>{
    let newData = this.state.formData;
    newData[target.name].value= target.value;
    this.setState({formData:newData});
  }

  render(){
    const { formData,error } = this.state;
    return (
      <>
      <div className="section-title">
        <span>{this.i18n.t('user.signIn.pageTitle')}</span>
      </div>
      <form className="ez-form sign-in-form">
        {
          Object.keys(formData).map((inputKey,index)=>{
            const field = formData[inputKey];
            return(
              <div key={index} className="field">
                <label className= {`label ${(field.required) ? "required":""}`} >
                  <span>{this.i18n.t(`user.signIn.${field.field}`)}</span>
                </label>
                <input
                  name={field.field}
                  type={field.type}
                  value={field.value}
                  onChange={this.onInputChange.bind(this)}
                />
                {
                  (error[inputKey] && error[inputKey].length!==0) &&
                  <div className="ez-error-wrapper sign-in">
                    <span>{this.i18n.t(error[inputKey])}</span>
                  </div>
                }
              </div>
            )
          })
        }
        <button className="signin-button" onClick={this.handleClick}>
          {this.i18n.t('user.signIn.buttonLabel')}
        </button>
        <Link {...this.router.get('forgottenPassword')}>
          <a className="forgotten-password-link">
            <span>{this.i18n.t('user.signIn.forgottenPassword')}</span>
          </a>
        </Link>
      </form>
      </>
    )
  }
}
export default connect(SigninBox.mapStateToProps,{ signIn } )(SigninBox);
