import {SearchBar as BaseSearchBar}     from '@themes/base/frontend/components/searchbar/SearchBar';
import dynamic                          from 'next/dynamic';
import { connect }                      from 'react-redux';
import Router                           from 'next/router';
import { getSearchData,setSearchValue } from '@frontend/actions';
import ProductList                      from '@frontend/components/product-list/ProductList';
import SearchResult                     from '@frontend/pages/search-result';

import '@frontend/components/searchbar/searchbar.less';

export class SearchBar extends BaseSearchBar{

  state = {
    showSearchBar: false
  }

  submitSearch = () =>{
    this.toggleSearchBar();
    this.searchValue();
  }

  handleKeyDown= (e) =>{
    if (e.key === 'Enter') {
      this.submitSearch();
    }
  }

  searchValue = () => {
    const { search }      = this.props;
    const { searchValue } = search;
    if(searchValue){
      this.router.redirect('searchProducts',{},{ q: searchValue });
      dynamic(()=> import("react-scroll")).then((Scroll)=>{
        Scroll.animateScroll.scrollToTop();
      })
    }
  }

  handleChange = async (event) => {

    const { storeConfig,getSearchData,setSearchValue } = this.props;
    const { searchChar:searchMinChar }  = storeConfig
    const searchValue                   = event.target.value;
    const storeId                       = (storeConfig && storeConfig.storeId) ? parseInt(storeConfig.storeId) : 0;

    // if ( searchValue.length >= searchMinChar){
      let variables = {
        searchText:       searchValue,
        actualPage:       SearchResult.defaultOptions.currentPage,
        pageSize:         SearchResult.defaultOptions.pageSize,
        selectedFilters:  SearchResult.defaultOptions.filters,
        storeId,
        customerGroup:    0
      };
      await getSearchData(this.context,variables);
      setSearchValue(searchValue);
    // }
  }

  componentDidMount(){
    // empty to overwrite base
  }

  render(){
    const { colData,search,isMobile } = this.props;
    const { searchValue }    = search;
    const { showSearchBar }  = this.state;

    return(
      <div className={`ez-search-bar-wrapper ${colData}`}>
        <div className="ez-search-bar-show">
          <div className="search-button" onClick={this.toggleSearchBar}>
            <span className="search-icon" id="search_mini_form"></span>
            <span className="text">{this.i18n.t('searchBar.search')}</span>
          </div>
        </div>
        {
          showSearchBar &&
          <div className={`ez-search-bar`}>
            <div className="close-button" onClick={this.toggleSearchBar}>
              <span>{this.i18n.t('searchBar.close')}</span>
            </div>

            <div className="ez-search-input">

                <input
                  type="text"
                  value={searchValue}
                  onChange={this.handleChange}
                  onKeyDown={this.handleKeyDown}
                  id="search_mini_form"
                  autoFocus={isMobile}
                />
                <div className="submit-button" onClick={this.submitSearch}>
                <span className="search-icon" id="search_mini_form"></span>
                </div>

            </div>
            <div className="search-results col-group">
              <div className="ez-search-result-products col-9">
                <ProductList
                  products={search.items}
                  showRating={true}
                  loading={false}
                  hasMoreProducts={false}
                  />
                </div>
            </div>
          </div>
        }

      </div>
    );
  }
}
function mapStateToProps({storeConfig,search,isMobile}){
  return {storeConfig,search,isMobile}
}
export default connect(mapStateToProps,{getSearchData,setSearchValue})(SearchBar);
