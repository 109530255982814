import EzComponent            from '@frontend/components/EzComponent';
import { connect }            from 'react-redux';

import Modal                  from '@frontend/components/modal/Modal';

import {deleteFlashMessage}   from '@frontend/actions';

import { MESSAGES_LIFETIME }  from '@frontend/constants';


class Message extends EzComponent{
  state = {
    showModal: false,
    modalError: ''
  }
  closeModal = () =>{
    this.setState({ showModal: false });
  }

  showModal = () => (ev)=>{
    this.setState({ showModal: true });
  }

  deleteMessage = (id)=>{
    this.props.deleteFlashMessage(id);
  }

  static getDerivedStateFromProps(props,state){
    const { deleteFlashMessage,id } = props;

   if(id){
    setTimeout(()=>{
     deleteFlashMessage(id)
   },MESSAGES_LIFETIME);
   }
   return state;
  }

  render (){
    const {showModal,modalError} = this.state;
    const {id,type,text,details} = this.props;

    return (
      <div className={"message "+type} id={id}>
        <span className = "message-content">{text}</span>
        {
          details &&
            <>
              <button className="details-button" onClick={this.showModal()}>Details</button>

              <Modal
              titleText={this.i18n.t('flashMessages.modal_title')}
              show={showModal}
              onHide={this.closeModal}
              error={modalError}
              acceptButtonText={this.i18n.t('modal.close')}
              acceptAction={this.closeModal}
              >
                {details.map(exception=>{
                  return (
                    <p>{"Row "+exception}</p>
                  )
                })}
              </Modal>
            </>
        }
        <span className="close-message-button" onClick= {()=>this.deleteMessage(id)}></span>
      </div>
    );
  }
}


export default connect(null,{deleteFlashMessage})(Message);
