import EzComponent       from '@frontend/components/EzComponent';
import { connect }       from "react-redux";
import {
  CarouselProvider,
  Slider,
  Slide,
  ButtonBack,
  ButtonNext
}                       from 'pure-react-carousel';
import ProductListItem  from '@frontend/components/product-list/product-item/ProductListItem';

import '@frontend/components/product-slider/product-slider.less';

export class ProductSlider extends EzComponent{

  getDesktopContent(){
    let {
      className,
      products,
      wishlistItems,
      infinite,
      isPlaying,
      visibleSlides,
      isMobile
    } = this.props;
    const productsLength = (products) ? products.length : 0;
    visibleSlides = this.props.isMobile ? 1 : visibleSlides;
    isPlaying = true;
    let sliderHeight = false;
    if (isMobile && typeof (window) != "undefined") {
      sliderHeight = window.innerHeight;
    } else {
      sliderHeight = 125;
    }
    return(
      <CarouselProvider className={`${className}-slider product-slider`}
        naturalSlideWidth={100}
        naturalSlideHeight={sliderHeight}
        visibleSlides={visibleSlides}
        totalSlides={productsLength}
        isPlaying={isPlaying}
        infinite={infinite}
        dragEnabled={isMobile ? true : false}
      >
        <Slider className={`${className}-slider`}>
          {
            products.map((product, index) => {
              return (
                <Slide key={index} index={index} className={`${className}-slider-item product-slider-item`}>
                  <ul className="product-slider-item-container">
                    <ProductListItem
                      key={index}
                      product={product}
                      containerClass={className}
                      wishlistItems={wishlistItems}
                      wishlistButtonEnable={true}
                      showRating={(product.rating_summary != null)}
                    />
                  </ul>
                </Slide>
              )
            })
          }
        </Slider>
        {
          (visibleSlides < productsLength) &&
          <div className={`${className}-actions-toolbar product-slider-actions-toolbar`}>
            <ButtonBack className={`${className}-back-button product-slider-back-button`}></ButtonBack>
            <ButtonNext className={`${className}-next-button product-slider-next-button`}></ButtonNext>
          </div>
        }
      </CarouselProvider>
    )
  }

  getMobileContent()
  {
    let {
      className,
      products,
      wishlistItems,
      infinite,
      isPlaying,
      visibleSlides,
      isMobile
    } = this.props;
    let productsLength = (products) ? products.length : 0;
    visibleSlides = this.props.isMobile ? 1 : visibleSlides;
    isPlaying = true;
    let sliderHeight = false;
    if (isMobile && typeof (window) != "undefined") {
      sliderHeight = window.innerHeight;
    } else {
      sliderHeight = 125;
    }
    if (this.router.getCurrentPath() == "/summer-sales") {
      productsLength = 2;
    }
    return(
      <CarouselProvider className={`${className}-slider product-slider`}
        naturalSlideWidth={100}
        naturalSlideHeight={sliderHeight}
        visibleSlides={visibleSlides}
        totalSlides={productsLength}
        isPlaying={isPlaying}
        infinite={infinite}
        dragEnabled={isMobile ? true : false}
      >
        <Slider className={`${className}-slider`}>
          {
            products.map((product, index) => {
              return (
                <Slide key={index} index={index} className={`${className}-slider-item product-slider-item`}>
                  <ul className="product-slider-item-container">
                    <ProductListItem
                      key={index}
                      product={product}
                      containerClass={className}
                      wishlistItems={wishlistItems}
                      wishlistButtonEnable={true}
                      magentoSlider={true}
                      showRating={(product.rating_summary != null)}
                    />
                  </ul>
                </Slide>
              )
            })
          }
        </Slider>
        {
          (visibleSlides < productsLength) &&
          <div className={`${className}-actions-toolbar product-slider-actions-toolbar`}>
            <ButtonBack className={`${className}-back-button product-slider-back-button`}></ButtonBack>
            <ButtonNext className={`${className}-next-button product-slider-next-button`}></ButtonNext>
          </div>
        }
      </CarouselProvider>
    )
  }

  render(){
    const {isMobile} = this.props
    const finalContent = isMobile ? this.getMobileContent() : this.getDesktopContent() 
    return(
      finalContent
    )
  }
}

function mapStateToProps ({isMobile}){
  return { isMobile };
}
export default connect(mapStateToProps)(ProductSlider);
