export const setHeaderHeight = (headerHeight) => {
  return {
    type: "SET_HEADER_HEIGHT",
    headerHeight
  }
}

export const setHeaderHeightWithMarquee = (headerHeight) => {
  return {
    type: "SET_HEADER_HEIGHT_MARQUEE",
    headerHeight
  }
}
