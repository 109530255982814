import EzComponent          from '@frontend/components/EzComponent';
import Link                 from 'next/link';

import { connect }          from 'react-redux'
import { signOut }               from '@frontend/actions'

import '@frontend/components/header/account-menu/account-menu.less';

class AccountMenu extends EzComponent{
  state = {
    isSignedIn:false
  }
  handleSignOut=()=>{
    this.props.signOut();
  }

  render(){
    const { isSignedIn } = this.props;
    if (isSignedIn){
      return (
        <div className={`ez-account-menu signed-in`}>
          <Link {...this.router.get('myAccountMe')}><span className="menu-link profile">{this.i18n.t('accountMenu.profile')}</span></Link>
          <Link {...this.router.get('myAccountMyOrders')}><span className="menu-link orders">{this.i18n.t('accountMenu.orders')}</span></Link>
          <Link {...this.router.get('myAccountWishlist')}><span className="menu-link wishlist">{this.i18n.t('accountMenu.wishlist')}</span></Link>
          <Link {...this.router.get('signOut')}><span className="menu-link signout-button">{this.i18n.t('accountMenu.signout')}</span></Link>
        </div>
      );
    }else{
      return (
        <div className={`ez-account-menu`}>
          <Link {...this.router.get('signin')}><span className="menu-link signin">{this.i18n.t('accountMenu.signin')}</span></Link>
        </div>
      );
    }

  }
}
function mapStateToProps(state){
  return { isSignedIn:state.user.isSignedIn };
}
export default connect(mapStateToProps,{signOut})(AccountMenu);
