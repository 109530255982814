import EzComponent   from '@frontend/components/EzComponent';
import Router        from 'next/router';
import { connect }   from 'react-redux';

import '@frontend/components/searchbar/searchbar.less';

export class SearchBar extends EzComponent{

  state = {
    searchValue:   null,
    showSearchBar: false
  }

  handleSubmit = (event)=>{
    const { searchValue } = this.state;
    this.searchValue(searchValue);
  }
  searchValue = (searchValue) => {
    if(searchValue){
      Router.push({
        pathname: '/search-result',
        query:    { q: searchValue },
      });
    }
  }
  handleChange = (event)=>{
    //Si queremos ir mostrando resultados de busqueda se hace aqui.
    const { searchMinChar } = this.props;
    const searchValue = event.target.value;
    if ( searchValue.length >= searchMinChar){
      this.searchValue(searchValue);
    }
    this.setState({searchValue});
  }

  toggleSearchBar = () =>{
    this.setState((state)=>({showSearchBar: !state.showSearchBar}))
  }

  handleKeyDown= (e) =>{
    if (e.key === 'Enter') {
      this.handleSubmit();
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let returnData = {};

    if (prevState.searchValue === null){
      returnData = {...returnData, searchValue:nextProps.search};
    }

    return returnData;
  }

  static getInitialProps ({query}) {
    const { q:searchValue } = query;
    return {searchValue};
  }

  componentDidMount(){
    const { isMobile } = this.props;
    
    if(!isMobile){
      this.setState({showSearchBar:true});
    }
  }



  render(){
    const { colData,search,isMobile } = this.props;
    const { showSearchBar }           = this.state;

    return(
      <div className={`ez-search-bar-wrapper ${colData}`}>
      {
        isMobile &&
        <div className="ez-search-bar-show">
          <div className="search-mobile-button" onClick={this.toggleSearchBar}><span></span></div>
        </div>
      }
      {
        showSearchBar &&
        <div className={`ez-search-bar`}>
          <div className="ez-search-input">
            <input
              type="text"
              value={this.state.searchValue}
              placeholder={this.i18n.t('searchBar.placeholder')}
              onChange={this.handleChange}
              onKeyDown={this.handleKeyDown}
              autoFocus={isMobile}
                id="search_mini_form"
            />
          </div>
          <div className="ez-search-controls">
            {
              isMobile ?
                <div className="close-button" onClick={this.toggleSearchBar}><span></span></div>
              :
                <div className="submit-button" onClick={this.handleSubmit}>
                    <span className="search-icon" id="search_mini_form"></span>
                </div>
            }
          </div>
        </div>
      }
      </div>
    );
  }
}
function mapStateToProps({isMobile}){
  return {
    searchMinChar: 3, //get from config
    isMobile
  }
}
export default connect(mapStateToProps)(SearchBar);
