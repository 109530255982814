import {Header as BaseHeader}                   from '@themes/store/frontend/components/header/Header';
import { connect }                              from 'react-redux';
import { compose }                              from 'redux';
import {withScroll}                             from '@frontend/lib/withScroll';
import Link                                     from 'next/link';

import _isEqual                                 from 'lodash/isEqual';
import _cloneDeep                               from 'lodash/cloneDeep';

import Head                                     from 'next/head';
import { closeCart,openCart }                   from '@frontend/actions/cart';
import { openAccountModal,closeAccountModal }   from '@frontend/actions/account-modal';
import { setHeaderHeight }                      from '@frontend/actions/header';
import { setMenuHidden }                        from '@frontend/actions/theme';
import { closeSideBar }                         from '@frontend/actions/side-bar';
import { setMenuBackground }                    from '@frontend/actions/theme';

import SearchBar                                from '@frontend/components/searchbar/SearchBar'
import MenuFooter                               from '@frontend/components/footer/MenuFooter';

import MiniCart                                 from '@frontend/components/minicart/MiniCart'
import AccountLink                              from '@frontend/components/header/account-link/AccountLink'
import WishlistLink                             from '@frontend/components/header/wishlist-link/WishlistLink'
import Navigation                               from "@frontend/components/header/Navigation"
import CMSBlock                                 from '@frontend/components/cms-block/CMSBlock';
import { HEADER_BANNER_ENABLED,HEADER_HEIGHT }  from '@frontend/constants';


import logo_black                               from '@frontend/static/logo-etnia.png';
import logo_white                               from '@frontend/static/logo-etnia-white.png';
import logo_black_borderless                    from '@frontend/static/logo-borderless.png';
import logo_white_borderless                    from '@frontend/static/logo-borderless-w.png';

import SideBarActions                           from '@frontend/components/side-bar/actions';

class Header extends BaseHeader {
  state = {
    showMegaMenuMobile: false,
    showChat: false,
    lastBackground: false,
    currentCart: null,
    currentShowCart: null,
    search: false
  }
  toggleShowMegaMenuMobile = () =>{
    const current_bg = this.props.theme.menuBackground;
    this.props.setMenuHidden(!this.props.theme.hideMegaMenu);
    this.props.closeSideBar();
    if (this.props.theme.hideMegaMenu) {
      this.props.setMenuBackground("transparent")
    } else {
      if (this.state.lastBackground) {
        this.props.setMenuBackground(this.state.lastBackground)
      } else {
        this.props.setMenuBackground("filled")
      }
    }
    this.setState({
      lastBackground: current_bg
    })
  }
  toggleShowCart = () =>{
    const {showCart} = this.props;
    this.minicartChanges(!showCart);
  }
  toggleShowAccountPopUp = () =>{

  }
  headerClasses = () => {
    const { showCart,scrollData={},isMobile,theme } = this.props;
    const { showMegaMenuMobile }              = this.state;
    const { scrollTop }                       = scrollData;
    const scrolled                            = scrollTop >= HEADER_HEIGHT;
    const themeClass    = theme.activeTheme;
    const sectionClass  = theme.activeSection;
    const scrolledClass = 'normal-header';
    const minicartClass = showCart                         ? 'minicart-opened'    : '';
    const bannerClass   = HEADER_BANNER_ENABLED            ? 'with-banner'        : '';
    const mobileClass   = (theme.hideMegaMenu)             ? 'menu-closed'        : 'menu-opened';

    return `${scrolledClass} ${minicartClass} ${bannerClass} ${mobileClass} ${sectionClass}`;
  }

  toggleShowAccountModal = () =>{
    const { showAccountModal } = this.props;
    if (showAccountModal){
      this.props.closeAccountModal();
    }else{
      this.props.openAccountModal();
    }
  }


  toggleSearcher = () => {
    this.setState(state => ({search:!state.search}))
  }

  getMainMenuItems(showCart)
  {
    const { isMobile,theme:{hideMegaMenu},search,showAccountModal } = this.props;
    return(
      <>
        <SearchBar colData="" search={search} showSearchInput={this.state.search}/>
        <span className="search-icon" onClick={this.toggleSearcher}></span>
        <WishlistLink />
        <MiniCart colData="" onMinicartChange={this.minicartChanges} showCart={showCart}/>
        <AccountLink showAccountModal={showAccountModal} />       
      </>
    )
  }

  closeMegamenu=()=>{
    this.props.setMenuHidden(true);
  }

  getProductTotalQty(items)
  {
    let total = 0;
    if (items == null||items==undefined||items.length == 0) {
      return total;
    }
    for (let index = 0; index < items.length; index++) {
      const element = items[index];
      total = total + element.qty
    }
    return total;
  }

  componentDidUpdate() {
    if(
      !_isEqual(this.state.currentCart, this.props.cartData) || 
      !_isEqual(this.state.currentShowCart, this.props.showCart)
    ){
      if(this.props.showCart){
        this.context.scriptManager.dispatch("ViewCart", this.props.cartData);
      }
      this.setState({
        currentCart: _cloneDeep(this.props.cartData),
        currentShowCart: _cloneDeep(this.props.showCart)
      });
    }
  }

  render() {
    const { showMegaMenuMobile }                            = this.state;
    const { cartData,search,showCart,scrollData={},isMobile,theme,user,storeConfiguration:{currentStore:{path}} }  = this.props;
    const { scrollTop }                                     = scrollData;
    const scrolled                                          = scrollTop >= HEADER_HEIGHT;
    let mobileFixedMenuClass                                = theme.hideMegaMenu ? "disabled" : "enabled";
    mobileFixedMenuClass = mobileFixedMenuClass + " " + theme.menuBackground;
    let totalItems = 0;
    let logoImgUrl;

    if (cartData != undefined) {
      totalItems = this.getProductTotalQty(cartData.items);
    } else {
      totalItems = 0;
    }

    logoImgUrl = "/static/logo-borderless.png";
    
    return (
      <>
      <div ref={this.trackPosition.bind(this)} id="ez-header" className={`ez-header col-group ${this.headerClasses()}`}>
        <Head/>
        <div className= "ez-top-pannel">
          {
            HEADER_BANNER_ENABLED &&
              <CMSBlock id="header_banner"/>
          }
        </div>
        <div className={"ez-header-menu col-12 "+theme.menuBackground}>

          <div className={`ez-left-menu ez-header-sub-section col-6 ${this.state.search && isMobile ? 'show-search' : ''}`}>
            <span className="ez-nav-span ez-menu-toggle" onClick={this.toggleShowMegaMenuMobile}></span>
            {!isMobile && <CMSBlock id="header_categories"/>}
          </div>

          <div className={`logo black ${this.state.search && isMobile ? 'show-search' : ''}`}>
            <a href={`${path}/`}>
              <img className={!isMobile ? 'desktop-logo' : ''} src={logoImgUrl}></img>
            </a>
          </div>

          <div className={`ez-right-menu ez-header-sub-section col-6 ${this.state.search && isMobile ? 'show-search' : ''}`}>
            {this.getMainMenuItems(showCart)}
          </div>

        </div>

        <div className="ez-navigation-menu mobile-menu">
          <div className="ez-navigation">
            {
              <div className="close-megamenu" onClick={this.closeMegamenu}>
                <span className="close-icon"></span>
              </div>
            }
            <Navigation />
            <MenuFooter />
          </div>
        </div>
        <div className="mobile-menu-overlay" onClick={this.toggleShowMegaMenuMobile}></div>
      </div>
    </>
    );
  }
}

function mapStateToProps({minicart,user,storeConfig,header,search,isMobile,showAccountModal,theme,sideBar}){
  return {
    cartData:           minicart.cart,
    user:               user,
    storeConfiguration: storeConfig,
    showCart:           minicart.showCart,
    header,
    isMobile,
    theme,
    search,
    showAccountModal,
    sideBarShow:    sideBar.show
  }
}
export default compose(
  withScroll,
  connect(mapStateToProps, {
    closeCart,
    openCart,
    setMenuBackground,
    setHeaderHeight,
    setMenuHidden,
    openAccountModal,
    closeAccountModal,
    closeSideBar
  })
  )(Header);


// <span className="ez-toggle-looks"></span>

// <span className="ez-nav-span">
//   ES | EN
// </span>
