import {MiniCart as BaseMiniCart} from '@themes/store/frontend/components/minicart/MiniCart';

import { connect }                from 'react-redux'
import Cookies                    from 'nookies';

import CartProductItem            from '@frontend/components/minicart/CartProductItem';
import CheckoutButton             from '@frontend/components/buttons/checkout-button/CheckoutButton';
import Coupon                     from '@frontend/components/coupons/Coupon';
import FreeShippingStatusBar      from '@frontend/components/minicart/free-shipping-status-bar';

import MiniCartHeader             from '@frontend/components/minicart/cart-header';
import MiniCartTotals             from '@frontend/components/minicart/cart-totals';
import CrossSellProducts          from '@frontend/components/minicart/cross-sell-products';
import ProductList                from '@frontend/components/product-list/ProductList';
import Link                 		  from 'next/link';
import { setCart }                from '@frontend/actions';
import {setActiveSection,
        setActiveTheme }          from '@frontend/actions';

import _get                       from 'lodash/get';

class MiniCart extends BaseMiniCart{
  state = {
    products : [],
    cartData:{
      subtotal: 0
    },
    user:{},
    storeConfiguration:{}
  }

  render(){
    const { 
      cartData,
      showCart,
      wishlistItems,
      storeConfiguration,
      isMobile,
      activeSection,
      crossSellCategory }                 = this.props;
    const products 			                  = (cartData && cartData.items)? cartData.items: [];
    const showCancelCoupon 		            = (cartData && cartData.subtotal) ? (cartData.subtotal != cartData.subtotal_discount) : false;
    const numProducts                     = products.length;
    let   totalItems                      = 0;
    if (products && products.length) {
      for (let index = 0; index < products.length; index++) {
        const cart_item = products[index];
        totalItems = totalItems + cart_item.qty;
      }
    }
    if (showCart && activeSection!='cart'){
      this.props.setActiveSection("cart");
      this.props.setActiveTheme("black");
    }else if(!showCart && activeSection=='cart'){
      this.props.setActiveSection('');
    }

    return (
      <div className={`ez-minicart-wraper ${this.props.colData}`}>
        <div className="show-cart" onClick={this.toggleShowCart(true)}>
          <span className="cart-text">
            <span className="cart-title">{this.i18n.t('miniCart.cart')}</span>
            <span className="items-counter">{totalItems}</span>
          </span>
        </div>
        <div className={`cart-content ${ (showCart) ? 'active' : ''} ${(numProducts==0) ? 'empty-cart' : ''}`} id="minicart-content-wrapper">
          <div className="cart-block">
            <div className="close-icon" onClick={this.toggleShowCart(false)}></div>
            <div className="minicart-section head">
              <MiniCartHeader />
              {
                (numProducts > 0) &&
                <FreeShippingStatusBar cartTotal={cartData.subtotal} />
              }
            </div>
            {(numProducts) ?
              <>
                <div className="minicart-section products" id="products-section">
                  <ul className="product-items">
                  {
                    products.map((product,index)=>{
                      product['currency'] = storeConfiguration.currencySymbol;
                      return(
                        <li key={index} className="product-item">
                          <CartProductItem
                            key={index}
                            data={product}
                          />
                        </li>
                      )
                    })

                  }
                  </ul>
                </div>
                <div className="minicart-section totals">
                  <MiniCartTotals/>
                </div>
                <div className="minicart-section go-to-checkout">
                  <div className="minicart-actions">
                      <CheckoutButton className="go-to-checkout-button" />
                  </div>
                </div>
              </>
              :
              <>
                <p className="ez-empty-minicart">{this.i18n.t('miniCart.noItems')}</p>
              </>
            }
            {
              (crossSellCategory && _get(crossSellCategory,'items') && _get(crossSellCategory,'items').length) &&
              <div className="minicart-section cross-sell">
                <p className="cross-sell-title">{_get(crossSellCategory,'name')}</p>
                <ProductList
                  itemClassName="minicart-cross-sell-item"
                  products={_get(crossSellCategory,'items')}
                  wishlistButtonEnable={false}
                  wishlistItems={wishlistItems || []}
                  showRating={false}
                  loading={false}
                  hasMoreProducts={false}
                  loadMore={() => {}}
                  isMobile={isMobile}
                  category={crossSellCategory}
                  isCrossSellCategory={true}
                  />
              </div>
            }
          </div>
        </div>
      </div>
    );
  }
}
function mapStateToProps(state){
  return {
    cartData:           state.minicart.cart,
    user:               state.user,
    storeConfiguration: state.storeConfig,
    wishlistItems:      state.user.wishlist_items,
    isMobile:           state.isMobile,
    activeSection:      state.theme.activeSection,
    crossSellCategory:  state.minicart.crossSellCategory
  }
}

export default connect(mapStateToProps, {setCart,setActiveSection,setActiveTheme})(MiniCart);
