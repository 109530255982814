import EzComponent              from '@frontend/components/EzComponent';
import { connect }              from 'react-redux';
import { signOut }				from '@frontend/actions/user';
import { openSideBar,
			closeSideBar }		from '@frontend/actions/side-bar';
import ContactInfo          		from '@frontend/components/my-account/me/ContactInfo'
import AddressesInfo        		from '@frontend/components/my-account/me/AddressesInfo'

import '@frontend/components/side-bar/sections/my-account/index.less';

class SideBarMyAccount extends EzComponent{
	closeSideBar = ()=>{
		this.props.closeSideBar();
	}
	openEditCustomer = ()=>{
		this.props.openSideBar('edit-customer',['my-account']);
	}
	openAddressBook = () =>{
		this.props.openSideBar('address-book',['my-account']);
	}
	openMyOrders = () =>{
		this.props.openSideBar('my-orders',['my-account']);
	}
	signOut = async () =>{
		const { storeConfiguration } = this.props;
		await this.props.signOut(storeConfiguration);
		this.closeSideBar();
	}
	render() {
		const { user } = this.props;
		if (user && user.id){
			return (	
				<div className="my-account-content">
					<div className="welcome">
						<h1>{this.i18n.t('user.myAccount.welcome',[user.firstname])}</h1>
					</div>
					<div className="customer-info">
						<div className="customer-info-section">
							<div className="section-header col-group">
								<span className="section-title col-11 col-mb-10">{this.i18n.t('user.myAccount.accountInfo')}</span>
							</div>
							<ContactInfo user={user}/>
						</div>
						<div className="customer-info-section default-addresses-info">
							<AddressesInfo   user= {user}/>
						</div>
					</div>
					<div className="my-account-actions">
						<span className="my-account-action" onClick={this.signOut}>{this.i18n.t('user.myAccount.signOut')}</span>
						{/* <span className="my-account-action" onClick={this.openEditCustomer}>{this.i18n.t('user.myAccount.editCustomer')}</span>
						<span className="my-account-action" onClick={this.openAddressBook}>{this.i18n.t('user.myAccount.addressInfo')}</span> */}
						<span className="my-account-action" onClick={this.openMyOrders}>{this.i18n.t('user.myAccount.myOrders')}</span>
					</div>
				</div>
			);
		}else{
			return null;
		}
	}
  
}
function mapStateToProps({user,storeConfig}){
	return {
		user,
		storeConfiguration: storeConfig
	}
}
export default connect(mapStateToProps,{signOut,openSideBar,closeSideBar})(SideBarMyAccount);