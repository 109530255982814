import EzComponent          from '@frontend/components/EzComponent';

import Link                 from 'next/link';
import { connect }          from 'react-redux'
import StarRatings          from 'react-star-ratings';
import _find                from 'lodash/find';
import _get                 from 'lodash/get';

import GalleryCarousel      from '@frontend/components/product/gallery/GalleryCarousel';
import Swatches             from '@frontend/components/product-swatches/Swatches';
import AddToCartButton      from '@frontend/components/buttons/add-to-cart-button/AddToCartButton'
import WishlistButton       from '@frontend/components/buttons/wishlist/WishlistButton'
import ProductPrice         from '@frontend/components/product/price/ProductPrice'

import '@frontend/components/product-list/product-item/product-list-item.less';

export class ProductListItem extends EzComponent{
  state = {
    selected_options:   [],
    selectedProductId:  ''
  }
  getProductListMediaGallery = (media_gallery)=>{
    const { storeConfiguration:{galleryTypes}, product:{is_configurable} }   = this.props;
    const { selectedProductId }                   = this.state;

    if(media_gallery && media_gallery.length){
      let gallery         = JSON.parse(media_gallery);
      let productGallery  = JSON.parse(gallery['productGallery']);

      if(selectedProductId && is_configurable && gallery['childGallery'] && gallery['childGallery'][selectedProductId]){
        productGallery = JSON.parse(gallery['childGallery'][selectedProductId]);
      }

      return (productGallery[galleryTypes['category_view_list']]) ?
                productGallery[galleryTypes['category_view_list']]
              : [];
    }
  }

  getChildProductId = (selectedOptions) =>{
    const { product_options } = this.props.product;
    const productsArray = selectedOptions.map(selectedOption =>{
      const attribute       = _find(product_options,{'option_id':selectedOption.option_id});
      const attributeOption = _find(attribute.values,{'value_id':String(selectedOption.option_value)});
      const prod            = attributeOption ? attributeOption.products:'';

      return prod;
    });
    const selectedProduct = productsArray.reduce((a,b) => a.filter(c =>b.includes(c)));
    const productId       = (selectedProduct.length === 1) ? selectedProduct[0] : '';

    return productId;
  }

  addSelectedOption = (option)=>{
    let { selected_options } = this.state;
    this.setState(this.insertSelectedOption(option));
  }
  getPrices = (prices) =>{
    const { selectedProductId } = this.state;
    const { product:{product_childs,is_configurable} } = this.props;
    if (selectedProductId && is_configurable && product_childs && product_childs.length){
      prices = (JSON.parse(product_childs)[selectedProductId]) ? JSON.parse(product_childs)[selectedProductId].prices: prices;
    }

    return prices;
  }
  insertSelectedOption = (option) => {
    return (previousState, currentProps) => {
      let { selected_options } = previousState;
      if (!_get(selected_options,'length')) return {...previousState}
      selected_options = selected_options.filter((item)=>item.option_id!==option.option_id);
      selected_options.push(option);
      const selectedProductId  = this.getChildProductId(selected_options);
      return { ...previousState, selected_options,selectedProductId};
    };
  }
  getProductPath = (product) => {
    return ( product ) ? "/"+product.path + ".html": "";
  }

  dispatchOnCategoryClick(){
    const {product} = this.props;
    this.context.scriptManager.dispatch("SelectItem", {
      item_list_id:   "category_list", 
      item_list_name: "Category List", 
      product
    });
  }

  render(){
    let {
      product,
      wishlistButtonEnable,
      showRating,
      className=''
     }                          = this.props;
    const { selected_options }  = this.state;
    const ratingValue           = (product.rating_summary*5)/100;
    const product_path          = this.getProductPath();
    if ( typeof product.product_options === 'string' ){
      product.product_options = JSON.parse(product.product_options);
    }
    if ( product ){

      let {
        product_id,
        path,
        media_gallery,
        media_gallery:original_media_gallery,
        name,
        product_options,
        prices,
        is_configurable
      }               = product;

      const {
        regular_price,
        final_price,
        tier_price
      }               = this.getPrices(prices);
      media_gallery   = this.getProductListMediaGallery(media_gallery)

      return (
        <li className={`ez-product-item ${className}`}>
          <div className="product-container">
            <div className="product-gallery-container">
              {
                wishlistButtonEnable &&
                <WishlistButton productId={product.product_id} onClickWishlist={this.props.onClickWishlist} />
              }
                <div>
                  <GalleryCarousel
                    galleryEntries={media_gallery}
                    productName={product.name}
                    imageLinkEnabled={true}
                    {...this.router.get('product',{id:product_id,slug:product_path})}
                  />
                </div>
            </div>
            <div className="product-details">
              <div className="product-name-box" onClick={this.dispatchOnCategoryClick.bind(this)}>
                <Link {...this.router.get('product',{id:product_id,slug:product_path})}><a><span>{product.name}</span></a></Link>
              </div>
              <ProductPrice
                regular_price={regular_price}
                final_price ={final_price}
                tier_price={tier_price}
              />
              {
                (showRating) &&
                <StarRatings
                  rating={ratingValue}
                  starRatedColor="black"
                  numberOfStars={5}
                  name="summary-rating"
                  starSpacing="1px"
                  starDimension="14px"
                />
              }
            </div>
            <div className="product-actions">
              {
                (is_configurable) &&
                <Swatches product={product} productId= {product_id} options={product.product_options} onOptionSelected={this.addSelectedOption} mediaGallery = {original_media_gallery}/>
              }
              <AddToCartButton item={product} selected_options={selected_options} qty={1}   />
            </div>
          </div>
        </li>
      );
    }else{
      return null;
    }

  }
}

function mapStateToProps(state){
  return { storeConfiguration:state.storeConfig }
}

export default connect(mapStateToProps)(ProductListItem);
