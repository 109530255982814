import EzComponent          		  from '@frontend/components/EzComponent';
import Link                 		  from 'next/link';
import { closeAddToCartModal }   	from '@frontend/actions/add-to-cart-modal';

import ReducedModal 				      from '@frontend/components/basic-components/reduced-modal';
import SwatchItem                 from '@frontend/components/product-swatches/SwatchItem';
import { openCart }               from '@frontend/actions';

import { connect }          		  from 'react-redux'


import '@frontend/components/buttons/add-to-cart-button/add-to-cart-modal/index.less';

class AddToCartModal extends EzComponent{
	closeModal = ()=>{
    this.props.closeAddToCartModal();
  }
  openCart = ()=>{
    const { isMobile } = this.props;
    this.closeModal();
    this.props.openCart();
  }
  roundUp = (num, precision) =>{
    precision = Math.pow(10, precision);
    let result = Math.ceil(num * precision) / precision
    return result.toFixed(2);
  }
	render(){
    const { addToCartModal:{productName,finalPrice},currencySymbol,isMobile } = this.props;
    let formattedPrice = this.roundUp(finalPrice,2);
		return (
			<ReducedModal className="add-to-cart-modal" closeModal={this.closeModal}>
        <div className="item-added">
            <span>{this.i18n.t('miniCart.itemAdded')}</span>
        </div>
        <div className="product-info">
            <div className="product-name">
            <span className="name">{productName}</span>
            </div>
            <div className="product-price">
            {formattedPrice} {currencySymbol}
            </div>
        </div>
        <div className="go-to-cart">
            {
              <span onClick={this.openCart} className="go-to-cart-button">{this.i18n.t('miniCart.goToCart')}</span>
            }
        </div>
       </ReducedModal>
		);
	}
}

function mapStateToProps ({isMobile,addToCartModal,storeConfig:{currencySymbol}}){
    return { addToCartModal,currencySymbol,isMobile };
}
export default connect(mapStateToProps,{closeAddToCartModal,openCart})(AddToCartModal);
