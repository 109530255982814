import EzComponent from '@frontend/components/EzComponent';
import VisibilitySensor from 'react-visibility-sensor';
import React, { Component } from 'react';

class Video extends EzComponent {

    vidRef = React.createRef();
    
    playVideo() {
        if (this.vidRef.current && this.vidRef.current.play) {
            this.vidRef.current.play();
        }
        
    }

    onChange(isVisible) {
        if (isVisible) {
            this.playVideo();
        }
    }

    render() {
        const {
            autoplay,
            style,
            loop,
            muted,
            src,
            className
        } = this.props;
        return (
            <>
                <VisibilitySensor onChange={this.onChange.bind(this)}>
                    <span className="visibility-check"></span>
                </VisibilitySensor>
                <video
                    className={className}
                    autoPlay={autoplay}
                    loop={loop}
                    muted={muted}
                    ref={this.vidRef}
                    src={src}
                    style={{height: "100%"}}
                    playsInline
                    >

                </video>
            </>
        )
    }
}

export default Video;
