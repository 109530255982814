import EzComponent          		from '@frontend/components/EzComponent';
import Link                 		from 'next/link';
import { closeSwatchesModal }   	from '@frontend/actions/swatches-modal';

import ReducedModal 				from '@frontend/components/basic-components/reduced-modal';
import SwatchItem                   from '@frontend/components/product-swatches/SwatchItem';

import { connect }          		from 'react-redux'


import '@frontend/components/product-swatches/swatches-modal/index.less';
import '@frontend/components/product-swatches/swatch.less';

class SwatchesModal extends EzComponent{
	closeModal = ()=>{
		this.props.closeSwatchesModal();
	}
	render(){
        const { option, product, onOptionSelected, setParentSwatchState, selectedValue } = this.props.swatchesModal;
		return (
			<ReducedModal className="swatches-modal" closeModal={this.closeModal}>
                <div className={`ez-swatches`}>
                    <SwatchItem 
                        option={option}
                        product={product}
                        onOptionSelected={onOptionSelected}
                        setParentSwatchState={setParentSwatchState}
                        selectedValue={selectedValue}
                        showAll={true}
                    />
                </div>
			</ReducedModal>
		);
	}
}

function mapStateToProps ({swatchesModal}){
    return {swatchesModal};
}
export default connect(mapStateToProps,{closeSwatchesModal})(SwatchesModal);
