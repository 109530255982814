import EzComponent          from '@frontend/components/EzComponent';
import { connect }          from 'react-redux'

import { addItemToCart }    from '@frontend/actions';

import '@frontend/components/buttons/add-to-cart-button/add-to-cart-button.less';

export class AddToCartButton extends EzComponent {
  addItemToCart = ()=>{
    const { item , qty, selected_options,cartData, user,storeConfiguration } = this.props;
    let product_option = {};
    if (item.is_configurable){
      let configurable_item_options=[];
      selected_options.map((option)=>{
        configurable_item_options.push(option)
      });
      product_option['extension_attributes']={
        configurable_item_options
      }
    }
    let variables = {
      sku: item.sku,
      qty: qty,
      storeId:(storeConfiguration.storeCode) ? parseInt(storeConfiguration.storeId) : 0,
      storeCode:(storeConfiguration.storeCode) ? storeConfiguration.storeCode : 'default',
      quoteId: (cartData.quote_id) ? cartData.quote_id: '',
      customerToken: (user.token) ? user.token : '',
      customerId: (user.id) ? user.id : 0,
      product_option
    }
    this.props.addItemToCart(this.context,variables);
  }


  render(){
    const { className,enabled=true } = this.props;

    return (
      <div className={`add-to-cart-actions ${className}`}>
        <button className="add-to-cart-button" onClick={this.addItemToCart} disabled={!enabled}>{this.i18n.t('addToCart')}</button>
      </div>
    )
  }
}
function mapStateToProps(state){
  return {
    cartData: state.minicart.cart,
    user: state.user,
    storeConfiguration:state.storeConfig
  }
}
export default connect(mapStateToProps, {addItemToCart})(AddToCartButton);
