import React     from 'react';
import useScroll from '@frontend/hooks/useScroll';

export const withScroll = (Component) => {
  return (props) => {
    const scrollData = useScroll(Component);

    return <Component scrollData={scrollData} {...props} />;
  };
};
