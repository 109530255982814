import EzComponent              from '@frontend/components/EzComponent';
import CreateAccountBox       	from '@frontend/components/create-account/CreateAccountBox'

import '@frontend/components/side-bar/sections/create-account/index.less';

class SideBarCreateAccount extends EzComponent{
	render() {
		return (	
			<div className="create-account-content">
				<div className="section-title">
					<span>{this.i18n.t('user.createAccount.pageTitle')}</span>
				</div>
				<div className="section-description">
					<span>{this.i18n.t('user.createAccount.pageDescription')}</span>
				</div>
				<CreateAccountBox />
			</div>
		);
	}
  
}
export default SideBarCreateAccount;
  