import EzComponent          from '@frontend/components/EzComponent';
import { connect }          from 'react-redux';
import Link                 from 'next/link';
import _unset               from 'lodash/unset';
import Cookies              from 'nookies';
import cookie               from 'cookie';

import { setUser,
          updateUser }      from '@frontend/actions';
import updateCustomerData   from '@frontend/services/mutations/updateCustomerData.graphql'


import '@frontend/components/my-account/addresses/address.less'

export class Address extends EzComponent {
  state = {
    storeCountries:{},
    user:{},
    storeConfiguration:{}
  }

  getCompleteStreet = (street=[]) =>{
    let finalStreet = '';
    street.map(fragment=>{
      finalStreet += fragment + " ";

    });
    return finalStreet;
  }

  deleteAddress = ()=>{
    const { user, user:{ token } } = this.props;
    const { id } = this.props.address;
    let userData = user;
    let newAddresses = [];
    userData.addresses.map(address=>{
      if(address.id!=id){
        address.custom_attributes = (address.custom_attributes) ? address.custom_attributes: [];
        newAddresses.push(address);
      }
    });
    user.addresses = newAddresses;
    _unset(userData,'token');
    _unset(userData,'error');
    _unset(userData,'orders');
    _unset(userData,'wishlist_items');
    _unset(userData,'isSignedIn');

    let variables = {
      token,
      customer:userData
    };
    this.props.updateUser(this.context,variables);
  }
  getCountryName = (countryId) =>{
    const { storeCountries:{ countriesData } } = this.props;
    let countryName = countryId;
    countriesData.map(country=>{
      if(country.countryCode == countryId){
        countryName = country.countryName;
      }
    })
    return countryName;
  }

  render(){
    const { className,address={},showActions=true } = this.props;

    const region = (address && typeof(address.region)=='string') ? address.region : (address && typeof(address.region) != 'undefined' && typeof(address.region)=='string') ? address.region.region : '';
    const street = (address && typeof(address.street)=='string') ? address.street : this.getCompleteStreet(address.street);
    const country = this.getCountryName(address.country_id);
    return (
      <div className={`address-wrapper ${className}`}>
        <div className="customer-name">
          <span>{`${address.firstname} ${address.lastname}`}</span>
        </div>
        <div className="street">
          <span>{street}</span>
        </div>
        <div className="city-info">
          <span>{`${address.city}, ${region}, ${address.postcode}`}</span>
        </div>
        <div className="country"><span>{this.i18n.t(`countries.${country}`)}</span></div>
        <div className="telephone">
          <span>{`T: ${address.telephone}`}</span>
        </div>
        {
          showActions &&
          <div className="address-actions">
            <Link {...this.router.get('myAccountEditAddress',{ id: address.id })}><span className="edit-address-button"></span></Link>
            <span onClick={this.deleteAddress} className="delete-address-button"></span>
          </div>
        }
      </div>
    );
  }
}
function mapStateToProps(state){
  return {
    storeConfiguration:state.storeConfig,
    storeCountries:state.storeCountries,
    user: state.user
  };
}
export default connect(mapStateToProps,{setUser,updateUser})(Address);
