import EzComponent              from '@frontend/components/EzComponent';


import '@frontend/components/side-bar/sections/new-account/index.less';

class SideBarNewAccount extends EzComponent{
	render() {
		return (	
            <div className="new-account">
                <div className="thank-you">
                    <p className="first">
                        {this.i18n.t('thanks.part1')}
                    </p>
                    <p className="second">
                        {this.i18n.t('thanks.part2')}
                    </p>
                </div>
                <div className="success">
                    {this.i18n.t('user.createAccount.success')}
                </div>
            </div>
        );
	}
  
}

export default SideBarNewAccount;