import {
  HtmlParser as MagentoParser }         from '@themes/magento/frontend/components/HtmlParser';

import dynamic from 'next/dynamic'

const FooterNewsletter                 = dynamic(()=> import('@frontend/components/newsletter/FooterNewsletter'));
const Mineral                          = dynamic(()=> import('@frontend/components/product/static-tables/Mineral'));
const Vitamine                         = dynamic(()=> import('@frontend/components/product/static-tables/Vitamine'));
const DoseIconImage                    = dynamic(()=> import('@frontend/components/product/dose-icon-image/DoseIconImage'));
const GraphDrillDown                   = dynamic(()=> import('@frontend/components/product/graphics/GraphDrillDown'));
const InformativeIndex                 = dynamic(()=> import('@frontend/components/cms/informative/index/index'));
const InformativeIndexElement          = dynamic(()=> import('@frontend/components/cms/informative/index/element'));
const InformativeContainer             = dynamic(()=> import('@frontend/components/cms/informative/container'));
const InformativeBlock                 = dynamic(()=> import('@frontend/components/cms/informative/block'));
const InformativeBlockElement          = dynamic(()=> import('@frontend/components/cms/informative/block/element'));
const CmsLink                          = dynamic(()=> import('@frontend/components/cms/link'));
const CapitalizedTitle                 = dynamic(()=> import('@frontend/components/cms/capitalized-title'));
const Newsletter                       = dynamic(()=> import('@frontend/components/newsletter/Newsletter'));
const RscNewsletter                    = dynamic(()=> import('@frontend/components/newsletter/RscNewsletter'));
const Collapsible                      = dynamic(()=> import('@frontend/components/basic-components/block/Collapsible'));
const GoogleMap                        = dynamic(()=> import('@frontend/components/map/GoogleMap'));
const StageContainer                   = dynamic(()=> import('@frontend/components/stage-container'));
const ScrollSnapping                   = dynamic(()=> import('@frontend/components/scroll-snapping'));
const TribeForm                        = dynamic(()=> import('@frontend/components/tribe-form'));
const ChangeTheme                      = dynamic(()=> import('@frontend/components/change-theme'));
const ChatButton                       = dynamic(()=> import('@frontend/components/basic-components/chat-button'));
const MobileParser                     = dynamic(()=> import('@frontend/components/basic-components/mobile-parser'));
const DesktopParser                    = dynamic(()=> import('@frontend/components/basic-components/desktop-parser'));
const HomeCollection                   = dynamic(()=> import('@frontend/components/home-collection'));
const Look                             = dynamic(()=> import('@frontend/components/look'));
const SafeBuy                          = dynamic(()=> import('@frontend/components/safe-buy'));
const Video                            = dynamic(()=> import('@frontend/components/basic-components/video'));
const ImageVideoPlay                   = dynamic(()=> import('@frontend/components/cms/image-video-play'));
const DownCount                        = dynamic(()=> import('@frontend/components/basic-components/down-count'));
const RetailContainer                  = dynamic(()=> import('@frontend/components/third-party-scripts/misc/retail/RetailContainer'));
const StoreSelector                    = dynamic(()=> import('@frontend/components/store-selector'));
const AppointmentForm                  = dynamic(()=> import('@frontend/components/appointment-form'));



export class HtmlParser extends MagentoParser{
  static getComponentObjects(){
    const magentoComponents = super.getComponentObjects();

    return {
      ...magentoComponents,
      "footer-newsletter" :         FooterNewsletter,
      "table-mineral":              Mineral,
      "table-vitamine":             Vitamine,
      "dose-icon-image":            DoseIconImage,
      "graph-drilldown":            GraphDrillDown,
      "informative-index":          InformativeIndex,
      "informative-container":      InformativeContainer,
      "downcount":                  DownCount,
      "informative-block":          InformativeBlock,
      "info-index-element":         InformativeIndexElement,
      "info-block-element":         InformativeBlockElement,
      "cms-link":                   CmsLink,
      "capitalized-title":          CapitalizedTitle,
      "newsletter":                 Newsletter,
      "rsc-newsletter":             RscNewsletter,
      "image-video-play":           ImageVideoPlay,
      "video":                      Video,
      // "main-image-section":         MainImageSection,
      "collapsible-block":          Collapsible,
      "google-map":                 GoogleMap,
      "stage-container":            StageContainer,
      "tribe-form":                 TribeForm,
      "change-theme":               ChangeTheme,
      "scroll-snapping":            ScrollSnapping,
      "chat-button":                ChatButton,
      "mobile-parser":              MobileParser,
      "desktop-parser":             DesktopParser,
      "home-collection":            HomeCollection,
      "look":                       Look,
      "safe-buy":                   SafeBuy,
      "retail-container":           RetailContainer,
      "store-selector":             StoreSelector,
      "appointment-form":           AppointmentForm
    };
  }
}
export default HtmlParser;
