import EzComponent                  from '@frontend/components/EzComponent';
import Link                         from 'next/link';
import { connect }                  from 'react-redux'
import { signOut }                  from '@frontend/actions'
import nookies                      from 'nookies';

import '@frontend/components/accountbanner/accountbanner.less';

class AccountBanner extends EzComponent{
  state = {
    isSignedIn: false,
    storeConfiguration:{}
  }

  handleSignOut=()=>{
    this.props.signOut();
  }

  render(){
    const {
      isSignedIn,
      storeConfiguration:{
        shopInfo:{shop_name}
      },
      user,
      loggedInUser }   = this.props;
    const userEmail               = (isSignedIn&& user) ? user.email : '';

    return(
      <div className="ez-account-banner row">
        <div className={`ez-shop-name col-${(isSignedIn)? "6":"9"}`}><span>{shop_name}</span></div>
        {
          (isSignedIn) ?
          <div className={`ez-account-content col-${(isSignedIn)? "6":"3"} row`}>
            <span className="email col-4">{userEmail}</span>
            <Link {...this.router.get('myAccountMe')}><a><span className="banner-link col-2">{this.i18n.t('accountBanner.myAccount')}</span></a></Link>
            <span onClick={this.handleSignOut} className="banner-link signout-button col-3">{this.i18n.t('accountBanner.logOut')}</span>
          </div>
          :
          <div className="ez-account-content col-3">
            <Link {...this.router.get('createAccount')}><a><span className="banner-link col-5">{this.i18n.t('accountBanner.createAccount')}</span></a></Link>
            <Link {...this.router.get('signin')}><a><span className="banner-link col-5">{this.i18n.t('accountBanner.logIn')}</span></a></Link>
          </div>
        }
      </div>
    );
  }
}
function mapStateToProps({user,storeConfig}){
  return {
    user:               user.user,
    isSignedIn:         user.isSignedIn,
    storeConfiguration: storeConfig
  }
}
export default connect(mapStateToProps,{signOut})(AccountBanner);
