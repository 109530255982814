import EzComponent                from '@frontend/components/EzComponent';
import Head                       from 'next/head';
import { connect }                from 'react-redux';

import { setHeaderHeight,
  setHeaderHeightWithMarquee }    from '@frontend/actions/header';

import Icon                       from '@frontend/components/header/Icon';
import SearchBar                  from '@frontend/components/searchbar/SearchBar';

import '@frontend/components/header/header.less';

const CHANGE_OFFSET = 5;
export class Header extends EzComponent {
  state = {
    showMobileMenu: false
  }

  showMobileMenu = ()=>{
    this.setState({showMobileMenu:true});
  }
  closeMobileMenu = ()=>{
    this.setState({showMobileMenu:false});
  }
  trackPosition(element){
    if(element){
      const bodyRect     = document.body.getBoundingClientRect();
      const elemRect     = element.getBoundingClientRect();
      const headerHeight = element.offsetHeight;
      if(!this.props.header || !this.props.header.height || (headerHeight != this.props.header.height) && Math.abs(headerHeight - this.props.header.height) > CHANGE_OFFSET){
        this.props.setHeaderHeight(headerHeight);
      }
    }
  }
  render() {
    const { showMobileMenu }  = this.state;
    const { search,isMobile } = this.props;

    return (
      <div ref={this.trackPosition.bind(this)} >
      {
        (isMobile && showMobileMenu) &&
        <div className="ez-navigation-menu">
          <div className="ez-navigation">
            <span className="ez-navigation-menu-title">{this.i18n.t('navigationMenu.title')}</span>
          {
              // menu
          }
          </div>
          <div onClick={this.closeMobileMenu} className="close-navigation">
            <button><span>&#x2715;</span></button>
          </div>
        </div>

      }
      <div className='ez-header'>
        <Head/>
        <div className= "ez-top-pannel">
          {
            // header banner
          }
        </div>
        <div className="ez-menu-mobile-icon col-sm-2" onClick={this.showMobileMenu}>
          <span className="toggle-nav"></span>
        </div>
        <div className="ez-header-menu row">
          {
          (!isMobile) &&
            <div className="ez-navigation col-8 col-sm-0">
              {
                // menu
              }
            </div>
          }
          <div className="ez-header-search-cart col-4 col-sm-12 row">
            <SearchBar colData="col-md-6" search={search} />
          </div>
        </div>
      </div>
    </div>
    );
  }
}
function mapStateToProps ({header,isMobile}){
  return {header,isMobile};
}
export default connect(mapStateToProps,{setHeaderHeight,setHeaderHeightWithMarquee})(Header);
