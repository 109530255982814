import EzComponent          from '@frontend/components/EzComponent';
import dynamic              from 'next/dynamic';
import { LazyLoadImage }    from 'react-lazy-load-image-component';

const Slider                 = dynamic(()=> import("react-slick")) ;


import "@frontend/components/product/gallery/carousel.less";
import '@frontend/components/product/gallery/gallery.less';

export class GalleryCarousel extends EzComponent {
  render(){
      const {
        galleryEntries,
        showThumbs=false,
        showStatus=false,
        showIndicators=true,
        isMobile
      } = this.props;
      const axis = "horizontal";
      const showArrows = false;
      var settings = {
        dots: true,
        vertical:true,
        verticalSwiping: true,
        dotsClass: 'product-view-dots'
      };


      if (!galleryEntries) {
          return null;
      }else{
        if (!isMobile) {
          return (
            <Slider {...settings}>
              {
                galleryEntries.map((item, i) =>{
                  if (item.includes("placeholder")) {
                    item = "/static/images/cat-placeholder.jpg"
                  }
                  return(
                    <div key={i}>

                      <div className="product-item-image-bg" style={{ backgroundImage: "url('" + this.router.webpUrl(item) + "')" }}></div>

                    </div>
                  )
                }
                )
              }
              </Slider>
          );
        } else {
          settings.vertical = false;
          settings.verticalSwiping = false;
          return (

            <Slider {...settings}>
              {
                galleryEntries.map((item, i) => {
                  if (item.includes("placeholder")) {
                    item = "/static/images/cat-placeholder.jpg"
                  }
                  return (
                    <div key={i}>
                      <LazyLoadImage
                          className="product-item-image"
                          src={this.router.webpUrl(item)}
                          alt={this.props.productName}
                      />
                    </div>
                  )
                }
                )
              }
            </Slider>
          )
        }

      }
  };
} export default GalleryCarousel;
