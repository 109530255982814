import EzComponent       from "@frontend/components/EzComponent";

class EzPublicPage extends EzComponent{
    static async getInitialProps(ctx){
        ctx.scriptManager.dispatch("PublicPage",this.getMetadata());
        return {};
    }

    static getMetadata(){
        return {
            title: "",
            meta_title: "",
            meta_description: "",
            meta_keywords: ""/*,
            url_slug: ""*/
        }
    };
}

export default EzPublicPage;
