export const openAddToCartModal = (productName,finalPrice) => {
    return {
      type: "OPEN_ADD_TO_CART_MODAL",
      productName,
      finalPrice
    }
  }
  
  export const closeAddToCartModal = () => {
    return {
      type: "CLOSE_ADD_TO_CART_MODAL"
    }
  }
  