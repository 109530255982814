import { AddToCartButton as
        StoreAddToCartButton }          from '@themes/store/frontend/components/buttons/add-to-cart-button/AddToCartButton';
import { connect }          from 'react-redux'
import ReducedModal 				from '@frontend/components/basic-components/reduced-modal';

import { addItemToCart }    from '@frontend/actions';
import { openCart }                   from '@frontend/actions';
import { openAddToCartModal } from '@frontend/actions/add-to-cart-modal';

import '@frontend/components/buttons/add-to-cart-button/add-to-cart-button.less';

export class AddToCartButton extends StoreAddToCartButton {
  
  addItemToCart = async ()=>{
    const { item , qty, selected_options,cartData, user,storeConfiguration,final_price } = this.props;
    let product_option = {};
    let bundle_option  = {};
    if (item.is_configurable){
      let configurable_item_options=[];
      selected_options.map((option)=>{
        configurable_item_options.push(option)
      });
      product_option['extension_attributes']={
        configurable_item_options
      }
    } else {
      // BUNDLE
      if (selected_options.extension_attributes && selected_options.extension_attributes.bundle_options.length) {
        product_option = selected_options
      }
    }
    
    let variables = {
      sku: item.sku,
      qty: qty,
      storeId:(storeConfiguration.storeCode) ? parseInt(storeConfiguration.storeId) : 0,
      storeCode:(storeConfiguration.storeCode) ? storeConfiguration.storeCode : 'default',
      quoteId: (cartData.quote_id) ? cartData.quote_id: '',
      customerToken: (user.token) ? user.token : '',
      customerId: (user.id) ? user.id : 0,
      product_option,
      customerGroup: (user.id) ? user.group_id : 0,
      enableCrossSellProducts: storeConfiguration. enableMinicartCrossSell
    }

    const result =  await this.props.addItemToCart(this.context,variables);
   
    if (result){
      this.props.openAddToCartModal(item.name,final_price);
    }
  }


  render(){
    const { className,enabled=true,buttonLabel=null } = this.props;
    const label = buttonLabel ? buttonLabel : this.i18n.t('addToCart');
    return (
      <>
        <div className={`add-to-cart-actions ${className}`}>
          <button className="add-to-cart-button" onClick={this.addItemToCart} disabled={!enabled}>{label}</button>
        </div>
      </>
    )
  }
}

function mapStateToProps(state){
  return {
    cartData: state.minicart.cart,
    user: state.user,
    storeConfiguration:state.storeConfig,
    isMobile: state.isMobile
  }
}
export default connect(mapStateToProps, {addItemToCart,openCart,openAddToCartModal})(AddToCartButton);
