import { ProductPrice as
          StoreProductPrice }   from '@themes/store/frontend/components/product/price/ProductPrice';
import { connect }              from 'react-redux'

import '@frontend/components/product/price/product-price.less';

export class ProductPrice extends StoreProductPrice{

  roundDown = (num,precission) => {
    // let result =  Math.floor(num * 100) / 100;
    return parseFloat(num).toFixed(precission);
  }

  render(){
    const { regular_price,final_price,tier_price,className='' } = this.props;
    const currency                                 = this.props.storeConfiguration.currencySymbol;
    const renderRegular                            = this.haveSpecialPrice();
    const price                                    = (tier_price && tier_price<final_price) ? tier_price : final_price;

    return (

      <div className={`product-price-box`}>
        {
          renderRegular &&
          <span className="product-regular-price">{`${this.roundDown(regular_price,2)}${currency}`}</span>
        }
        <span className={`product-final-price ${renderRegular ? 'discount' : ''}`}>{`${this.roundDown(price,2)}${currency}`}</span>
      </div>
    );
  }
}
function mapStateToProps(state){
  return {storeConfiguration:state.storeConfig}
}
export default connect(mapStateToProps)(ProductPrice);
