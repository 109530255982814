import {EditCustomerForm as
        EditCustomerFormStore }           from '@themes/store/frontend/components/my-account/me/EditCustomerForm';
import moment                             from 'moment';
import { connect }                        from 'react-redux'
import { updateUser }                     from '@frontend/actions';

export class EditCustomerForm extends EditCustomerFormStore{
  state = {
    user: null,
    formData: {
      firstname:        { field:'firstname',       type:'text',     value:'', validation:'lettersWithSpaces,notEmpty',        required:true },
      lastname:         { field:'lastname',        type:'text',     value:'', validation:'lettersWithSpaces,notEmpty',        required:true },
      dob:              { field:'dob',        type:'date',     value:'', validation:'',                                  required:false },
      gender:           { field:'gender',          type:'select',   value:'', validation:'',                                  required:false,   focused:false },
      taxvat:           { field:'taxvat',             type:'text',     value:'', validation:'notEmpty',                          required:true }
    },
    selectValues:{
      gender: [{value:1,label:this.i18n.t('genders.male')},{value:2,label:this.i18n.t('genders.female')}]
    },
    error:[]
  }
  componentDidMount () {
    const { user } = this.props;
    let { formData } = this.state;
    if (user && user.id){
      Object.keys(formData).map(key=>{
        if (key=='dob' && user[key]){
          let dob = moment(user[key],'YYYY-MM-DD');
          formData[key]['value'] = dob.format('DD/MM/YYYY');
        }
        else if(user[key]){
          formData[key]['value'] = user[key];
        }else if(user['custom_attributes']&&user['custom_attributes'].filter(item=>item.attribute_code==key).length){
          formData[key]['value'] = user['custom_attributes'].filter(item=>item.attribute_code==key)[0]['value'];
        }
      });
      this.setState({formData});
    }
  }
  componentDidUpdate () {
    const { user } = this.props;
    let { formData } = this.state;
    let hasChanges = false;
    Object.keys(formData).map(key=>{
      if(user[key] && !formData[key]['value'].length && formData[key]['value']!=user[key]){
        if (key=='dob' && user[key] && !formData[key]['value'].length ){
          let dob = moment(user[key],'YYYY-MM-DD');
          if(formData[key]['value'] != dob.format('DD/MM/YYYY')){
            formData[key]['value'] = dob.format('DD/MM/YYYY');
            hasChanges = true;
          }
        }else{
          formData[key]['value'] = user[key];
          hasChanges = true;
        }
      }else if(user['custom_attributes']&&user['custom_attributes'].filter(item=>item.attribute_code==key).length){
        if (!formData[key]['value'].length &&
            formData[key]['value'] != user['custom_attributes'].filter(item=>item.attribute_code==key)[0]['value'] &&
            user['custom_attributes'].filter(item=>item.attribute_code==key)[0]['value'] != ' '
          ){
          formData[key]['value'] = user['custom_attributes'].filter(item=>item.attribute_code==key)[0]['value'];
          hasChanges = true;
        }
      }
    });
    if (hasChanges){
      this.setState({formData});
    }
  }
}
function mapStateToProps({user}){
  return {user};
}

export default connect(mapStateToProps,{updateUser})(EditCustomerForm);
