import {default as MagentoMegaMenuItem} from '@themes/magento/frontend/components/megamenus/MegaMenuItem';
import Link                             from 'next/link'
import { connect }                      from 'react-redux'
import {closeSideBar}                   from '@frontend/actions/side-bar';

class MegaMenuItem extends MagentoMegaMenuItem {

  state = {
    showChildren : false
  }

  getLinkData(type,url,category= null){
    const hrefArray = {
      'category' : 'category',
      'page'     : 'cmsPage',
      'custom'   : 'custom'
    };

    const linkType = hrefArray[type] || '';
    url            = url             || '';
    const id       = linkType === 'category' ? category : url;
    let linkData = this.router.get(linkType,{id,slug:url});
    // linkData.as += ".html";
    return linkData;
  }

  handleParentClick(){
    this.setState({showChildren: !this.state.showChildren});
  }
  hideSideBar= ()=>{
    if (this.props.closeSideBar){
      this.props.closeSideBar();
    }
    
  }
  render(){
    const { childrenActive } = this.state;
    let {level,data:{item_type,label,category=null,url,category_id=null,children=[],rel=null},storeConfiguration} = this.props;
    const childLevel = level + 1;
    const childrenData = (item_type=='category' && children.length) ? children :null;
    let suffix = (storeConfiguration && storeConfiguration.categoryUrlSuffix ) ? storeConfiguration.categoryUrlSuffix : '';
    url = (item_type=='category' && url) ? "/"+url + suffix : url;
    let cssClass = this.props.data.css_class;
    cssClass     = cssClass == undefined ? "" : cssClass;
    category_id = category_id || category;
    let showChildrenClass = this.state.showChildren ? "show-children" : "hide-children";
    item_type = item_type == "link" ? "custom" : item_type;
    let { href,as } = this.getLinkData(item_type,url,category_id);
    if (item_type == "category"&&!as.includes(".html")) {
      as = as+".html";
    }

    // if(label == "Maquillaje"){
    //   debugger;
    // }

    return (
      <li onClick={this.handleParentClick.bind(this)} className={`megamenu-item ${cssClass} ${showChildrenClass} level-${level} ${(childrenActive)? "active":""} ${!!childrenData ? 'have-children' : ''}`} data-has-children={!!childrenData}>
        {
          (url && item_type == 'category' && childrenData && childLevel == 1) &&
          <span onClick={this.handleParentClick.bind(this)} className={`item-name level-${level}`}><span className="item-name-text">{label}</span></span>
        }
        {
          (url && item_type == 'category' && !childrenData) &&
          <Link href={href} as={as}><a rel={rel ? rel : ""} className="item-name direct-cat" onClick={this.hideSideBar}>{label}</a></Link>
        }
        {
          (url && item_type == 'category' && childLevel >= 2 && childrenData) &&
          <Link href={href} as={as}><a rel={rel ? rel : ""} className="item-name direct-cat child-level-2" onClick={this.hideSideBar}>{label}</a></Link>
        }
        {
          (url && item_type != 'category') &&
          <Link href={href} as={as}><a className="item-name" onClick={this.hideSideBar}>{label}</a></Link>
        }
        {
          (!url && item_type == 'category') &&
          <span className="item-name">{label}</span>
        }
        {
          (childrenData && childLevel==1 ) &&
          <>
            <span className={`mobile-show-content ${(childrenActive)? "active":""}`} onClick={this.toggleChildrenActive}></span>

            <ul className={`item-category-content level-${childLevel} ${(childrenActive)? "active":""}`}>
            {
              (url && item_type == 'category' && childrenData.length >= 1) &&
              <Link href={href} as={as}><a rel={rel ? rel : ""} className="item-name see-all">{this.i18n.t(`navigationMenu.all_category`)}</a></Link>
            }
            {
              childrenData.map((child,index)=>{
                return (
                    <MegaMenuItem key={index} data={child} level={childLevel} storeConfiguration={storeConfiguration} closeSideBar={this.props.closeSideBar}/>
                )
              })
            }
            </ul>
          </>
        }
      </li>

    );
  }
}
function mapStateToProps ({storeConfig}){
  return { storeConfiguration:storeConfig};
}
export default connect(mapStateToProps, { closeSideBar})(MegaMenuItem);
