import {
  REGEX_LETTERS,
  REGEX_LETTERS_NUMBERS,
  REGEX_EMAIL,
  REGEX_PHONE,
  REGEX_LINE,
  REGEX_PASSWORD,
  REGEX_LETTERS_WITHSPACES,
  REGEX_LETTERS_NUMBERS_WITHSPACES,
  REGEX_NUMBERS,
  REGEX_FLOAT
}                                      from "@frontend/regex.js";

import moment                 from 'moment';



export function validateForm(formData){
  let isValid = true;
  let error   = {};

  Object.keys(formData).map((inputKey,i) => {
    const { value,validation,required=true } = formData[inputKey];
    if(validation&&required || validation&&value.length){
      const validationList = validation.split(',');
      const {isFieldValid,fieldError} = validateField(value,validationList);
      isValid = (isFieldValid) ? isValid : false;

      if(!isFieldValid && fieldError){
        error[inputKey] = fieldError;
      }
    }
    return true
  });

  return {isValid, error}
}

function validateField(value,validationList){
    let isFieldValid  = true;
    let fieldErrors   = validationList
      .map((rule,i) =>{
        const { isValid,errorMessage } = applyRule(String(value).trim(),rule);
        isFieldValid = (isValid) ? isFieldValid : false;
        return errorMessage;
      })
      .filter(Boolean);
    let fieldError = (fieldErrors.length) ? fieldErrors[0]  :"";

    return {isFieldValid,fieldError};
}


function applyRule(value,rule){
  const isValid =
    (rule === 'letters') ?
      IsLetters(value)
    : (rule === 'lettersWithSpaces') ?
      IsLettersWithSpaces(value)
    : (rule === 'lettersNumbers') ?
      IsLettersNumbers(value)
    : (rule === 'lettersNumbersWithSpaces') ?
      IsLettersNumbersWithSpaces(value)
    : (rule === 'validPassword') ?
      ValidPassword(value)
    : (rule === 'numbers') ?
      IsNumbers(value)
    : (rule === 'float') ?
      IsFloat(value)  
    : (rule === 'validDate(DD/MM/YYYY)') ?
      IsValidDate(value,'DD/MM/YYYY')
    : (rule === 'email') ?
      IsEmail(value)
    : (rule === 'phone') ?
      IsPhone(value)
    : (rule === 'notEmpty') ?
      IsNotEmpty(value)
    : (rule === 'notEmptyCaptcha') ?
      IsNotEmpty(value)
    :
      IsNotEmpty(value);

  const errorMessage = (!isValid) ? `validator.${rule}`: '';

  return {isValid,errorMessage};
}

function IsLetters(value) {
  return (value.match(REGEX_LETTERS) !== null);
}

function IsValidDate (value,format){
  if(value.length && value!="//"){
    const dateMoment = moment(value,format);
    return dateMoment.isValid();
  }
  return true;
}

function IsLettersWithSpaces(value) {
  return (value.match(REGEX_LETTERS_WITHSPACES) !== null);
}

function IsLettersNumbersWithSpaces(value) {
  return (value.match(REGEX_LETTERS_NUMBERS_WITHSPACES) !== null);
}

function IsLettersNumbers(value) {
  return (value.match(REGEX_LETTERS_NUMBERS) !== null);
}

function ValidPassword(value) {
  return (value.match(REGEX_PASSWORD) !== null);
}

function IsNumbers(value) {
  value = value.toString();
  return (value.match(REGEX_NUMBERS) !== null);
}

function IsFloat(value) {
  value = value.toString();
  return (value.match(REGEX_FLOAT) !== null);
}

function IsEmail(value) {
  return (value.match(REGEX_EMAIL) !== null);
}

function IsPhone(value) {
  return (value.match(REGEX_PHONE) !== null);
}

function IsEmpty(value) {
  return (value.length === 0);
}

function IsNotEmpty(value) {
  return (value !== 'undefined' && value !== null && value.length !== 0);
}
