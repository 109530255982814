import { MegaMenu as MagentoMegaMenu }    from  '@themes/magento/frontend/components/megamenus/MegaMenu';

import { connect }                        from 'react-redux';
import { openSideBar }                    from '@frontend/actions/side-bar';
import {setMenuHidden }                   from '@frontend/actions/theme';

import Link                               from 'next/link';

import MegaMenuItem                       from '@frontend/components/megamenus/MegaMenuItem';
import ChangeTheme                        from '@frontend/components/change-theme';

import '@frontend/components/megamenus/megamenus.less';

class MegaMenu extends MagentoMegaMenu{
  openSideBar = ()=>{
    const { user } = this.props;
    if ((!user || !user.id) && this.props.openSideBar){
      this.props.openSideBar('signin');
    }else if ( user && user.id && this.props.openSideBar){
      this.props.openSideBar('my-account');
    }
    this.props.setMenuHidden(true);
  }
  hideMenu = () =>{
    this.props.setMenuHidden(true);
  }
  render(){
    const { megamenu,header,isMobile } = this.props;
    const level        = 0;
    return (
      <div className="ez-megamenu">
        
        <ul className="ez-megamenu-items">
           <li className={`megamenu-item main-section hide-children level-${level}`} data-has-children={false}>
             {
               isMobile ?
                <Link {...this.router.get('signin')}><a className="item-name" onClick={this.hideMenu}> {this.i18n.t(`navigationMenu.myAccount`)}</a></Link>
               :
                <span className="item-name account-me" onClick={this.openSideBar}>
                  {this.i18n.t(`navigationMenu.myAccount`)}
                </span>
             }
          </li>
          {
            megamenu.map((item,index)=>{
              return (
                <MegaMenuItem key={index} data={item} level={level} />
              );
            })
          }
          <li key={"overlay"} className="megamenu-overlay"></li>
        </ul>
        {/* <img src="/static/images/heritage-menu-mobile.png" className="heritage-menu-image" /> */}
      </div>
    );
  }
}
function mapStateToProps({megamenu,header,isMobile,user}){
  return {
    megamenu,
    header,
    isMobile,
    user
  };
}
export default connect(mapStateToProps,{openSideBar,setMenuHidden})(MegaMenu);
