import EzComponent              from '@frontend/components/EzComponent';
import { getProductPrices }     from '@frontend/services/PriceCalculation';

import Link                     from 'next/link';
import { connect }              from 'react-redux'
import _find                    from 'lodash/find';


import {ProductListItem
      as StoreProductListItem}   from '@themes/store/frontend/components/product-list/product-item/ProductListItem';

import '@frontend/components/product-list/product-item/product-list-item.less';

export class ProductListItem extends StoreProductListItem{

  getProductPath = () => {
    const { product, storeConfiguration } = this.props;
    if (!product){
      return "";
    }
    else if (storeConfiguration.productUrlSuffix && storeConfiguration.productUrlSuffix.length){
      return  "/" + product.path + storeConfiguration.productUrlSuffix;
    }else{
      return "/" + product.path;
    }
  }
  getPrices = (prices) =>{
    const { selectedProductId } = this.state;
    const { product:{product_childs,is_configurable},storeConfiguration:{excludedTaxCustomerGroup},user,defaultCountry } = this.props;
    if (selectedProductId && is_configurable && product_childs && product_childs.length){
      prices = (JSON.parse(product_childs)[selectedProductId]) ? JSON.parse(product_childs)[selectedProductId].prices : prices;
    }
    return getProductPrices({ user, excludedTaxCustomerGroup, prices, defaultCountry, storeConfig: this.props.storeConfiguration});

  }
}

function mapStateToProps({storeConfig,user}){
  return {
    storeConfiguration:storeConfig,
    user
  }
}

export default connect(mapStateToProps)(ProductListItem);
